import React, { PropsWithChildren, ReactNode, useCallback } from "react";
import {
  getCollectionNameByModuleName
} from "../../../RAFComponents/helpers/RAFMenuHelper";
import {
  getLogoURL,
  isNotNullAndUndefined
} from "../../../RAFComponents/helpers/utils";

import { useNavigate } from "react-router-dom";
import { RAFEventName, triggerRAFEvent } from "../../../RAFComponents/helpers/RAFDocumentEvents";
import RAFImageWithFallback from "../../../RAFComponents/hoc/RAFImageWithFallback";
import RAFIndexViewTypeHeaders from "./RAFIndexViewTypeHeaders";

interface IProps {
  viewID?: string;
  title?: string;
  moduleName?: string;
  iconClassName?: string;
  secondaryTitle?: string;
  secondaryModuleName?: string;
  showViewTypes?: boolean;
  showBackArrow?: boolean;
  showHamburgerBtn?: boolean;
  hideSecondaryHeader?: boolean;
  disableDashboardAnalytic?: boolean;
  backArrowClicked?: () => void;
  tabHeaderItems?: {
    id: number;
    text: string;
    value: string;
    disabled: boolean;
  }[];
  rightSection?: React.ReactFragment | ReactNode;
}

function RafListPageHeader({ ...props }: PropsWithChildren<IProps>) {
  const navigate = useNavigate();

  const onToggleMenu = () => {
    // const windowWidth = window.innerWidth;
    // if (windowWidth >= 767) {
    //   onToggleSideMenu2();
    // }
    // else {
    // onToggleSideMenu();
    // }
    // if (BrowserIsDevice) {
    //   navigate('/menu');
    // }
    // else {
    triggerRAFEvent(RAFEventName.ToggleSideMenu, null);
    // }
  };

  const backArrowClicked = () => {
    if (props.backArrowClicked) {
      props.backArrowClicked();
    } else {
      navigate(-1);
    }
  };

  let headerText = isNotNullAndUndefined(props.title) ? props.title : getCollectionNameByModuleName(props.moduleName);
  let secondaryHeader = props.secondaryTitle;
  let showHamburgerBtn = isNotNullAndUndefined(props.showHamburgerBtn) ? props.showHamburgerBtn : true;

  return (
    <div>

      <div className="page-heading" id="page-heading">
        <div className="w-100">
          <div className="row g-2 flex-nowrap align-items-center">
            {props.showBackArrow === true ? (
              <div className="col-auto">
                <div
                  className={
                    isNotNullAndUndefined(props.iconClassName)
                      ? `${props.iconClassName}-avatar backArrowIcon bg-transparent backArrowIcon-md`
                      : "backArrowIcon backArrowIcon-md bg-transparent default-avatar"
                  }
                  onClick={backArrowClicked}
                  id="gridPageHeadingBackBtn"
                >
                  <i className="fass fa-arrow-left-long"></i>
                </div>
              </div>
            ) :
              showHamburgerBtn === true ?
                (
                  <div className="col-auto">
                    <div
                      className={
                        isNotNullAndUndefined(props.iconClassName)
                          ? `cursor backArrowIcon backArrowIcon-md sidemenu-toggle-btn`
                          : "cursor backArrowIcon backArrowIcon-md sidemenu-toggle-btn"
                      }
                      onClick={() => onToggleMenu()}
                      id="gridPageHeadingHamburgerMenuBtn"
                    >
                      <span className="fas fa-bars"></span>
                    </div>
                  </div>
                )
                :
                null}
            <div className="col overflow-auto">
              <div className="row g-0 flex-column flex-nowrap">
                <div className="col-12">
                  <div className="row g-0 flex-nowrap align-items-center">
                    <div className="col overflow-auto">
                      {props.hideSecondaryHeader !== true && (
                        <span
                          className="page_heading_header_text ecllipseFirstLine"
                        // id="pageHeading_viewname_div"
                        >
                          {/* {headerText} */}
                          {/* {isNotNullAndUndefined(currentView) ? currentView.DisplayName : ""} */}
                          {secondaryHeader}
                        </span>
                      )}
                      {isNotNullAndUndefined(secondaryHeader) ?
                        <span className={props.hideSecondaryHeader !== true ? "ecllipseFirstLine text-break subtitle_2" : "header_5 ecllipseFirstLine text-break"}>
                          {headerText}
                        </span>
                        :
                        <span
                          className="header_5 ecllipseFirstLine text-break"
                        // id="pageHeading_viewname_div"
                        >
                          {headerText}
                        </span>
                      }
                      {props.showViewTypes === true && (
                        <div className="">
                          {" "}
                          {/*d-none don't remove this view type header */}
                          <RAFIndexViewTypeHeaders
                            viewID={props.viewID}
                            moduleName={props.moduleName}
                            //defaultViewItems={props.defaultViewItems}
                            showViewTypes={props.showViewTypes}
                            disableDashboardAnalytic={
                              props.disableDashboardAnalytic
                            }
                            tabHeaderItems={props.tabHeaderItems}
                            secondaryModuleName={props.secondaryModuleName}
                          />
                        </div>
                      )}
                    </div>
                    <div className="col d-none d-lg-flex align-items-center justify-content-center mb-1">
                      {/* <img
                      src={getLogoURL()}
                      alt="Client Logo"
                      className="clientLogo"
                    /> */}
                      <RAFImageWithFallback
                        src={getLogoURL()}
                        fallbackSrc={`https://workesio-static.s3.ap-southeast-2.amazonaws.com/clientlogo/company_logo.png`}
                        alt="Client Logo"
                        className="clientLogo"
                      />
                    </div>

                    {isNotNullAndUndefined(props.rightSection) ? (
                      <div className="col-auto col-lg-4 d-flex align-items-center justify-content-end">
                        {props.rightSection}
                      </div>
                    ) : (
                      <div className="col-auto col-lg">
                        {/* <div className="row g-2 flex-nowrap">
                                                <div className="col-auto ms-auto">
                                                    <RAFProfileCard></RAFProfileCard>
                                                </div>

                                            </div> */}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(RafListPageHeader);
