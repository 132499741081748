import React, { createContext, PropsWithChildren, ReactNode } from "react";
import {
  getFields,
  hideProgress,
  showProgress,
} from "../../../RAFComponents/helpers/AppHelper";
import {
  isNotNullAndUndefined,
  isNullOrUndefined,
  IsNullOrWhiteSpace,
  wrapChildrenWith,
} from "../../../RAFComponents/helpers/utils";
import { QueryAttributeJM } from "../../../RAFComponents/models/Common/QueryAttributeJM";
import { RAFAttributesContext } from "../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import { RAFPageType } from "../../../constants/Common/RMConstants";
import { EntitySimpleJM } from "../../../RAFMaster/RMModules/Entity/EntityRow";
import { PageLayoutJM } from "../../../RAFMaster/RMModules/RAFPage/PageLayoutJM";
import {
  convertAttributesToPageLayoutJM,
  getPageLayoutByEntityIdAndPageType,
  getPageLayoutByUID,
} from "../../../RAFMaster/RMModules/RAFPage/RAFPageHelper";
import { RAFEntityContext } from "./../Providers/RAFEntityProvider";

interface IProps {
  //attributeRelatedList?: AttributeRelatedList;
  pagelayoutUID?: string;
  rafPageType?: RAFPageType;
  prefix?: string;
  pageLayout?: PageLayoutJM;
  mode?: "Edit" | "Details" | "Create";
  defaultComponent?: React.ReactFragment | ReactNode;
  progressDivId?: string;
}

export const RAFPageLayoutContext = createContext<{
  pageLayout?: PageLayoutJM;
  prefix?: string;
  mode?: "Edit" | "Details" | "Create";
  viewAttributes?: QueryAttributeJM[];
}>({
  pageLayout: null,
  prefix: null,
  mode: null,
  viewAttributes: [],
});
export default function RAFPageLayoutProvider({
  pagelayoutUID,
  children,
  ...props
}: PropsWithChildren<IProps>) {
  const [viewAttributes, setviewAttributes] = React.useState([]);
  const [pageLayoutJM, setPageLayoutJM] = React.useState(
    isNotNullAndUndefined(props.pageLayout) ? props.pageLayout : null
  );

  const entityContext = React.useContext(RAFEntityContext);
  const entityRow: EntitySimpleJM = entityContext.entity;

  const rafAttributesContext = React.useContext(RAFAttributesContext);
  const fieldList: QueryAttributeJM[] = rafAttributesContext.queryAttributes;
  React.useEffect(() => {
    if (isNullOrUndefined(entityRow)) {
      return;
    }
    getFields(entityRow.EntityName).then((viewAttributes) => {
      if (
        isNotNullAndUndefined(props.pageLayout) &&
        isNotNullAndUndefined(props.pageLayout.Layout) &&
        isNotNullAndUndefined(props.pageLayout.Layout.sections) &&
        props.pageLayout.Layout.sections.length > 0
      ) {
        //setPageLayoutJM(props.pageLayout);
      } else if (
        isNotNullAndUndefined(props.pageLayout) &&
        isNotNullAndUndefined(props.pageLayout.Components) &&
        isNotNullAndUndefined(props.pageLayout.Components.Components) &&
        props.pageLayout.Components.Components.length > 0
      ) {
        setPageLayoutJM(props.pageLayout);
      } else {
        let progressDiv = isNotNullAndUndefined(props.progressDivId)
          ? showProgress(props.progressDivId)
          : showProgress("body", true);
        if (IsNullOrWhiteSpace(pagelayoutUID)) {
          getPageLayoutByEntityIdAndPageType(
            entityRow.UID,
            props.rafPageType
          ).then((pageLayoutJM) => {
            hideProgress(progressDiv);
            if (
              isNotNullAndUndefined(pageLayoutJM) &&
              isNotNullAndUndefined(pageLayoutJM.UID)
            ) {
              if (isNullOrUndefined(pageLayoutJM.Layout)) {
                if (
                  isNotNullAndUndefined(fieldList) &&
                  isNotNullAndUndefined(fieldList)
                ) {
                  pageLayoutJM = convertAttributesToPageLayoutJM(
                    entityRow.DisplayName,
                    entityRow.UID,
                    pageLayoutJM,
                    fieldList
                  );
                  setPageLayoutJM(pageLayoutJM);
                } else {
                  setPageLayoutJM(pageLayoutJM);
                }
              } else {
                setPageLayoutJM(pageLayoutJM);
              }
            } else {
              let pageLayoutJM: PageLayoutJM = {};

              // if (
              //   isNotNullAndUndefined(attributeRelatedList) &&
              //   isNotNullAndUndefined(attributeRelatedList.Attributes)
              // ) {
              //   pageLayoutJM = convertAttributesToPageLayoutJM(
              //     attributeRelatedList.Attributes,
              //     entityRow.DisplayName,
              //     entityRow.UID
              //   );
              //   setPageLayoutJM(pageLayoutJM);
              // } else {
              //   setPageLayoutJM(pageLayoutJM);
              // }
              setPageLayoutJM(pageLayoutJM);
            }
          });
        } else {
          getPageLayoutByUID(pagelayoutUID).then((pageLayoutJM) => {
            hideProgress(progressDiv);
            if (
              isNotNullAndUndefined(pageLayoutJM) &&
              isNotNullAndUndefined(pageLayoutJM.UID)
            ) {
              if (isNullOrUndefined(pageLayoutJM.Layout)) {
                if (
                  isNotNullAndUndefined(fieldList) &&
                  isNotNullAndUndefined(fieldList)
                ) {
                  pageLayoutJM = convertAttributesToPageLayoutJM(
                    entityRow.DisplayName,
                    entityRow.UID,
                    pageLayoutJM,
                    fieldList
                  );
                  setPageLayoutJM(pageLayoutJM);
                } else {
                  setPageLayoutJM(pageLayoutJM);
                }
              } else {
                setPageLayoutJM(pageLayoutJM);
              }
            } else {
              let pageLayoutJM: PageLayoutJM = {};

              if (
                isNotNullAndUndefined(fieldList) &&
                isNotNullAndUndefined(fieldList)
              ) {
                pageLayoutJM = convertAttributesToPageLayoutJM(
                  entityRow.DisplayName,
                  entityRow.UID,
                  pageLayoutJM,
                  fieldList
                );
                setPageLayoutJM(pageLayoutJM);
              } else {
                setPageLayoutJM(pageLayoutJM);
              }
            }
          });
        }
      }
      setviewAttributes(viewAttributes);
    });
  }, [entityRow, pagelayoutUID, props.pageLayout]);

  let children1 = wrapChildrenWith(children, {
    prefix: props.prefix,
    pageLayout: pageLayoutJM,
    mode: props.mode,
    viewAttributes: viewAttributes,
  });

  if (isNotNullAndUndefined(pageLayoutJM)) {
    return (
      <RAFPageLayoutContext.Provider
        value={{
          pageLayout: pageLayoutJM,
          prefix: props.prefix,
          mode: props.mode,
          viewAttributes: viewAttributes,
        }}
      >
        {children1}
      </RAFPageLayoutContext.Provider>
    );
  } else {
    return <div></div>;
  }
}
