import React, { PropsWithChildren } from "react";
import { ColumnField } from "../../RAFMaster/RMModules/RAFPage/PageLayoutJM";
import RAFField from "./RAFField";

interface IProps {
  items?: ColumnField[];
  prefix?: string;
  validateFields?: boolean;
  mode?: "Edit" | "Details" | "Create";
}

export default function RAFFieldsList({
  children,
  ...props
}: PropsWithChildren<IProps>) {
  const { items } = props;

  return (
    <div className="row g-3">
      {items.map((item) => (
        <RAFField
          key={item.AttributeUID}
          data={item}
          prefix={props.prefix}
          validateFields={props.validateFields}
          mode={props.mode}
        />
      ))}
    </div>
  );
}
