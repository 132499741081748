import { PropsWithChildren, Reducer, useEffect, useReducer } from "react";
import { useRecoilValue } from "recoil";
import "survey-core/defaultV2.min.css";
import RAFAttributeRelatedListProvider from "../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import {
  IDialogProps,
  IsNullOrWhiteSpace,
  RAFFormComponentProps,
  isNotNullAndUndefined,
} from "../../../RAFComponents/helpers/utils";
import { atomEditingRecordId } from "../List/IndexHelper";
import RAFEntityProvider from "../Providers/RAFEntityProvider";
import RAFObjectContextProvider from "../Providers/RAFObjectContextProvider";
import UpdateContentRenderer from "./UpdateContentRenderer";

interface IProps {
  moduleName?: string;
  onSave?: (entityId?: string, objectName?: string) => void;
  onDelete?: () => void;
  objectUID?: string;
  isActive?: boolean;
}

interface IState {
  isActive?: boolean;
}

function RAFEdit({
  objectUID,
  moduleName,
  isActive,
  ...props
}: PropsWithChildren<IProps & RAFFormComponentProps & IDialogProps>) {
  let objectGUID = useRecoilValue(atomEditingRecordId(moduleName));
  objectUID = isNotNullAndUndefined(objectGUID) ? objectGUID : objectUID;

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isActive: false,
    }
  );

  useEffect(() => {
    setState({
      isActive: true,
    });
  }, [isActive, objectUID]);

  const onSave = (objectUID) => {
    if (props.onSave) props.onSave(objectUID);
  };

  const cancelClick = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  const deleteClick = () => {
    if (props.onDelete) {
      props.onDelete();
    }
  };

  if (
    isNotNullAndUndefined(objectUID) &&
    !IsNullOrWhiteSpace(objectUID) &&
    state.isActive
  ) {
    return (
      <div
        className="h-100"
        key={`${objectUID}_${moduleName}`}
        id={`raf_dlg_Outer_Div_${moduleName}`}
      >
        <RAFEntityProvider moduleName={moduleName}>
          <RAFAttributeRelatedListProvider moduleName={moduleName}>
            <RAFObjectContextProvider
              moduleName={moduleName}
              objectId={objectUID}
              progressDivId={`#raf_dlg_Outer_Div_${moduleName}`}
            >
              <UpdateContentRenderer
                onSave={onSave}
                onCancel={cancelClick}
                onDelete={deleteClick}
                mode="Dialog"
                objectUID={objectUID}
              />
            </RAFObjectContextProvider>
          </RAFAttributeRelatedListProvider>
        </RAFEntityProvider>
      </div>
    );
  } else {
    return (
      <div
        className="container-fluid px-0"
        style={{ background: "transparent" }}
      ></div>
    );
  }
}
export default RAFEdit;
