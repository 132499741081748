import { DialogUtility } from "@syncfusion/ej2-popups";
import React, {
  PropsWithChildren,
  Reducer,
  useEffect,
  useReducer,
} from "react";
import { Field, FormRenderProps } from "react-final-form";

import RAFAutoCompleteMUITagInput from "../../../RAFComponents/Inputs/RAFAutoCompleteMUITagInput";
import RAFForm, { WhenFieldChanges } from "../../../RAFComponents/Inputs/RAFForm";
import RAFLookUpMUI from "../../../RAFComponents/Inputs/RAFLookUpMUI";
import RAFLookupCC from "../../../RAFComponents/Inputs/RAFLookupCC";
import RAFTextArea from "../../../RAFComponents/Inputs/RAFTextArea";
import RAFTextBox from "../../../RAFComponents/Inputs/RAFTextBox";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDeletedRecordState from "../../../RAFComponents/Navigation/RAFDeletedRecordState";
import RAFAttributeRelatedListProvider from "../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import { RAFCustomFilter, RAFCustomOperator } from "../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import { showSuccessToast, showWarningToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import {
  DeleteRecord,
  RAFActionMessage,
  RetrieveRecord,
  hideProgress,
  showProgress,
} from "../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../RAFComponents/helpers/PermissionHelper";
import {
  IDialogProps,
  IsNotNullOrWhiteSpace,
  getSaveRequest,
  isNotNullAndUndefined,
} from "../../../RAFComponents/helpers/utils";
import * as repositoryActions from "../../../RAFComponents/store/actions/repositoryActions";
import ACLoadingPanel from "../../../components/shared/ACLoadingPanel";
import { CareStandardizedLibraryConstants } from "../../../constants/CareESIO/CareESIOPermissionConstant";
import {
  ContentType,
  RAFButtonConstant,
  RAFLayout,
} from "../../../constants/Common/Constants";
import { RAFEntityName } from "../../../constants/Common/EntityConstants";
import RAFEntityProvider from "../../Common/Providers/RAFEntityProvider";
import { StandardizedLibraryRow } from "./StandardizedLibraryRow";

export const saveStandardizedLibraryAPI = (
  standardizedLibraryRow?: StandardizedLibraryRow,
  moduleName?: string
) => {
  return new Promise<{ entityId: string; objectName: string; }>(
    async (resolve) => {
      let saveRequestData = getSaveRequest(standardizedLibraryRow, standardizedLibraryRow.UID);
      if (isNotNullAndUndefined(moduleName)) {
        saveRequestData.EntityName = moduleName;
      }
      if (isNotNullAndUndefined(standardizedLibraryRow)) {
        repositoryActions
          .postDataAndGetResponse(
            "StandardizedLibrary/Save",
            saveRequestData,
            null,
            ContentType.applicationJson
          )
          .then((response) => {
            if (
              isNotNullAndUndefined(response) &&
              isNotNullAndUndefined(response.data) &&
              isNotNullAndUndefined(response.data.EntityId)
            ) {
              showSuccessToast(RAFActionMessage.RecordSaved);
              resolve({
                entityId: response.data.EntityId,
                objectName: response.data.ObjectName,
              });
            } else {
              resolve({ entityId: null, objectName: null });
            }
          })
          .catch((error) => resolve({ entityId: null, objectName: null }));
      } else {
        resolve({ entityId: null, objectName: null });
      }
    }
  );
};

interface IProps {
  initialValues?: StandardizedLibraryRow;
  onDelete?: () => void;
  objectUID?: string;
}

interface IState {
  isLoading: boolean;
  noContent: boolean;
  standardizedLibraryRow: StandardizedLibraryRow;
}

function RAFManageStandardizedLibrary({
  ...props
}: PropsWithChildren<IProps & IDialogProps>) {
  let rafForm: FormRenderProps | null;

  const moduleName = RAFEntityName.StandardizedLibrary;

  const outerDivId = `manageupdate_standardizedLibrary_dialog`;
  let deleteDialog: any;

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: true,
      noContent: true,
      standardizedLibraryRow: null,
    }
  );

  useEffect(() => {
    refresh();
  }, []);

  const refresh = async () => {
    if (props.isActive) {
      setState({ isLoading: true });
      const [standardizedLibraryRow] = await Promise.all([getIntitailStandardizedLibraryRow()]);

      if (isNotNullAndUndefined(standardizedLibraryRow)) {
        setState({ isLoading: false, noContent: false, standardizedLibraryRow });
      } else {
        setState({ isLoading: false, noContent: true });
      }
    }
  };

  const getIntitailStandardizedLibraryRow = () => {
    return new Promise<StandardizedLibraryRow>(async (resolve) => {
      if (isNotNullAndUndefined(props.objectUID)) {
        const standardizedLibraryRow = await RetrieveRecord(props.objectUID, moduleName);
        if (
          isNotNullAndUndefined(standardizedLibraryRow) &&
          isNotNullAndUndefined(standardizedLibraryRow.UID)
        ) {
          resolve(standardizedLibraryRow);
        } else {
          resolve(null);
        }
      } else {
        let initialObject = isNotNullAndUndefined(props.initialValues)
          ? props.initialValues
          : new StandardizedLibraryRow();

        resolve(initialObject);
      }
    });
  };

  const onSubmitStandardizedLibrary = async (value: StandardizedLibraryRow) => {
    let progressDiv = showProgress(`#${outerDivId}`);
    const response = await saveStandardizedLibraryAPI(value, moduleName);
    hideProgress(progressDiv);
    if (
      isNotNullAndUndefined(response) &&
      isNotNullAndUndefined(response.entityId)
    ) {
      if (isNotNullAndUndefined(props.onSave)) {
        props.onSave(response.entityId);
      }
    } else {
      showWarningToast(RAFActionMessage.RecordNotSaved);
    }
  };

  //delete item start
  const onClickDelete = () => {
    deleteDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: { text: "No" },
      closeOnEscape: false,
      content: "Are you sure want to delete?",
      okButton: { text: "Yes", click: deleteRecord.bind(this) },
      title: `Delete StandardizedLibrary`,
      position: { X: "center", Y: "center" },
      cssClass: `alert-dialog deleteDialog`,
    });
  };

  async function deleteRecord() {
    let progressDiv = showProgress(".deleteDialog.e-dialog");

    let isDeleted = await DeleteRecord(props.objectUID, moduleName);

    if (isDeleted) {
      hideProgress(progressDiv);
      deleteDialog.hide();
      if (isNotNullAndUndefined(props.onDelete)) {
        props.onDelete();
      } else if (isNotNullAndUndefined(props.onSave)) {
        props.onSave();
      }
    } else {
      hideProgress(progressDiv);
      deleteDialog.hide();
      showWarningToast("Sorry something went wrong !");
    }
  }
  //delete item end

  const getParentContent = (type: string) => {
    if (type === "Support") {
      return (
        <div className="col-12">
          <Field name={"Type"}>
            {({ input }) => {
              let customFilter: RAFCustomFilter =
                {};
              customFilter.Condition =
                "and";
              customFilter.Rules = [];
              if (
                isNotNullAndUndefined(
                  input
                ) &&
                IsNotNullOrWhiteSpace(
                  input.value
                ) &&
                input.value !== "None"
              ) {
                let filter: RAFCustomFilter =
                  {};
                let filterVal: string[] =
                  [];
                filterVal.push(
                  "Activity"
                );
                filter.Operator =
                  RAFCustomOperator.Equal;
                filter.Value = filterVal;
                filter.Field = "Type";
                customFilter.Rules.push(
                  filter
                );

                if (
                  isNotNullAndUndefined(
                    props.objectUID
                  )
                ) {
                  let filter2: RAFCustomFilter =
                    {};
                  let filterVal2: string[] =
                    [];
                  filterVal2.push(
                    props.objectUID
                  );
                  filter2.Operator =
                    RAFCustomOperator.NotEqual;
                  filter2.Value =
                    filterVal2;
                  filter2.Field = "UID";
                  customFilter.Rules.push(
                    filter2
                  );
                }
              }
              return (
                <RAFLookupCC
                  field="Parent"
                  label="Category"
                  moduleName={moduleName}
                  url="DataList/Lookup"
                  customFilter={
                    customFilter
                  }
                  showCreateButton={false}
                  showFullList={false}
                  formGroupClassName="mb-0"
                />
              );
            }}
          </Field>
        </div>
      );
    } else if (type === 'Task') {
      let customFilter: RAFCustomFilter = {};
      customFilter.Condition = "and";
      customFilter.Rules = [];
      let filter: RAFCustomFilter = {};
      let filterVal: string[] = [];
      filterVal.push('Task');
      filter.Operator = RAFCustomOperator.Equal;
      filter.Value = filterVal;
      filter.Field = "Type";
      customFilter.Rules.push(filter);

      return (
        <RAFLookUpMUI
          field={"Parent"}
          placeholder={"Select Parent"}
          url={"StandardizedLibrary/Lookup"}
          moduleName={RAFEntityName.StandardizedLibrary}
          showCreateButton={false}
          SearchCreateOptionMode={"Footer"}
          customFilter={customFilter}
        />
      );
    } else {
      return null;
    }
  };

  if (props.isActive) {
    if (state.isLoading === false) {
      if (state.noContent === false) {
        return (
          <RAFEntityProvider moduleName={moduleName}>
            <RAFAttributeRelatedListProvider moduleName={moduleName}>
              <RAFForm
                type={StandardizedLibraryRow}
                initialValues={state.standardizedLibraryRow}
                formRef={(g) => (rafForm = g)}
                layout={RAFLayout.TwoColumnLayout}
                onSubmit={onSubmitStandardizedLibrary}
                className="h-100"
              >
                <div className="e-dlg-content-outer" id={outerDivId}>
                  <div className="e-dlg-body-content">
                    <div className="row gx-0 gy-3">
                      <div className="col-12">
                        <RAFTextBox
                          field="Title"
                          required
                          formGroupClassName="mb-0"
                        />
                      </div>
                      <div className="col-12">
                        {/* {getTypeContent(queryAttributes)} */}
                        <WhenFieldChanges
                          field={"Type"}
                          set={"Parent"}
                          to={null}
                        />
                        <WhenFieldChanges
                          field={"Type"}
                          set={"ParentUID"}
                          to={null}
                        />
                      </div>
                      {getParentContent(state.standardizedLibraryRow.Type)}
                      <div className="col-12">
                        <RAFAutoCompleteMUITagInput
                          field="TagsListJson"
                          label="Tags"
                          showLabel={true}
                          // showLabel={true}
                          placeholder="Type and press enter to add a tag"
                          showAddBtn
                          rowClassName="gy-0 gx-2"
                          moduleName={
                            RAFEntityName.StandardizedLibrary
                          }
                        />
                      </div>
                      <div className="col-12">
                        <RAFTextArea field="Description" />
                      </div>
                    </div>
                  </div>
                  <div className="e-dlg-footerContent ">
                    <div className="w-100">
                      <div className="row gx-2">
                        {isNotNullAndUndefined(props.objectUID) && (
                          <RAFPermissionRender
                            permissionName={
                              CareStandardizedLibraryConstants.CareStandardizedLibraryDelete
                            }
                          >
                            <div className="col-auto">
                              <RAFButtonComponent
                                action={RAFButtonConstant.Delete}
                                onClick={() => onClickDelete()}
                                idString="DeleteContent"
                                className="e-danger e-outline"
                              />
                            </div>
                          </RAFPermissionRender>
                        )}
                        <div className="col-auto ms-auto">
                          <RAFPermissionRender
                            permissionName={
                              isNotNullAndUndefined(props.objectUID)
                                ? CareStandardizedLibraryConstants.CareStandardizedLibraryUpdate
                                : CareStandardizedLibraryConstants.CareStandardizedLibraryAdd
                            }
                          >
                            <RAFButtonComponent
                              type="button"
                              isPrimary
                              action={RAFButtonConstant.Save}
                              onClick={() => rafForm && rafForm.form.submit()}
                              idString="CreateContent"
                              disabled={rafForm && rafForm.submitting}
                            />
                          </RAFPermissionRender>
                        </div>
                        <div className="col-auto">
                          <RAFButtonComponent
                            type="button"
                            action={RAFButtonConstant.Cancel}
                            onClick={props.onClose}
                            idString="CreateContent"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </RAFForm>
            </RAFAttributeRelatedListProvider>
          </RAFEntityProvider>
        );
      } else {
        return (
          <div className="container-fluid px-0">
            <RAFDeletedRecordState title="This item is either deleted or You do not have sufficient privileges to view this item." />
          </div>
        );
      }
    } else {
      return (
        <div className="container-fluid px-0">
          <ACLoadingPanel loadingText="Loading..." />
        </div>
      );
    }
  } else {
    return <div></div>;
  }
}

export default React.memo(RAFManageStandardizedLibrary);
