import React, { PropsWithChildren } from "react";
import { Column } from "../../RAFMaster/RMModules/RAFPage/PageLayoutJM";
import RAFColumn from "./RAFColumn";

interface IProps {
  items?: Column[];
  columnCount?: number;
  prefix?: string;
  validateFields?: boolean;
  mode?: "Edit" | "Details" | "Create";
}

export default function RAFColumnList({
  children,
  ...props
}: PropsWithChildren<IProps>) {
  const { items, columnCount } = props;
  const colClass = columnCount ? `col-md-${12 / columnCount}` : `col-md-12`;

  return (
    <div className={"row"}>
      {items.map((item) => (
        <RAFColumn
          key={item.UID}
          data={item}
          colClass={colClass}
          prefix={props.prefix}
          validateFields={props.validateFields}
          mode={props.mode}
        />
      ))}
    </div>
  );
}
