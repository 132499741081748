import { TabComponent } from "@syncfusion/ej2-react-navigations";
import React, { PropsWithChildren, useEffect, useReducer, useRef } from "react";
import { useRecoilState } from "recoil";
import { SetGridState, getSessionStorage, hideProgress, showProgress } from "../../../RAFComponents/helpers/AppHelper";
import { IsNullOrWhiteSpace, isNotNullAndUndefined } from "../../../RAFComponents/helpers/utils";
import { getDefaultViewTypes } from "../../../components/shared/NavigationMenu/NavigationHelper";
import { RAFReportType, RAFTreeViewMenu, StorageKey } from "../../../constants/Common/Constants";
import { RAFEntityName } from "../../../constants/Common/EntityConstants";
import { RMReportType } from "../../../constants/Common/RMConstants";
import { atomSelectedViewHeaderItem, getViewsReportsDashboards } from "./IndexHelper";


interface IProps {
    viewID: string;
    moduleName: string;
    tabHeaderItems?: { id: number, text: string, value: string, disabled: boolean; }[];
    selectedTabIndex?: number;
    showViewTypes?: boolean;
    disableDashboardAnalytic?: boolean;
    //defaultViewItems: RAFViewRow[];
    secondaryModuleName?: string;
    modeClass?: string;
    listPageGridID?: string;
}

function RAFIndexViewTypeHeaders({
    showViewTypes = true,
    disableDashboardAnalytic = false,
    ...props }: PropsWithChildren<IProps>) {
    //const setSelectedViewHeaderItem = useSetRecoilState(atomSelectedViewHeaderItem(props.moduleName));
    const [selectedViewHeaderItemValue, setSelectedViewHeaderItem] = useRecoilState(atomSelectedViewHeaderItem(props.moduleName));
    const moduleName = selectedViewHeaderItemValue === RMReportType.Process && isNotNullAndUndefined(props.secondaryModuleName) ? props.secondaryModuleName : props.moduleName;

    let tabcomponent = useRef<TabComponent>(null);

    const [state, setState] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            isActive: false as boolean,
            viewMenuOuterDivKey: Math.random() as number,
            viewMenuDataSource: null as RAFTreeViewMenu[],
        }
    );

    useEffect(() => {
        getAllView();
    }, [moduleName, props.viewID]);

    async function getAllView() {
        setState({ viewMenuDataSource: null, isActive: false });
        let progressDiv = showProgress("body", true);
        let viewMenuDataSource = await getViewsReportsDashboards(moduleName, true, disableDashboardAnalytic);
        setState({ viewMenuDataSource, isActive: true, viewMenuOuterDivKey: Math.random() });
        hideProgress(progressDiv);
    }

    function onViewTypeChanged(e, tabHeaderItems) {
        if (e.isInteracted) {
            setSelectedViewHeaderItem(tabHeaderItems[e.selectedIndex].value);
            if (isNotNullAndUndefined(props.listPageGridID)) {
                SetGridState(props.listPageGridID, null, null);
            }
        }
    }

    const showViewTypesValue = disableDashboardAnalytic === true ? false : showViewTypes;
    const disableDashboardAnalyticValue = showViewTypesValue === true ? false : disableDashboardAnalytic;

    // const viewHeaderTypeText = [
    //     { text: RAFReportTypeDisplayName.Dashboard, value: RAFReportType.Dashboard },0
    //     { text: RAFReportTypeDisplayName.List, value: RAFReportType.Table },1
    //     { text: RAFReportTypeDisplayName.Analytic, value: RAFReportType.Analytic },2
    //     { text: RAFReportTypeDisplayName.Table, value: RAFReportType.Table },3
    //     { text: RAFReportTypeDisplayName.Record, value: RMReportType.Process },4
    //     { text: RAFReportTypeDisplayName.Gallery, value: RMReportType.Gallery }5
    // ];

    const defaultHeaderTabs = [
        { id: 0, text: getDefaultViewTypes.Dashboard.text, value: getDefaultViewTypes.Dashboard.value, disabled: disableDashboardAnalyticValue },
        { id: 1, text: getDefaultViewTypes.List.text, value: getDefaultViewTypes.List.value, disabled: false },
        { id: 2, text: getDefaultViewTypes.Analytic.text, value: getDefaultViewTypes.Analytic.value, disabled: disableDashboardAnalyticValue },
    ];

    const btHeaderTabItems = [
        // { id: 0, text: viewHeaderTypeText[0].text, value: viewHeaderTypeText[0].value, disabled: disableDashboardAnalytic },
        { id: 1, text: getDefaultViewTypes.Table.text, value: getDefaultViewTypes.Table.value, disabled: false },
        { id: 2, text: getDefaultViewTypes.Gallery.text, value: getDefaultViewTypes.Gallery.value, disabled: false },
        // { id: 2, text: viewHeaderTypeText[2].text, value: viewHeaderTypeText[2].value, disabled: disableDashboardAnalytic },
    ];

    const docHeaderTabItems = [
        { id: 1, text: getDefaultViewTypes.Library.text, value: getDefaultViewTypes.Library.value, disabled: false },
        { id: 2, text: getDefaultViewTypes.Gallery.text, value: getDefaultViewTypes.Gallery.value, disabled: false },
    ];

    const tabHeaderItems = isNotNullAndUndefined(props.tabHeaderItems) ? props.tabHeaderItems :
        props.moduleName === RAFEntityName.BusinessProcessTemplate ? btHeaderTabItems :
            props.moduleName === RAFEntityName.ContentLibrary ? docHeaderTabItems :
                defaultHeaderTabs;

    const tabCreated = () => {
        if (isNotNullAndUndefined(tabcomponent) && isNotNullAndUndefined(tabcomponent.current)) {
            let itemsData = [];
            tabHeaderItems.forEach((item) => {
                itemsData.push({ header: { text: item.text }, disabled: item.disabled });
            });
            tabcomponent.current.items = itemsData;
            tabcomponent.current.refresh();

            let recentViews =
                JSON.parse(
                    getSessionStorage(StorageKey.recentViewId_modulename + moduleName, true)
                ) || [];

            let recentViewId = isNotNullAndUndefined(props.viewID) ? props.viewID : isNotNullAndUndefined(recentViews) ? recentViews["viewId"] : null;

            let recentView: RAFTreeViewMenu = isNotNullAndUndefined(recentViewId) &&
                state.viewMenuDataSource && state.viewMenuDataSource.length > 0 ? state.viewMenuDataSource.find(x => x.UID === recentViewId) : null;

            const recentViewcategory = moduleName === props.secondaryModuleName ? RMReportType.Process : isNotNullAndUndefined(recentView) && isNotNullAndUndefined(recentView.UID) ? recentView.Category : null;

            let selectedItemIndex = !IsNullOrWhiteSpace(recentViewId) && !IsNullOrWhiteSpace(recentViewcategory) ?
                tabHeaderItems.findIndex(x => x.disabled !== true && x.value === recentViewcategory) : -1;

            if (isNotNullAndUndefined(selectedItemIndex) && selectedItemIndex >= 0) {
                tabcomponent.current.selectedItem = selectedItemIndex;
                if (
                    selectedViewHeaderItemValue !== recentViewcategory
                ) {
                    setSelectedViewHeaderItem(recentViewcategory);
                }
            } else {
                let selectedTabType = isNotNullAndUndefined(selectedViewHeaderItemValue) ? selectedViewHeaderItemValue : RAFReportType.Table;
                let tableTabIndex = tabHeaderItems.findIndex(x => x.disabled !== true && x.value === selectedTabType);
                tabcomponent.current.selectedItem = tableTabIndex >= 0 ? tableTabIndex : 0;
                const recentSelectedType = tabHeaderItems[tableTabIndex >= 0 ? tableTabIndex : 0].value;
                if (recentSelectedType !== selectedViewHeaderItemValue)
                    setSelectedViewHeaderItem(recentSelectedType);
            }
        }
    };

    return (
        <div
            //key={state.viewMenuOuterDivKey}
            key={`${state.viewMenuOuterDivKey}_${selectedViewHeaderItemValue}`}
        >
            {(showViewTypesValue === true || disableDashboardAnalyticValue === true) && (
                <div className="viewTitleBtnOutter">
                    {state.isActive === true &&
                        isNotNullAndUndefined(tabHeaderItems) && tabHeaderItems.length > 0 && (
                            <div className="custom-viewDropdown-container"  >
                                <TabComponent
                                    ref={tabcomponent}
                                    cssClass={isNotNullAndUndefined(props.modeClass) ? `${props.modeClass} e-fill header-tab` : "e-fill custom-tab-footer-border header-tab"}
                                    loadOn="Demand"
                                    heightAdjustMode="Auto"
                                    //selectedItem={state.selectedTabIndex}
                                    //animation={tabAnimationSettings}
                                    selected={(e) => {
                                        onViewTypeChanged(e, tabHeaderItems);
                                    }}
                                    created={tabCreated}
                                >
                                    {/* <TabItemsDirective>
                                    {tabHeaderItems.map((item, index) => {
                                        return (
                                            <TabItemDirective key={index} header={{ text: item.text }} disabled={item.disabled} />
                                        );
                                    })}
                                </TabItemsDirective> */}
                                </TabComponent>
                            </div>
                        )}
                </div>
            )}
        </div>
    );
}

export default React.memo(RAFIndexViewTypeHeaders);