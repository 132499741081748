import * as repositoryActions from "../../..//RAFComponents/store/actions/repositoryActions";
import { ContentType, RAFReportType, RAFTreeViewMenu, RAFViewType } from "../../../constants/Common/Constants";
import { isNotEmptyArray, isNotNullAndUndefined, propertyOf } from "../../../RAFComponents/helpers/utils";
import { ListServiceRequest } from "../../../RAFComponents/models/Common/ListRequest";
import { RAFCustomFilter, RAFCustomOperator } from "../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import { showWarningToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import { PartnerPortalRow } from "./PartnerPortal/PartnerPortalRow";
import { WorkspaceRow } from "./WorkspaceRow";


export const defaultPortalName = "All Portals";

export const defaultPortalItems = [
    {
        UID: null,
        PortalName: defaultPortalName,
        Icon: 'FaAvianex'
    }
] as WorkspaceRow[];


export enum RAFPortalType {
    Tenant = 'Tenant',
    Internal = 'Internal',
    Public = 'Public',
    PartnerHub = 'Partner Hub',
    Project = 'Project',
    PartnerConnect = 'Partner Connect',
}

export const systemPortalNames = ['WorkESIO'];

//MyOrganizationPortal = 'my organization'
export class PortalDefaultView {
    static MyOrganizationPortalView = { Id: 'my_organization', DisplayName: 'My Organization' };
    static MyPortalView = { Id: 'my_portals', DisplayName: 'My Portals' };
}

export const getPortalViewByViewName = (viewName) => {
    let currentView: RAFTreeViewMenu = {
        UID: null,
        Name: viewName === PortalDefaultView.MyPortalView.DisplayName ? PortalDefaultView.MyPortalView.Id : PortalDefaultView.MyOrganizationPortalView.Id,
        DisplayName: viewName === PortalDefaultView.MyPortalView.DisplayName ? PortalDefaultView.MyPortalView.DisplayName : PortalDefaultView.MyOrganizationPortalView.DisplayName,
        Category: RAFReportType.Table,
        IsSystem: true,
        ViewType: RAFViewType.SystemView,
    };

    return currentView;
};

export function isPortalChildItemExist(selectedWorkspaceUID: string, workspaceList: WorkspaceRow[]): boolean {
    let isExist = false;
    if (isNotEmptyArray(workspaceList) && isNotNullAndUndefined(selectedWorkspaceUID)) {
        const isTenantPortal = workspaceList.find(x => x.UID === selectedWorkspaceUID);
        if (isNotNullAndUndefined(isTenantPortal) && isTenantPortal.PortalType === RAFPortalType.Tenant) {
            isExist = true;
            showWarningToast('You do not have sufficient privileges to delete this item.');
        } else {
            let childItems = workspaceList.find(x => x.ParentUID === selectedWorkspaceUID);
            if (isNotNullAndUndefined(childItems) && isNotNullAndUndefined(childItems.UID)) {
                showWarningToast('Please delete all the sub Portals before deleting this Portal.');
                isExist = true;
            }
        }
    }
    return isExist;
}

export function getAllPortalList(viewUID, headerFilterText?: string) {
    return new Promise<WorkspaceRow[]>((resolve, reject) => {
        let listServiceRequest = new ListServiceRequest();
        listServiceRequest.Skip = 0;
        listServiceRequest.Take = 0;
        if (isNotNullAndUndefined(viewUID))
            listServiceRequest.ViewUID = viewUID;

        if (isNotNullAndUndefined(headerFilterText)) {
            let customFilter: RAFCustomFilter = {};
            customFilter.Condition = "and";
            customFilter.Rules = [];
            let filter3: RAFCustomFilter = {};
            let filterVal3: string[] = [];
            filterVal3.push(headerFilterText);
            filter3.Operator = RAFCustomOperator.Contains;
            filter3.Value = filterVal3;
            filter3.Field = propertyOf<WorkspaceRow>("PortalName");
            customFilter.Rules.push(filter3);
            listServiceRequest.CustomFilter = customFilter;
        }
        return repositoryActions.postDataAndGetResponse(
            `Portal/List`,
            listServiceRequest,
            null,
            ContentType.applicationJson, false
        ).then((response) => {
            if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data) && isNotNullAndUndefined(response.data.Entities)) {
                resolve(response.data.Entities);
            } else {
                resolve(null);
            }

        });
    });
}

// export function getAllPartnetPortalList1() {
//     return new Promise<WorkspaceRow[]>((resolve, reject) => {
//         let listServiceRequest = new ListServiceRequest();
//         listServiceRequest.Skip = 0;
//         listServiceRequest.Take = 0;

//         let customFilter: RAFCustomFilter = {};
//         customFilter.Condition = "and";
//         customFilter.Rules = [];

//         let filter1: RAFCustomFilter = {};
//         let filterVal1: string[] = [];
//         filterVal1.push(RAFPortalType.PartnerConnect);
//         filter1.Operator = RAFCustomOperator.Equal;
//         filter1.Value = filterVal1;
//         filter1.Field = propertyOf<WorkspaceRow>("PortalType");
//         customFilter.Rules.push(filter1);

//         listServiceRequest.CustomFilter = customFilter;
//         return repositoryActions.postDataAndGetResponse(
//             `Portal/List`,
//             listServiceRequest,
//             null,
//             ContentType.applicationJson, false
//         ).then((response) => {
//             if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data) && isNotNullAndUndefined(response.data.Entities)) {
//                 resolve(response.data.Entities);
//             } else {
//                 resolve(null);
//             }

//         })
//             .catch((error) => error);
//     });
// }

export function getAllPartnetPortalList() {
    return new Promise<PartnerPortalRow[]>((resolve, reject) => {
        let listServiceRequest = new ListServiceRequest();
        listServiceRequest.Skip = 0;
        listServiceRequest.Take = 0;

        return repositoryActions.postDataAndGetResponse(
            `Portal/PartnerConnect`,
            listServiceRequest,
            null,
            ContentType.applicationJson, false
        ).then((response) => {
            if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data) && isNotNullAndUndefined(response.data.Entities)) {
                resolve(response.data.Entities);
            } else {
                resolve(null);
            }

        });
    });
}