import React, { PropsWithChildren, ReactNode } from "react";
import { isNotNullAndUndefined } from "../../RAFComponents/helpers/utils";
import { QueryAttributeJM } from "../../RAFComponents/models/Common/QueryAttributeJM";
import RAFFormModelRenderer from "../../RAFMaster/RMModules/FormLibrary/RAFFormModelRenderer";
import {
  PageLayoutJM,
  RAFDataModelRow,
} from "../../RAFMaster/RMModules/RAFPage/PageLayoutJM";
import { RAFEntityContext } from "./Providers/RAFEntityProvider";
import { RAFPageLayoutContext } from "./Providers/RAFPageLayoutProvider";
import RAFSectionList from "./RAFSectionList";

interface IProps {
  isDisabled?: boolean;
  isPreviewMode?: boolean;
  validateFields?: boolean;
  defaultComponent?: React.ReactFragment | ReactNode;
  previewMode?: "Mobile" | "Desktop";
}

export default function RAFPageLayout({
  //children,
  ...props
}: PropsWithChildren<IProps>) {
  const rafPageLayoutContext = React.useContext(RAFPageLayoutContext);
  const pageLayoutJM: PageLayoutJM = rafPageLayoutContext.pageLayout;
  const viewAttributes: QueryAttributeJM[] =
    rafPageLayoutContext.viewAttributes;
  const prefix: string = rafPageLayoutContext.prefix;
  const entityContext = React.useContext(RAFEntityContext);
  const entity = entityContext.entity;
  const mode = rafPageLayoutContext.mode;
  let fieldsToShow: boolean;

  if (
    isNotNullAndUndefined(pageLayoutJM) &&
    isNotNullAndUndefined(pageLayoutJM.Layout)
  ) {
    let sections = pageLayoutJM.Layout.sections;
    if (isNotNullAndUndefined(sections) && sections.length > 0) {
      sections.forEach((section) => {
        let columns = section.Columns;
        if (isNotNullAndUndefined(columns) && columns.length > 0) {
          columns.forEach((column) => {
            if (
              isNotNullAndUndefined(column) &&
              isNotNullAndUndefined(column.Fields) &&
              column.Fields.length > 0
            ) {
              fieldsToShow = true;
            }
          });
        }
      });
    } else {
      fieldsToShow = false;
    }
  }

  let formComponents: RAFDataModelRow[] = [];
  if (
    isNotNullAndUndefined(pageLayoutJM) &&
    isNotNullAndUndefined(pageLayoutJM.Components) &&
    isNotNullAndUndefined(pageLayoutJM.Components.Components)
  ) {
    formComponents = pageLayoutJM.Components.Components;
  }

  if (isNotNullAndUndefined(pageLayoutJM)) {
    return (
      <>
        {isNotNullAndUndefined(formComponents) && formComponents.length > 0 ? (
          <div
            className="formModelRenderer h-100"
            //style={{ background: "#fff" }}
          >
            <div className="row gy-3">
              <RAFFormModelRenderer
                formDataFieldName={prefix}
                components={formComponents}
                validateFields={
                  isNotNullAndUndefined(props.validateFields)
                    ? props.validateFields
                    : true
                }
                isDisabled={props.isDisabled}
                isPreviewMode={props.isPreviewMode}
                allowSubmit={false}
                moduleName={entity && entity.EntityName}
                viewAttributes={viewAttributes}
                colClass="col-12"
                previewMode={props.previewMode}
              />
            </div>
          </div>
        ) : fieldsToShow === true ? (
          <RAFSectionList
            items={pageLayoutJM.Layout.sections}
            prefix={prefix}
            validateFields={
              isNotNullAndUndefined(props.validateFields)
                ? props.validateFields
                : true
            }
            mode={mode}
          />
        ) : (
          props.defaultComponent
        )}
      </>
    );
  } else {
    props.defaultComponent;
  }
}
