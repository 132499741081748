import { propertyOf } from '../../../RAFComponents/helpers/utils';
import { RAFEntityBase } from '../../../RAFComponents/models/Common/RAFEntityBase';
import { ILookupRow } from '../../../RAFComponents/models/CompositeTypes/ILookupRow';

export class IWorkspaceRow {
    UID?: string;
    PortalName?: string;
    Icon?: string;
    Parent?: string;
    ParentUID?: string;
    DisplayOrder?: number;
    PortalType?: string;
    IsSystem?: boolean;
    Label?: boolean;
    CreatedDate?: Date;
    CreatedByUID?: string;
    CreatedBy?: string;
    LastActivityDate?: Date;
    ModifiedDate?: Date;
    ModifiedByUID?: string;
    ModifiedBy?: string;
    RecordOwnerUID?: string;
    RecordOwner?: string;
}

export class WorkspaceRow extends IWorkspaceRow implements RAFEntityBase, ILookupRow {
    getClassName?() { //use entityname
        return 'portal';
    }
    getIdField?(): string {
        return propertyOf<WorkspaceRow>("UID");
    }
    getListUrl?(): string { //use route name(entity displayname)
        return "Portal/List";
    }
    getSaveUrl?(): string { //use route name(entity displayname)
        return "Portal/Save";
    }
    getGroupByUrl?(): string {
        return "Portal/GroupBy";
    }
    getLookupUrl(): string { //use route name(entity displayname)
        return "Portal/Lookup";
    }
    isOptionCreatable?(): boolean {
        return false;
    }
}
