import * as R from "ramda";
import { RAFCustomFilter, RAFCustomOperator } from "../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import { showWarningToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import { getRelatedRecords, getSessionStorage, setSessionStorage } from "../../../RAFComponents/helpers/AppHelper";
import { IsNullOrWhiteSpace, getSaveRequest, isNotEmptyArray, isNotNullAndUndefined, propertyOf } from "../../../RAFComponents/helpers/utils";
import { ListServiceRequest } from "../../../RAFComponents/models/Common/ListRequest";
import * as repositoryActions from "../../../RAFComponents/store/actions/repositoryActions";
import { ContentType, StorageKey } from "../../../constants/Common/Constants";
import { RAFEntityName } from "../../../constants/Common/EntityConstants";
import { UserRow } from "../../ActiveContacts/User/UserRow";
import { RoleRow } from "./RoleRow";

export class RAFPermissionJsonRow {
    Value?: string;
    DisplayName?: string;
}

export class RAFRolePermissionCategoryRow {
    Category: string;
    CategoryDisplayName: string;
    CategoryPermission: RAFCategoryPermissionRow[];
}

export class RAFCategoryPermissionRow {
    Name?: string;
    DisplayName?: string;
    Description?: string;
    IsDefault?: boolean;
    isChecked?: boolean;
    packages?: string[];
    Permissions?: RAFCategoryPermissionRow[];
    Customize?: RAFCategoryPermissionRow[];
}

export const retrieveRoleByUID = (roleUID: string) => {
    let url = 'PermissionGroup/Retrieve';
    return new Promise<RoleRow>((resolve) => {
        if (!IsNullOrWhiteSpace(roleUID)) {
            return repositoryActions.postDataAndGetResponse(
                url,
                { EntityId: roleUID },
                null,
                ContentType.applicationJson,
            ).then((response) => {
                if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data)) {
                    let roleRow: RoleRow = response.data.Entity;
                    resolve(roleRow);
                } else {
                    resolve(null);
                }
            }).catch((error) => resolve(null));
        } else {
            resolve(null);
        }
    });
};

// export const getPermissionsByRoleId = (roleUID: string) => {
//     let url = `RolePermission/GetPermissionsByRole`;
//     return new Promise<RolePermissionRow>((resolve) => {
//         if (!IsNullOrWhiteSpace(roleUID)) {
//             return repositoryActions.postDataAndGetResponse(
//                 url,
//                 { RoleUID: roleUID },
//                 null,
//                 ContentType.applicationJson,
//             ).then((response) => {
//                 if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data) && isNotNullAndUndefined(response.data.Entity)) {
//                     let rolePermissionRow: RolePermissionRow = response.data.Entity;
//                     resolve(rolePermissionRow);
//                 } else {
//                     resolve(new RolePermissionRow());
//                 }
//             }).catch((error) => error);
//         } else {
//             resolve(null);
//         }
//     });
// };

export const getAllPermissions = () => {
    let url = `Permission/List`;
    return new Promise<RAFRolePermissionCategoryRow[]>((resolve) => {
        const allPermissionsStorage = getSessionStorage(StorageKey.all_Permissions, true);
        if (isNotNullAndUndefined(allPermissionsStorage) && !IsNullOrWhiteSpace(allPermissionsStorage)) {
            const allPermissions: RAFRolePermissionCategoryRow[] = JSON.parse(allPermissionsStorage);
            if (isNotNullAndUndefined(allPermissions)) {
                resolve(allPermissions);
            }
        }
        else {
            return repositoryActions.postDataAndGetResponse(
                url,
                null,
                null,
                ContentType.applicationJson,
            ).then((response) => {
                if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data)) {
                    const allPermissions: RAFRolePermissionCategoryRow[] = response.data.RolePermissionJson;
                    const uniquePermissions = R.uniq(allPermissions);

                    setSessionStorage(StorageKey.all_Permissions, true, JSON.stringify(uniquePermissions));
                    resolve(uniquePermissions);
                } else {
                    resolve(null);
                }
            }).catch((error) => resolve(null));
        }
    });
};

export const saveRoleRow = (roleRow: RoleRow) => {
    return new Promise<string>((resolve) => {
        repositoryActions.postDataAndGetResponse('PermissionGroup/Save', getSaveRequest(roleRow, roleRow.UID), null, ContentType.applicationJson)
            .then((response) => {
                if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data)) {
                    resolve(response.data.EntityId);
                } else {
                    resolve(null);
                }
            });
    });
};

// export const saveRolePermissions = (rolePermissionRow: RolePermissionRow) => {
//     return new Promise<string>((resolve) => {
//         repositoryActions.postDataAndGetResponse('RolePermission/Save', getSaveRequest(rolePermissionRow, rolePermissionRow.UID), null, ContentType.applicationJson)
//             .then((response) => {
//                 if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data)) {
//                     resolve(response.data.EntityId);
//                 } else {
//                     resolve(null);
//                 }
//             });
//     });
// };

export const getAllRoleList = () => {
    let listServiceRequest = new ListServiceRequest();
    let customFilter: RAFCustomFilter = {};
    customFilter.Condition = "and";
    customFilter.Rules = [];

    listServiceRequest.CustomFilter = customFilter;
    return new Promise<RoleRow[]>((resolve) => {
        return repositoryActions.postDataAndGetResponse(
            `PermissionGroup/List`,
            listServiceRequest,
            null,
            ContentType.applicationJson, false
        ).then((response) => {
            if (isNotNullAndUndefined(response) &&
                isNotNullAndUndefined(response.data)) {
                resolve(response.data.Entities);
            } else {
                resolve(null);
            }
        }).catch((error) => error);
    });
};

export const getRoleListByRoleName = (roleName?: string) => {
    return new Promise<RoleRow[]>((resolve) => {
        if (isNotNullAndUndefined(roleName)) {
            let listServiceRequest = new ListServiceRequest();
            let customFilter: RAFCustomFilter = {};
            customFilter.Condition = "and";
            customFilter.Rules = [];

            let filter: RAFCustomFilter = {};
            let filterVal: string[] = [];
            filterVal.push(roleName);
            filter.Operator = RAFCustomOperator.Equal;
            filter.Value = filterVal;
            filter.Field = propertyOf<RoleRow>("PermissionGroupName");
            customFilter.Rules.push(filter);

            listServiceRequest.CustomFilter = customFilter;

            return repositoryActions.postDataAndGetResponse(
                `PermissionGroup/List`,
                listServiceRequest,
                null,
                ContentType.applicationJson, false
            ).then((response) => {
                if (isNotNullAndUndefined(response) &&
                    isNotNullAndUndefined(response.data)) {
                    resolve(response.data.Entities);
                } else {
                    resolve(null);
                }
            }).catch((error) => error);
        } else {
            resolve(null);
        }
    });
};


export const checkIsRoleLinkedWithUser = (roleID: string) => {
    return new Promise<Boolean>(async (resolve) => {
        if (isNotNullAndUndefined(roleID)) {
            const userModule =
                RAFEntityName.User;
            const relatedItems = await getRelatedRecords(
                userModule,
                null,
                propertyOf<UserRow>("PermissionGroupUID"),
                roleID,
                null,
                null,
                null,
                null,
                null,
                1,
                0,
                `related_${userModule}`
            );
            if (isNotEmptyArray(relatedItems)) {
                showWarningToast("Role is linked with user. Please unlink the role from user and try again.");
                resolve(true);
            } else {
                resolve(false);
            }
        } else {
            resolve(false);
        }
    });
};

export const getAllPermissionsFromPermissionJson = (allPermissionsJsonList: RAFRolePermissionCategoryRow[]) => {
    let allPermissionsValueList: string[] = [];
    if (isNotEmptyArray(allPermissionsJsonList)) {
        allPermissionsJsonList.forEach((permissionJson) => {
            permissionJson.CategoryPermission.forEach((permission) => {
                processPermissions(permission, allPermissionsValueList);
            });
        });
    }
    return allPermissionsValueList;
};

function processPermissions(permission: RAFCategoryPermissionRow, allPermissionsValueList: string[]) {
    allPermissionsValueList.push(permission.Name);

    if (isNotEmptyArray(permission.Permissions)) {
        permission.Permissions.forEach((subPermission) => {
            processPermissions(subPermission, allPermissionsValueList);
        });
    }

    if (isNotEmptyArray(permission.Customize)) {
        permission.Customize.forEach((customize) => {
            processPermissions(customize, allPermissionsValueList);
        });
    }
}

export const convertPermissionJsonToPermissionTreeData = (selectedRoleValue: string[], allPermissionsJsonList: RAFRolePermissionCategoryRow[]) => {
    //update newPermissionJSON isChecked value if name exist in selectedRoleValue
    if (isNotEmptyArray(allPermissionsJsonList)) {
        allPermissionsJsonList.forEach((permissionJson) => {
            permissionJson.CategoryPermission.forEach((permission) => {
                updatePermissions(permission, selectedRoleValue);
            });
        });
        return allPermissionsJsonList;
    } else {
        return null;
    }
};

function updatePermissions(permission: RAFCategoryPermissionRow, selectedRoleValue: string[]) {
    // Update isChecked value if name exists in selectedRoleValue
    if (selectedRoleValue.includes(permission.Name)) {
        permission.isChecked = true;
    } else {
        permission.isChecked = false;
    }

    // Recursively update sub-permissions in permissions
    if (Array.isArray(permission.Permissions)) {
        permission.Permissions.forEach((subPermission) => {
            updatePermissions(subPermission, selectedRoleValue);
        });
    }

    // Recursively update sub-permissions in customize
    if (Array.isArray(permission.Customize)) {
        permission.Customize.forEach((customize) => {
            updatePermissions(customize, selectedRoleValue);
        });
    }
}

export function getTotalChildPermissionsLength(permission: RAFCategoryPermissionRow) {
    let totalLength = 0;

    // Add the length of permissions
    if (Array.isArray(permission.Permissions)) {
        totalLength += permission.Permissions.length;
        // Recursively add the length of nested permissions
        permission.Permissions.forEach((subPermission) => {
            totalLength += getTotalChildPermissionsLength(subPermission);
        });
    }

    // Add the length of customize
    if (Array.isArray(permission.Customize)) {
        permission.Customize.forEach((customize) => {
            if (Array.isArray(customize.Permissions)) {
                totalLength += customize.Permissions.length;
                // Recursively add the length of nested permissions
                customize.Permissions.forEach((subPermission) => {
                    totalLength += getTotalChildPermissionsLength(subPermission);
                });
            }
        });
    }

    return totalLength;
}

export function getCheckedChildPermissionsLength(permission: RAFCategoryPermissionRow) {
    let totalLength = 0;

    // Add the length of permissions
    if (Array.isArray(permission.Permissions)) {
        permission.Permissions.forEach((subPermission) => {
            if (subPermission.isChecked) {
                totalLength++;
            }
            totalLength += getCheckedChildPermissionsLength(subPermission);
        });
    }

    // Add the length of Customize
    if (Array.isArray(permission.Customize)) {
        permission.Customize.forEach((customize) => {
            if (Array.isArray(customize.Permissions)) {
                customize.Permissions.forEach((subPermission) => {
                    if (subPermission.isChecked) {
                        totalLength++;
                    }
                    totalLength += getCheckedChildPermissionsLength(subPermission);
                });
            }
        });
    }

    return totalLength;
}

export function getAllChildPermissions(permission: RAFCategoryPermissionRow) {
    let allPermissions = [];

    // Add the permissions
    if (Array.isArray(permission.Permissions)) {
        allPermissions = allPermissions.concat(permission.Permissions);
        // Recursively add the nested permissions
        permission.Permissions.forEach((subPermission) => {
            allPermissions = allPermissions.concat(getAllChildPermissions(subPermission));
        });
    }

    // Add the customize
    if (Array.isArray(permission.Customize)) {
        permission.Customize.forEach((customize) => {
            if (Array.isArray(customize.Permissions)) {
                allPermissions = allPermissions.concat(customize.Permissions);
                // Recursively add the nested permissions
                customize.Permissions.forEach((subPermission) => {
                    allPermissions = allPermissions.concat(getAllChildPermissions(subPermission));
                });
            }
        });
    }

    return allPermissions;
}
