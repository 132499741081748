import React, {
  createContext,
  PropsWithChildren,
  Reducer,
  useReducer,
} from "react";
import { RAFEntityType } from "../../../constants/Common/RMConstants";
import {
  getFormByEntity
} from "../../../RAFComponents/helpers/AppHelper";
import {
  ConvertSystemName,
  isNotNullAndUndefined,
  IsNullOrWhiteSpace,
  wrapChildrenWith,
} from "../../../RAFComponents/helpers/utils";
import { FormLibraryRow } from "../../../RAFComponents/models/Common/FormLibraryRow";
import {
  ConvertObjkeysToLowerCase,
  getEntityByObjectName
} from "../../../RAFMaster/helpers/RMutils";
import {
  EntityRow,
  EntitySimpleJM,
} from "../../../RAFMaster/RMModules/Entity/EntityRow";

interface IProps {
  moduleName?: string;
}

interface IState {
  //entity: EntitySimpleJM;
  entity: EntityRow;
  formLibrary: FormLibraryRow;
}
export const RAFEntityContext = createContext<{
  //entity: EntitySimpleJM;
  entity: EntityRow;
  listUrl: string;
  formLibrary: FormLibraryRow;
  collectionName: string;
  displayName: string;
}>({
  entity: null,
  listUrl: null,
  formLibrary: null,
  collectionName: null,
  displayName: null,
});

export default function RAFEntityProvider({
  moduleName,
  children,
}: PropsWithChildren<IProps>) {
  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      entity: null,
      formLibrary: null,
    }
  );

  const getListUrl = (objEntity: EntitySimpleJM) => {
    let url = "DataList/List";
    if (
      isNotNullAndUndefined(objEntity) &&
      objEntity.EntityType === RAFEntityType.DataList
    ) {
      url = `${objEntity.EntityName}/List`;
    }
    // else if (
    //   isNotNullAndUndefined(objEntity) &&
    //   objEntity.EntityType === RAFEntityType.UserForm
    // ) {
    //   url = `FormData/List`;
    // }
    return url;
  };

  React.useEffect(() => {
    if (IsNullOrWhiteSpace(moduleName)) {
      return;
    }
    // getSimpleEntityByObjectName({
    //   ObjectName: ConvertSystemName(moduleName),
    // }).then((entity) => {
    getEntityByObjectName({
      ObjectName: ConvertSystemName(moduleName),
    }).then((entity) => {
      if (
        isNotNullAndUndefined(entity) &&
        entity.EntityType === RAFEntityType.UserForm
      ) {
        getFormByEntity(entity.EntityName).then((formLibrary) => {
          // let formLibrary = isNotNullAndUndefined(allForms)
          //   ? allForms.find((x) => x.Entity === entity.EntityName)
          //   : null;
          if (
            isNotNullAndUndefined(formLibrary) &&
            isNotNullAndUndefined(formLibrary.FormRules) &&
            formLibrary.FormRules.length > 0
          ) {
            formLibrary.FormRules.forEach((item) => {
              ConvertObjkeysToLowerCase(item.RRuleModel);
            });
          }

          setState({ entity, formLibrary });
        });
      } else {
        setState({ entity, formLibrary: null });
      }
    });
  }, [moduleName]);

  let children1 = wrapChildrenWith(children, {
    //entityRow: entity,
    //listUrl: getListUrl(entity),
  });

  if (isNotNullAndUndefined(state.entity) && !IsNullOrWhiteSpace(state.entity.UID)) {
    return (
      <RAFEntityContext.Provider
        value={{
          entity: state.entity,
          listUrl: getListUrl(state.entity),
          formLibrary: state.formLibrary,
          collectionName: state.entity.CollectionName,
          displayName: state.entity.DisplayName,
        }}
      >
        {children1 && children1}
      </RAFEntityContext.Provider>
    );
  } else {
    return null;
  }
}
