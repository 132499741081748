import { propertyOf } from '../../../RAFComponents/helpers/utils';
import { RAFEntityBase } from '../../../RAFComponents/models/Common/RAFEntityBase';
import { ILookupRow } from '../../../RAFComponents/models/CompositeTypes/ILookupRow';
import { RAFEntityName } from '../../../constants/Common/EntityConstants';

export class IStandardizedLibraryRow {
    UID?: string;
    Title?: string;
    Type?: string;
    Category?: string;
    SubCategory?: string;
    Description?: string;
    TagsListJson?: string[];
    SubCategoryUID?: string;
    CategoryUID?: string;
    Parent?: string;
    ParentUID?: string;
    StandardizedLibraryIdNumber?: number;
    StandardizedLibraryId?: string;
    Portal?: string;
    PortalUID?: string;
    CreatedDate?: Date;
    CreatedBy?: string;
    CreatedByUID?: string;
    ModifiedDate?: Date;
    ModifiedBy?: string;
    ModifiedByUID?: string;
    RecordOwner?: string;
    RecordOwnerUID?: string;
    LastActivityDate?: Date;
}

export class StandardizedLibraryRow extends IStandardizedLibraryRow implements RAFEntityBase, ILookupRow {
    getClassName?() {
        return RAFEntityName.StandardizedLibrary;
    }
    getIdField?(): string {
        return propertyOf<StandardizedLibraryRow>("UID");
    }
    getListUrl?(): string { //use route name(entity displayname)
        return "StandardizedLibrary/List";
    }
    getSaveUrl?(): string { //use route name(entity displayname)
        return "StandardizedLibrary/Save";
    }
    getGroupByUrl?(): string {
        return "StandardizedLibrary/GroupBy";
    }
    getLookupUrl(): string { //use route name(entity displayname)
        return "StandardizedLibrary/Lookup";
    }
    isOptionCreatable?(): boolean {
        return false;
    }
}
