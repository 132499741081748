import React, { PropsWithChildren } from "react";
import { isNotNullAndUndefined } from "../../RAFComponents/helpers/utils";
import RAFCollapse from "../../RAFComponents/Navigation/RAFCollapse";
import { SectionRow } from "../../RAFMaster/RMModules/RAFPage/PageLayoutJM";
import RAFColumnList from "./RAFColumnList";

interface IProps {
  data?: SectionRow;
  prefix?: string;
  validateFields?: boolean;
  mode?: "Edit" | "Details" | "Create";
}

export default function RAFSection({
  // children,
  ...props
}: PropsWithChildren<IProps>) {
  const { data } = props;

  return (
    <div className="">
      {data && (
        <RAFCollapse
          title={data.Name}
          IsCollapsed={data.IsCollapsed}
          showSectionHeader={data.ShowHeader}
          removeContentCardPadding
          headerRowClassName="custom-section-header"
          contentRowClassName="customSection"
          //layout={GetRAFLayoutByNumber(data.ColumnCount)}
        >
          {isNotNullAndUndefined(data.Columns) && (
            <RAFColumnList
              items={data.Columns}
              columnCount={data.ColumnCount}
              prefix={props.prefix}
              validateFields={props.validateFields}
              mode={props.mode}
            />
          )}
        </RAFCollapse>
      )}
    </div>
  );
}
