import React, { createContext, PropsWithChildren, useContext } from "react";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import {
  Constants,
  ContentType,
  StorageKey,
} from "../../../constants/Common/Constants";
import { RAFEntityName } from "../../../constants/Common/EntityConstants";
import { RAFEntityType } from "../../../constants/Common/RMConstants";
import { getURLPrefixByModuleName } from "../../../helpers/Common/URLPrefixByModuleName";
import { RolePermissionsContext } from "../../../RAFAuthentication/RAFRolePermissionsContextProvider";
import {
  getSessionStorage,
  hideProgress,
  removeSessionStorage,
  showProgress,
} from "../../../RAFComponents/helpers/AppHelper";
import { hasPermission } from "../../../RAFComponents/helpers/PermissionHelper";
import {
  getSaveRequest,
  isNotNullAndUndefined,
  IsNullOrWhiteSpace,
  isString,
  wrapChildrenWith,
} from "../../../RAFComponents/helpers/utils";
import { FormLibraryRow } from "../../../RAFComponents/models/Common/FormLibraryRow";
import { QueryAttributeJM } from "../../../RAFComponents/models/Common/QueryAttributeJM";
import { RAFViewRow } from "../../../RAFComponents/models/Common/RAFViewRow";
import { RAFAttributesContext } from "../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import * as repositoryActions from "../../../RAFComponents/store/actions/repositoryActions";
import { EntitySimpleJM } from "../../../RAFMaster/RMModules/Entity/EntityRow";
import {
  retrieveObjectByUID,
  saveDataByModuleName,
} from "../../../WorkesioRepository";
import { ConvertContactDtoToContactRow } from "../../ActiveContacts/Contact/ContactMappingRegister";
import { RAFEntityContext } from "./RAFEntityProvider";

const defaultLeadCareRecipientSaveURL = (entityName: string) => {
  if (entityName === CareEsioEntity.CareRecipient.EntityName) {
    let allViews: RAFViewRow[] = JSON.parse(
      getSessionStorage(StorageKey.allViews_modulename + entityName, true)
    );
    let recentViewId = JSON.parse(
      getSessionStorage(StorageKey.recentViewId_modulename + entityName, true)
    );
    let recentView: RAFViewRow;
    if (
      isNotNullAndUndefined(recentViewId) &&
      isNotNullAndUndefined(recentViewId.viewId)
    ) {
      recentView =
        allViews && allViews.find((x) => x.UID === recentViewId.viewId);
    }
    if (
      isNotNullAndUndefined(recentView) &&
      recentView.ViewName === "all_leads"
    ) {
      return "CareRecipient/SaveLead";
    } else {
      return "CareRecipient/Save";
    }
  }
  return null;
};

export const submitRAFForm = (
  objectData?: any,
  objectId?: string,
  entityJM?: EntitySimpleJM,
  formLibrary?: FormLibraryRow,
  customurl?: string
) => {
  let url = customurl ?? "DataList/Save";

  let saveRequestData = getSaveRequest(objectData, objectData.UID);
  if (
    isNotNullAndUndefined(entityJM) &&
    entityJM.EntityType === RAFEntityType.DataList
  ) {
    let moduleName = getURLPrefixByModuleName(
      entityJM.EntityName,
      null,
      entityJM.UID
    );
    url = `${moduleName}/Save`;
    if (url === "DataList/Save") {
      saveRequestData.EntityUID = entityJM.UID;
    }
  } else if (
    isNotNullAndUndefined(entityJM) &&
    entityJM.EntityType === RAFEntityType.UserForm
  ) {
    //url = `DataList/SaveForm`;
    if (
      entityJM &&
      entityJM.EntityName === CareEsioEntity.CareRecipient.EntityName
    ) {
      url = "CareRecipient/SaveForm";
    } else if (entityJM && entityJM.EntityName === RAFEntityName.Employee) {
      url = "Employee/SaveForm";
    }
    //const objData = {
    //  DataJson: objectData,
    //  Entity: entityJM.EntityName,
    //};
    // objectData.FormLibrary = '';
    // objectData.FormLibraryUID = '';
    //saveRequestData = getSaveRequest(objData, objectData.UID);
    objectData.FormLibrary = formLibrary && formLibrary.Title;
    objectData.FormLibraryUID = formLibrary && formLibrary.UID;
    saveRequestData = getSaveRequest(objectData, objectData.UID);
    saveRequestData.EntityUID = entityJM.UID;
  } else if (isNotNullAndUndefined(entityJM)) {
    saveRequestData.EntityUID = entityJM.UID;
  }

  return new Promise<{ objectUID?: string; objectName?: string }>((resolve) => {
    // if (
    //   IsNotNullOrWhiteSpace(
    //     defaultLeadCareRecipientSaveURL(entityJM.EntityName)
    //   )
    // ) {
    //   url = defaultLeadCareRecipientSaveURL(entityJM.EntityName);
    // }

    repositoryActions
      .postDataAndGetResponse(
        url,
        saveRequestData,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data) &&
          isNotNullAndUndefined(response.data.EntityId)
        ) {
          resolve({
            objectUID: response.data.EntityId,
            objectName: response.data.ObjectName,
          });
        } else {
          resolve(null);
        }
      });
  });
};

interface IProps {
  objectId?: string;
  moduleName?: string;
  objectData?: any;
  progressDivId?: string;
}

export const RAFObjectContext = createContext<{
  objectId?: string;
  objectName?: string;
  rafObject?: any;
  error?: string;
  saveObject?: (
    objectData?: any,
    onlineOffline?: "Online" | "Offline",
    url?: string
  ) => Promise<{ objectUID?: string; objectName?: string }>;
  getObject?: (id?: string, entity?: EntitySimpleJM) => Promise<{}>;
  setRAFObject?: (rafObject: any) => void;
  refresh?: any;
}>({
  objectId: null,
  objectName: null,
  rafObject: null,
  error: null,
  saveObject: null,
  getObject: null,
  refresh: null,
  setRAFObject: null,
});

export default function RAFObjectContextProvider({
  //objectId,
  children,
  ...props
}: PropsWithChildren<IProps>) {
  const [rafObject, setRafObject] = React.useState({
    objectId: props.objectId,
    objectName: null,
    rafObject: null,
    error: null,
  });

  const rolePermissionsContext = useContext(RolePermissionsContext);
  const permissionValue = isNotNullAndUndefined(rolePermissionsContext)
    ? rolePermissionsContext.permissionValue
    : null;

  const entityContext = useContext(RAFEntityContext);
  const entitySimpleJM: EntitySimpleJM = entityContext.entity;
  const formLibrary: FormLibraryRow = entityContext.formLibrary;
  const attributeContext = useContext(RAFAttributesContext);
  const queryAttributes = attributeContext.queryAttributes;
  const defaultField: QueryAttributeJM = isNotNullAndUndefined(queryAttributes)
    ? queryAttributes.find((x) => x.IsDefault === true)
    : null;

  let objectName;
  const getObject = (id, entity?: EntitySimpleJM) => {
    return new Promise<{} | string>((resolve) => {
      //in get read permissions and check if user has read permission

      if (
        hasPermission(permissionValue, `${props.moduleName}::Read`) ||
        props.moduleName === RAFEntityName.Employee //added as hot fix for my documents 13-7-2024 by Hari
      ) {
        if (isNotNullAndUndefined(id)) {
          let progressDiv = isNotNullAndUndefined(props.progressDivId)
            ? showProgress(props.progressDivId)
            : showProgress(`divObjectContext_${props.objectId}`);
          let url = "DataList/Retrieve";
          if (entity && entity.EntityType === RAFEntityType.UserForm) {
            //url = "DataList/RetrieveForm";
            if (
              entity &&
              entity.EntityName === CareEsioEntity.CareRecipient.EntityName
            ) {
              url = "CareRecipient/RetrieveForm";
            } else if (entity && entity.EntityName === RAFEntityName.Employee) {
              url = "Employee/RetrieveForm";
            }
          }

          let objData: any = { EntityId: id };

          if (
            isNotNullAndUndefined(entity) &&
            entity.EntityType === RAFEntityType.DataList
          ) {
            let moduleName = getURLPrefixByModuleName(
              entitySimpleJM.EntityName,
              null,
              entitySimpleJM.UID
            );
            url = `${moduleName}/Retrieve`;
            if (url === "DataList/Retrieve") {
              objData = {
                EntityId: id,
                EntityUID: entity.UID,
              };
            }
          } else if (isNotNullAndUndefined(entity)) {
            objData = { EntityId: id, EntityUID: entity.UID };
          }

          retrieveObjectByUID(id, entity.EntityName).then(
            (objRafObjectFromDB) => {
              if (
                isNotNullAndUndefined(objRafObjectFromDB) &&
                !IsNullOrWhiteSpace(objRafObjectFromDB.UID)
              ) {
                hideProgress(progressDiv);
                resolve(objRafObjectFromDB);
              } else {
                repositoryActions
                  .postDataAndGetResponse(
                    url,
                    objData,
                    null,
                    ContentType.applicationJson
                  )
                  .then((response) => {
                    hideProgress(progressDiv);
                    if (response.status === 204) {
                      resolve(Constants.RAFObject_NoContent);
                    } else {
                      if (
                        isNotNullAndUndefined(entity) &&
                        entity.EntityType === RAFEntityType.DataList
                      ) {
                        if (url === "DataList/Retrieve") {
                          if (
                            response.data &&
                            response.data.ResultTable &&
                            response.data.ResultTable.length > 0
                          ) {
                            if (entity.EntityName === RAFEntityName.Contact) {
                              let contactRow = ConvertContactDtoToContactRow(
                                response.data.ResultTable[0]
                              );
                              resolve(contactRow);
                            } else {
                              resolve(response.data.ResultTable[0]);
                            }
                          } else {
                            resolve(Constants.RAFObject_NoContent);
                          }
                        } else if (
                          isNotNullAndUndefined(response) &&
                          isNotNullAndUndefined(response.data) &&
                          isNotNullAndUndefined(response.data.Entity)
                        ) {
                          resolve(response.data.Entity);
                        } else {
                          resolve(Constants.RAFObject_NoContent);
                        }
                      } else {
                        if (
                          response.data &&
                          response.data.ResultTable &&
                          response.data.ResultTable.length > 0
                        ) {
                          resolve(response.data.ResultTable[0]);
                        } else {
                          resolve(Constants.RAFObject_NoContent);
                        }
                      }
                    }
                  });
              }
            }
          );
        } else {
          resolve(Constants.RAFObject_NoContent);
        }
      } else {
        resolve(Constants.RAFObject_NoContent);
      }
    });
  };

  const saveObject = (
    objectData: any,
    onlineOffline?: "Online" | "Offline",
    url?: string
  ) => {
    return new Promise<{ objectUID?: string; objectName?: string }>(
      async (resolve) => {
        // console.log(
        //   "RAFObjectContextProvider saveObject 1",
        //   onlineOffline,
        //   entityContext.entity.EntityName
        // );
        const objectUID = isNotNullAndUndefined(objectData)
          ? objectData.UID
          : null;
        if (
          hasPermission(
            permissionValue,
            isNotNullAndUndefined(objectUID)
              ? `${props.moduleName}::Update`
              : `${props.moduleName}::ADD`
          )
        ) {
          if (onlineOffline === "Offline") {
            debugger;
            const savedObject = await saveDataByModuleName(
              entityContext.entity.EntityName,
              objectData
            );
            if (
              entityContext.entity.EntityName == RAFEntityName.TenantSettings
            ) {
              removeSessionStorage(StorageKey.UserTenantSettings);
            }
            //console.log("RAFObjectContextProvider 2", savedObject);
            resolve({
              objectUID: savedObject.UID,
              objectName: null, //response.data.ObjectName,
            });
          } else {
            let progressDiv = isNotNullAndUndefined(props.progressDivId)
              ? showProgress(props.progressDivId)
              : showProgress(`divObjectContext_${props.objectId}`);
            let response = await submitRAFForm(
              objectData,
              props.objectId,
              entitySimpleJM,
              formLibrary,
              url
            );
            if (
              entityContext.entity.EntityName == RAFEntityName.TenantSettings
            ) {
              removeSessionStorage(StorageKey.UserTenantSettings);
            }
            hideProgress(progressDiv);
            resolve(response);
          }
        } else {
          resolve(null);
        }
      }
    );
  };

  const setRAFObject = (updatedObject: any) => {
    setRafObject({
      objectId: rafObject.objectId,
      objectName: rafObject.objectName,
      rafObject: hasPermission(permissionValue, `${props.moduleName}::Read`)
        ? updatedObject
        : null,
      error: hasPermission(permissionValue, `${props.moduleName}::Read`)
        ? null
        : Constants.RAFObject_NoContent,
    });
  };

  React.useEffect(() => {
    if (IsNullOrWhiteSpace(props.objectId)) {
      return;
    }

    refresh(props.objectId, props.objectData);
  }, [props.objectId, props.objectData]);

  const refresh = (objectId, objectData) => {
    if (isNotNullAndUndefined(objectData)) {
      objectName = isNotNullAndUndefined(defaultField)
        ? objectData[defaultField.PropertyName]
        : null;
      setRafObject({
        objectId,
        objectName,
        rafObject: hasPermission(permissionValue, `${props.moduleName}::Read`)
          ? objectData
          : null,
        error: hasPermission(permissionValue, `${props.moduleName}::Read`)
          ? null
          : Constants.RAFObject_NoContent,
      });
    } else {
      getObject(objectId, entitySimpleJM).then((rafObject) => {
        if (
          isString(rafObject) &&
          rafObject === Constants.RAFObject_NoContent
        ) {
          setRafObject({
            objectId,
            objectName: null,
            rafObject: null,
            error: rafObject,
          });
        } else {
          objectName = isNotNullAndUndefined(defaultField)
            ? rafObject[defaultField.PropertyName]
            : null;

          setRafObject({
            objectId,
            objectName,
            rafObject,
            error: null,
          });
        }
      });
    }
  };

  let children1 = wrapChildrenWith(children, {
    objectId: rafObject.objectId,
    objectName: rafObject.objectName,
    rafObject: rafObject.rafObject,
    error: rafObject.error,
    saveObject: saveObject,
    getObject: getObject,
  });

  return (
    <div className="h-100" id={`divObjectContext_${props.objectId}`}>
      <RAFObjectContext.Provider
        value={{
          objectId: rafObject.objectId,
          objectName: rafObject.objectName,
          rafObject: rafObject.rafObject,
          error: rafObject.error,
          saveObject: saveObject,
          getObject: getObject,
          refresh: refresh,
          setRAFObject: setRAFObject,
        }}
      >
        {children1}
      </RAFObjectContext.Provider>
    </div>
  );
}
