import { atomFamily } from "recoil";
import { getDashboardListByModuleName } from "../../../RAFComponents/Dashboard/DashboardHelper";
import RAFGridColumn from "../../../RAFComponents/Grid/RAFGridColumn";
import {
  getReportListByModuleName,
  getSessionStorage,
  getUserFocusMyList,
  getUserViewsByModuleName,
  setSessionStorage,
} from "../../../RAFComponents/helpers/AppHelper";
import { hasPermission } from "../../../RAFComponents/helpers/PermissionHelper";
import { getCollectionNameByModuleName } from "../../../RAFComponents/helpers/RAFMenuHelper";
import {
  ConvertToElementID,
  IsNullOrWhiteSpace,
  isNotEmptyArray,
  isNotNullAndUndefined,
  isNullOrUndefined,
  propertyOf,
} from "../../../RAFComponents/helpers/utils";
import { DashboardJM } from "../../../RAFComponents/models/Common/DashboardJM";
import { RAFViewRow } from "../../../RAFComponents/models/Common/RAFViewRow";
import { ReportJM } from "../../../RAFComponents/models/Common/ReportJM";
import { ServiceTransactionPermissionConstants } from "../../../constants/CareESIO/CareESIOPermissionConstant";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import {
  BrowserIsDevice,
  Constants,
  RAFGridView,
  RAFReportType,
  RAFTreeViewMenu,
  RAFViewType,
  SFColumnType,
  StorageKey,
} from "../../../constants/Common/Constants";
import { RAFEntityName } from "../../../constants/Common/EntityConstants";
import { getURLPrefixByModuleName } from "../../../helpers/Common/URLPrefixByModuleName";
import { ContentLibraryRow } from "../../ActiveContacts/Document/Library/ContentLibraryRow";
import {
  TaskDropdownItems,
  TaskRow,
  TaskViewMenuItem,
} from "../../ActiveContacts/Task/TaskRow";
import { RoleRow } from "../Role/RoleRow";
import { PortalDefaultView } from "../Workspace/WorkspaceHelper";

export const atomSelectedGridItemsState = atomFamily<any, any>({
  key: "selectedGridItemsState",
  default: null,
});

export const atomEditingRecordId = atomFamily<any, any>({
  key: "editingRecordIdState",
  default: null,
});

export const atomSelectedViewHeaderItem = atomFamily<any, any>({
  key: "selectedViewHeaderItemState",
  default: null,
  //used to store recently viewed view header for each module like Dashboard(1) or List(2) or Analytics(3)
  //use constant instead of 1 in default
});

export const atomSelectedViewState = atomFamily<any, any>({
  key: "selectedViewState",
  default: null as RAFTreeViewMenu,
  //used to store recently viewed view for each module
});

export const atomSelectedGridFilterState = atomFamily<any, any>({
  key: "selectedGridFilterState",
  default: null as RAFTreeViewMenu,
  //used to store recently viewed view for each module
});

export const atomOnlineOfflineState = atomFamily<any, any>({
  key: "onlineOfflineState",
  default: null,
});

export const atomSelectedGridView = atomFamily<any, any>({
  key: "selectedGridView",
  default: RAFGridView.ListView,
});

export const atomToggleGridCardContent = atomFamily<any, any>({
  key: "toggleGridCardContent",
  default: "card", //grid
});

// export const atomGridRefreshTime = atomFamily<any, any>({
//     key: "gridRefreshTimeState",
//     default: null,
// });

// export const getDefaultViewAndGridColumns = (moduleName: string) => {
//     let defaultViewAndGridColumns = { defaultView: null as RAFViewRow, defaultGridColumns: null as RAFDefaultGridColumnProps[] };
//     if (moduleName === RAFEntityName.Contact) {
//         defaultViewAndGridColumns.defaultView = {
//             UID: null,
//             ViewName: "allcontacts_default",
//             DisplayName: "All Contacts",
//             ViewType: RAFReportType.Table,
//             SettingsJson: {},
//             IsSystem: true,
//         };

//         defaultViewAndGridColumns.defaultGridColumns = [
//             {
//                 field: "ContactName",
//                 headerText: "Contact Name",
//                 type: SFColumnType.string
//             },
//             {
//                 field: "Account",
//                 headerText: "Company",
//                 type: SFColumnType.string
//             },
//             {
//                 field: "Type",
//                 headerText: "Type",
//                 type: SFColumnType.string
//             }
//         ];
//     } else if (moduleName === RAFEntityName.Account) {
//         defaultViewAndGridColumns.defaultView = {
//             UID: null,
//             ViewName: "allaccounts_default",
//             DisplayName: "All Accounts",
//             ViewType: RAFReportType.Table,
//             SettingsJson: {},
//             IsSystem: true,
//         };

//         defaultViewAndGridColumns.defaultGridColumns = [
//             {
//                 field: "AccountName",
//                 headerText: "Account Name",
//                 type: SFColumnType.string
//             },
//             {
//                 field: "Website",
//                 headerText: "Website",
//                 type: SFColumnType.string
//             }
//         ];
//     } else if (moduleName === RAFEntityName.Ticket) {
//         defaultViewAndGridColumns.defaultView = {
//             UID: null,
//             ViewName: "alltickets_default",
//             DisplayName: "All Tickets",
//             ViewType: RAFReportType.Table,
//             SettingsJson: {},
//             IsSystem: true,
//         };
//         defaultViewAndGridColumns.defaultGridColumns = [
//             {
//                 field: "Subject",
//                 headerText: "Subject",
//                 type: SFColumnType.string
//             },
//             {
//                 field: "TicketId",
//                 headerText: "Ticket Id",
//                 type: SFColumnType.string
//             },
//             {
//                 field: "Account",
//                 headerText: "Company Name",
//                 type: SFColumnType.string
//             },
//             {
//                 field: "Contact",
//                 headerText: "Contact Name",
//                 type: SFColumnType.string
//             },
//             {
//                 field: "Priority",
//                 headerText: "Priority",
//                 type: SFColumnType.string
//             },
//             {
//                 field: "RequestOrigin",
//                 headerText: "Request Origin",
//                 type: SFColumnType.string
//             },
//             {
//                 field: "TicketType",
//                 headerText: "Ticket Type",
//                 type: SFColumnType.string
//             },
//         ];
//     } else if (moduleName === RAFEntityName.Deal) {
//         defaultViewAndGridColumns.defaultView = {
//             UID: null,
//             ViewName: "alldeals_default",
//             DisplayName: "All Deals",
//             ViewType: RAFReportType.Table,
//             SettingsJson: {},
//             IsSystem: true,
//         };

//         defaultViewAndGridColumns.defaultGridColumns = [
//             {
//                 field: "DealName",
//                 headerText: "Deal Name",
//                 type: SFColumnType.string
//             }
//         ];
//     } else {
//         defaultViewAndGridColumns.defaultView = {
//             UID: null,
//             ViewName: 'all_default',
//             DisplayName: "All Views",
//             ViewType: RAFReportType.Table,
//             SettingsJson: {},
//             IsSystem: true,
//         };
//     }
//     return defaultViewAndGridColumns;
// };

//export const getListPageiewMenuItem = (allViews: RAFViewRow[], allReports: ReportJM[], allDashboards: DashboardJM[]) => {
export const getViewsReportsDashboards = (
  moduleName: string,
  addEmptyView?: boolean,
  disableDashboardAnalytic?: boolean,
  defaultType?: string
) => {
  return new Promise<RAFTreeViewMenu[]>(async (resolve) => {
    const [allViews, userFocusMyList] = await Promise.all([
      getUserViewsByModuleName(moduleName, addEmptyView),
      getUserFocusMyList(`entity_view_${moduleName}`),
    ]);
    let allReports: ReportJM[] = null;
    let allDashboards: DashboardJM[] = null;

    if (disableDashboardAnalytic !== true) {
      [allReports, allDashboards] = await Promise.all([
        getReportListByModuleName(moduleName),
        getDashboardListByModuleName(moduleName),
      ]);
    }

    let viewMenuDataSource: RAFTreeViewMenu[] = [];
    if (isNotEmptyArray(allViews)) {
      allViews.forEach((item) => {
        const focusedUserItem = isNotEmptyArray(userFocusMyList)
          ? userFocusMyList.find((x) => x.RelatedToUID === item.UID)
          : null;

        const viewData: RAFTreeViewMenu = {
          UID: item.UID,
          Name: item.ViewName,
          DisplayName: item.DisplayName,
          Category: isNotNullAndUndefined(defaultType)
            ? defaultType
            : item.ViewType === RAFReportType.Group
            ? RAFReportType.Group
            : item.ViewType === RAFReportType.Report
            ? RAFReportType.Report
            : RAFReportType.Table,
          IsPrivate: item.SettingsJson && item.SettingsJson.IsPrivate,
          IsSystem: item.IsSystem,
          ViewType: item.IsSystem
            ? RAFViewType.SystemView
            : item.SettingsJson && item.SettingsJson.IsPrivate
            ? RAFViewType.MyView
            : RAFViewType.SharedView,
          IsDefault: item.Isdefault,
          PermissionName: item.PermissionName ?? `${moduleName}::Read`,
          IsFocused: isNotNullAndUndefined(focusedUserItem) ? true : false,
          FocusUserUID: isNotNullAndUndefined(focusedUserItem)
            ? focusedUserItem.FocusUserUID
            : null,
        };
        viewMenuDataSource.push(viewData);
      });
    }

    if (isNotNullAndUndefined(allReports) && allReports.length > 0) {
      allReports.forEach((item) => {
        const focusedUserItem = isNotEmptyArray(userFocusMyList)
          ? userFocusMyList.find((x) => x.RelatedToUID === item.UID)
          : null;

        let reportData: RAFTreeViewMenu = {
          UID: item.UID,
          Name: item.Title,
          DisplayName: item.Title,
          Category: RAFReportType.Analytic,
          ViewType: item.ReportType,
          ParentID: item.EntityViewUID,
          IsFocused: isNotNullAndUndefined(focusedUserItem) ? true : false,
          FocusUserUID: isNotNullAndUndefined(focusedUserItem)
            ? focusedUserItem.FocusUserUID
            : null,
        };
        viewMenuDataSource.push(reportData);
      });
    }

    if (isNotNullAndUndefined(allDashboards) && allDashboards.length > 0) {
      allDashboards.forEach((item) => {
        const focusedUserItem = isNotEmptyArray(userFocusMyList)
          ? userFocusMyList.find((x) => x.RelatedToUID === item.UID)
          : null;

        let dashboardData: RAFTreeViewMenu = {
          UID: item.UID,
          Name: item.Title,
          DisplayName: item.Title,
          Category: RAFReportType.Dashboard,
          IsFocused: isNotNullAndUndefined(focusedUserItem) ? true : false,
          FocusUserUID: isNotNullAndUndefined(focusedUserItem)
            ? focusedUserItem.FocusUserUID
            : null,
        };
        viewMenuDataSource.push(dashboardData);
      });
    }
    resolve(viewMenuDataSource);
  });
};

class RAFViewRowWithColumns extends RAFViewRow {
  url?: string;
  gridColumns?: RAFDefaultGridColumnProps[];
}

export interface RAFDefaultGridColumnProps {
  field: string;
  type?: SFColumnType;
  headerText?: string;
  visible?: boolean;
  //template?: React.ReactElement,
  template?: any;
  width?: string | number;
  minWidth?: string | number;
  maxWidth?: string | number;
  valueAccessor?: any;
  //textAlign?: TextAlign;
  //headerTextAlign?: TextAlign;
  isPrimaryKey?: boolean;
  isEditable?: boolean;
}

const getDefaultGridColumnsBymoduleName = (
  moduleName: string,
  viewName?: string
) => {
  let defaultGridColumns: RAFDefaultGridColumnProps[];
  if (moduleName === RAFEntityName.Contact) {
    defaultGridColumns = [
      {
        field: "ContactName",
        headerText: "Contact Name",
        type: SFColumnType.string,
      },
      {
        field: "Account",
        headerText: "Company",
        type: SFColumnType.string,
      },
      {
        field: "Type",
        headerText: "Type",
        type: SFColumnType.string,
      },
    ];
  } else if (moduleName === RAFEntityName.Account) {
    defaultGridColumns = [
      {
        field: "AccountName",
        headerText: "Account Name",
        type: SFColumnType.string,
      },
      {
        field: "Website",
        headerText: "Website",
        type: SFColumnType.string,
      },
    ];
  } else if (moduleName === RAFEntityName.Ticket) {
    defaultGridColumns = [
      {
        field: "Subject",
        headerText: "Subject",
        type: SFColumnType.string,
      },
      {
        field: "TicketId",
        headerText: "Ticket Id",
        type: SFColumnType.string,
      },
      {
        field: "Account",
        headerText: "Company Name",
        type: SFColumnType.string,
      },
      {
        field: "Contact",
        headerText: "Contact Name",
        type: SFColumnType.string,
      },
      {
        field: "Priority",
        headerText: "Priority",
        type: SFColumnType.string,
      },
      {
        field: "RequestOrigin",
        headerText: "Request Origin",
        type: SFColumnType.string,
      },
      {
        field: "TicketType",
        headerText: "Ticket Type",
        type: SFColumnType.string,
      },
    ];
  } else if (moduleName === RAFEntityName.Deal) {
    defaultGridColumns = [
      {
        field: "DealName",
        headerText: "Deal Name",
        type: SFColumnType.string,
      },
    ];
  } else if (moduleName === RAFEntityName.ContentLibrary) {
    defaultGridColumns = [
      {
        field: propertyOf<ContentLibraryRow>("DisplayName"),
        headerText: "Display Name",
        type: SFColumnType.string,
      },
      {
        field: propertyOf<ContentLibraryRow>("FileName"),
        headerText: "File Name",
        type: SFColumnType.string,
      },
      {
        field: propertyOf<ContentLibraryRow>("DocumentType"),
        headerText: "Document Type",
        type: SFColumnType.string,
      },
    ];
  } else if (moduleName === RAFEntityName.Category) {
    defaultGridColumns = [
      {
        field: "Title",
        headerText: "Title",
        type: SFColumnType.string,
      },
      {
        field: "Parent",
        headerText: "Parent",
        type: SFColumnType.string,
      },
    ];
  } else if (moduleName === RAFEntityName.Task) {
    let defaultTaskGridColumns: RAFDefaultGridColumnProps[] = [
      {
        field: propertyOf<TaskRow>("TaskDate"),
        headerText: "Date",
        width: "100",
        minWidth: "100",
        type: SFColumnType.string,
      },
    ];

    if (!BrowserIsDevice) {
      defaultTaskGridColumns.push({
        field: propertyOf<TaskRow>("TaskType"),
        headerText: "Type",
        width: "80",
        minWidth: "80",
        type: SFColumnType.string,
      });
    }
    defaultTaskGridColumns.push(
      {
        field: propertyOf<TaskRow>("Title"),
        headerText: "Title",
        type: SFColumnType.string,
      },
      {
        field: propertyOf<TaskRow>("RelatedTo"),
        headerText: "Related To",
        width: "150",
        minWidth: "150",
      }
    );
    if (
      viewName === TaskDropdownItems.MyTask ||
      TaskDropdownItems.AssignedToMe
    ) {
    } else if (
      viewName === TaskDropdownItems.AssignedToMe ||
      TaskDropdownItems.Completed
    ) {
      defaultTaskGridColumns.push({
        field: propertyOf<TaskRow>("Assignee"),
        headerText: "Assigned To",
        minWidth: "80",
        type: SFColumnType.date,
      });
    }

    defaultTaskGridColumns.push({
      field: propertyOf<TaskRow>("ModifiedDate"),
      headerText: "Updated",
      minWidth: "80",
      type: SFColumnType.date,
    });

    return defaultTaskGridColumns;
  }
  return defaultGridColumns;
};

const allModulesDefaultViews: {
  moduleName: string;
  views: RAFViewRowWithColumns[];
}[] = [
  {
    moduleName: RAFEntityName.Account,
    views: [
      {
        UID: null,
        ViewName: `allaccount_default`,
        DisplayName: `All Accounts`,
        ViewType: RAFReportType.Table,
        SettingsJson: {},
        IsSystem: true,
        url: "Account/List",
        gridColumns: getDefaultGridColumnsBymoduleName(RAFEntityName.Account),
      },
      {
        UID: null,
        ViewName: `recentlyUpdatedaccount_default`,
        DisplayName: `Recently Updated`,
        ViewType: RAFReportType.Table,
        SettingsJson: {},
        IsSystem: true,
        gridColumns: getDefaultGridColumnsBymoduleName(RAFEntityName.Account),
      },
    ],
  },
  {
    moduleName: RAFEntityName.Contact,
    views: [
      {
        UID: null,
        ViewName: `allcontact_default`,
        DisplayName: `All Contacts`,
        ViewType: RAFReportType.Table,
        SettingsJson: {},
        IsSystem: true,
        gridColumns: getDefaultGridColumnsBymoduleName(RAFEntityName.Contact),
      },
      {
        UID: null,
        ViewName: `recentlyUpdatedcontact_default`,
        DisplayName: `Recently Updated`,
        ViewType: RAFReportType.Table,
        SettingsJson: {},
        IsSystem: true,
        gridColumns: getDefaultGridColumnsBymoduleName(RAFEntityName.Contact),
      },
    ],
  },
  {
    moduleName: RAFEntityName.Deal,
    views: [
      {
        UID: null,
        ViewName: `alldeal_default`,
        DisplayName: `All Deal`,
        ViewType: RAFReportType.Table,
        SettingsJson: {},
        IsSystem: true,
        gridColumns: getDefaultGridColumnsBymoduleName(RAFEntityName.Deal),
      },
      {
        UID: null,
        ViewName: `recentlyUpdateddeal_default`,
        DisplayName: `Recently Updated`,
        ViewType: RAFReportType.Table,
        SettingsJson: {},
        IsSystem: true,
        gridColumns: getDefaultGridColumnsBymoduleName(RAFEntityName.Deal),
      },
    ],
  },
  {
    moduleName: RAFEntityName.Ticket,
    views: [
      {
        UID: null,
        ViewName: `allticket_default`,
        DisplayName: `All Ticket`,
        ViewType: RAFReportType.Table,
        SettingsJson: {},
        IsSystem: true,
        gridColumns: getDefaultGridColumnsBymoduleName(RAFEntityName.Ticket),
      },
      {
        UID: null,
        ViewName: `recentlyUpdatedticket_default`,
        DisplayName: `Recently Updated`,
        ViewType: RAFReportType.Table,
        SettingsJson: {},
        IsSystem: true,
        gridColumns: getDefaultGridColumnsBymoduleName(RAFEntityName.Ticket),
      },
    ],
  },
  {
    moduleName: RAFEntityName.ContentLibrary,
    views: [
      {
        UID: null,
        ViewName: `all_status`,
        DisplayName: `All`,
        ViewType: RAFReportType.Table,
        SettingsJson: {},
        IsSystem: true,
        gridColumns: getDefaultGridColumnsBymoduleName(
          RAFEntityName.ContentLibrary
        ),
      },
      {
        UID: null,
        ViewName: `published`,
        DisplayName: `Published`,
        ViewType: RAFReportType.Table,
        SettingsJson: {},
        IsSystem: true,
        gridColumns: getDefaultGridColumnsBymoduleName(
          RAFEntityName.ContentLibrary
        ),
      },
      {
        UID: null,
        ViewName: `draft`,
        DisplayName: `Draft`,
        ViewType: RAFReportType.Table,
        SettingsJson: {},
        IsSystem: true,
        gridColumns: getDefaultGridColumnsBymoduleName(
          RAFEntityName.ContentLibrary
        ),
      },
      {
        UID: null,
        ViewName: `archived`,
        DisplayName: `Archived`,
        ViewType: RAFReportType.Table,
        SettingsJson: {},
        IsSystem: true,
        gridColumns: getDefaultGridColumnsBymoduleName(
          RAFEntityName.ContentLibrary
        ),
      },
    ],
  },
  {
    moduleName: RAFEntityName.Portal,
    views: [
      {
        UID: null,
        ViewName: PortalDefaultView.MyPortalView.Id,
        DisplayName: PortalDefaultView.MyPortalView.DisplayName,
        ViewType: RAFReportType.Table,
        SettingsJson: {},
        IsSystem: true,
        gridColumns: getDefaultGridColumnsBymoduleName(RAFEntityName.Portal),
      },
      {
        UID: null,
        ViewName: PortalDefaultView.MyOrganizationPortalView.Id,
        DisplayName: PortalDefaultView.MyOrganizationPortalView.DisplayName,
        ViewType: RAFReportType.Table,
        SettingsJson: {},
        IsSystem: true,
        gridColumns: getDefaultGridColumnsBymoduleName(RAFEntityName.Portal),
      },
    ],
  },
  {
    moduleName: RAFEntityName.Category,
    views: [
      {
        UID: null,
        ViewName: RAFEntityName.BusinessProcessTemplate,
        DisplayName: "Business Process",
        ViewType: RAFReportType.Table,
        SettingsJson: {},
        IsSystem: true,
        url: "category/list",
        gridColumns: getDefaultGridColumnsBymoduleName(RAFEntityName.Category),
      },
      {
        UID: null,
        ViewName: RAFEntityName.FormLibrary,
        DisplayName: "Form Library",
        ViewType: RAFReportType.Table,
        SettingsJson: {},
        IsSystem: true,
        url: "category/list",
        gridColumns: getDefaultGridColumnsBymoduleName(RAFEntityName.Category),
      },
      {
        UID: null,
        ViewName: "assessment",
        DisplayName: "Assessments",
        ViewType: RAFReportType.Table,
        SettingsJson: {},
        url: "category/list",
        IsSystem: true,
        gridColumns: getDefaultGridColumnsBymoduleName(RAFEntityName.Category),
      },
    ],
  },
  {
    moduleName: RAFEntityName.FormLibraryStatic,
    views: [
      //commented by hari
      // {
      //   UID: null,
      //   ViewName: RAFEntityName.BusinessProcessTemplate,
      //   DisplayName: "Business Process",
      //   ViewType: RAFReportType.Table,
      //   SettingsJson: {},
      //   IsSystem: true,
      //   url: "formlibrary/list",
      //   //gridColumns: getDefaultGridColumnsBymoduleName(RAFEntityName.Category),
      // },
      {
        UID: null,
        ViewName: RAFEntityName.FormLibrary,
        DisplayName: "Form Library",
        ViewType: RAFReportType.Table,
        SettingsJson: {},
        IsSystem: true,
        url: "formlibrary/list",
        //gridColumns: getDefaultGridColumnsBymoduleName(RAFEntityName.Category),
      },
      {
        UID: null,
        ViewName: "assessment",
        DisplayName: "Assessments",
        ViewType: RAFReportType.Table,
        SettingsJson: {},
        url: "formlibrary/list",
        IsSystem: true,
        //gridColumns: getDefaultGridColumnsBymoduleName(RAFEntityName.Category),
      },
      {
        UID: null,
        ViewName: "care_shift_log",
        DisplayName: "Shift Log",
        ViewType: RAFReportType.Table,
        SettingsJson: {},
        url: "formlibrary/list",
        IsSystem: true,
        //gridColumns: getDefaultGridColumnsBymoduleName(RAFEntityName.Category),
      },
      {
        UID: null,
        ViewName: "cqi_register",
        DisplayName: "CQI Register",
        ViewType: RAFReportType.Table,
        SettingsJson: {},
        url: "formlibrary/list",
        IsSystem: true,
        //gridColumns: getDefaultGridColumnsBymoduleName(RAFEntityName.Category),
      },
      {
        UID: null,
        ViewName: "recurring_form",
        DisplayName: "Recurring Form",
        ViewType: RAFReportType.Table,
        SettingsJson: {},
        url: "formlibrary/list",
        IsSystem: true,
        //gridColumns: getDefaultGridColumnsBymoduleName(RAFEntityName.Category),
      },
    ],
  },
  {
    moduleName: RAFEntityName.Task,
    views: [
      {
        UID: null,
        ViewName: TaskViewMenuItem.UpcomingTasks.value,
        DisplayName: TaskViewMenuItem.UpcomingTasks.text,
        ViewType: RAFReportType.Table,
        SettingsJson: {},
        IsSystem: true,
        url: "taskdashboard",
      },
      {
        UID: null,
        ViewName: TaskViewMenuItem.AssignedByMe.value,
        DisplayName: TaskViewMenuItem.AssignedByMe.text,
        ViewType: RAFReportType.Table,
        SettingsJson: {},
        IsSystem: true,
        url: "taskdashboard",
      },
      {
        UID: null,
        ViewName: TaskViewMenuItem.CompletedTasks.value,
        DisplayName: TaskViewMenuItem.CompletedTasks.text,
        ViewType: RAFReportType.Table,
        SettingsJson: {},
        IsSystem: true,
        url: "taskdashboard",
      },
    ],
  },
  {
    moduleName: RAFEntityName.ServiceTransaction,
    views: [
      {
        UID: null,
        ViewName: "my_shift",
        DisplayName: "My Shifts",
        ViewType: RAFReportType.Table,
        SettingsJson: {},
        IsSystem: true,
        url: "schedule",
        PermissionName:
          ServiceTransactionPermissionConstants.ServiceTransactionMyShifts,
      },
      {
        UID: null,
        ViewName: "all_shift",
        DisplayName: "Manage Shifts",
        ViewType: RAFReportType.Table,
        SettingsJson: {},
        IsSystem: true,
        url: "schedule",
        PermissionName:
          ServiceTransactionPermissionConstants.ServiceTransactionRead,
      },
    ],
  },
  {
    moduleName: CareEsioEntity.XeroIntegration.EntityName,
    views: [
      {
        UID: null,
        ViewName: "xero_integration",
        DisplayName: "Xero",
        ViewType: RAFReportType.Table,
        SettingsJson: {},
        IsSystem: true,
        url: "XeroIntegration",
      },
    ],
  },
];

export function getDefaultViewsByModuleName(
  moduleName: string
): RAFViewRowWithColumns[] {
  if (!IsNullOrWhiteSpace(moduleName)) {
    let defaultItem = allModulesDefaultViews.find(
      (x) => x.moduleName === moduleName
    );
    if (
      isNotNullAndUndefined(defaultItem) &&
      isNotNullAndUndefined(defaultItem.views)
    ) {
      return defaultItem.views;
    } else {
      let defaultView: RAFViewRowWithColumns[] = [
        {
          UID: null,
          ViewName: `all${moduleName}_default`,
          DisplayName: `All ${getCollectionNameByModuleName(moduleName)}`,
          ViewType: RAFReportType.Table,
          SettingsJson: {},
          IsSystem: true,
          PermissionName: `${moduleName}::Read`,
          //url: 'DataList/List',
          //Isdefault: true,
        },
      ];
      return defaultView;
    }
  } else {
    return null;
  }
}

export const getUrlByModuleAndView = (
  moduleName: string,
  currentView: RAFTreeViewMenu,
  URL?: string
) => {
  let url = isNotNullAndUndefined(URL)
    ? URL
    : `${getURLPrefixByModuleName(moduleName)}/List`;
  if (isNotNullAndUndefined(currentView)) {
    const defaultViews = getDefaultViewsByModuleName(moduleName);
    let item = isNotNullAndUndefined(defaultViews)
      ? defaultViews.find((x) => x.ViewName === currentView.Name)
      : null;
    // if (isNotNullAndUndefined(item) && isNotNullAndUndefined(item.url)) {
    //   url = item.url;
    // }
    if (isNotNullAndUndefined(item)) {
      if (isNotNullAndUndefined(item.url)) {
        const splitURL = isNotNullAndUndefined(item)
          ? item.url.split("/")
          : null;
        if (isNotEmptyArray(splitURL) && splitURL.length > 1) {
          url = item.url;
        }
      }
    }
  }
  if (url.includes(Constants.baseAPIUrl)) {
    return url;
  } else {
    return `${Constants.baseAPIUrl}${url}`;
  }
};

export const getGridColumnsByModuleAndView = (
  moduleName?: string,
  currentView?: RAFTreeViewMenu,
  viewName?: string
) => {
  const defaultViews = getDefaultViewsByModuleName(moduleName);
  let item: RAFViewRowWithColumns;

  if (
    isNotNullAndUndefined(defaultViews) &&
    isNotNullAndUndefined(currentView)
  ) {
    item = isNotNullAndUndefined(currentView)
      ? defaultViews.find((x) => x.ViewName === currentView.Name)
      : null;
  }
  if (isNotNullAndUndefined(defaultViews) && isNotNullAndUndefined(viewName)) {
    item = isNotNullAndUndefined(viewName)
      ? defaultViews.find((x) => x.ViewName === viewName)
      : null;
  }

  if (
    isNotNullAndUndefined(item) &&
    isNotNullAndUndefined(item.gridColumns) &&
    item.gridColumns.length > 0
  ) {
    return item.gridColumns.map((item, index) => {
      return (
        <RAFGridColumn
          key={index}
          field={item.field}
          headerText={item.headerText}
          type={item.type}
        />
      );
    });
  }
};

export const getViewElementId = (viewName: string, itemUID: string) => {
  let elementId;
  if (!IsNullOrWhiteSpace(viewName) && !IsNullOrWhiteSpace(itemUID)) {
    elementId = ConvertToElementID(`${viewName}_${itemUID}`);
  } else if (!IsNullOrWhiteSpace(itemUID)) {
    elementId = itemUID;
  } else if (!IsNullOrWhiteSpace(viewName)) {
    elementId = ConvertToElementID(viewName);
  }
  return elementId;
};

export const getDefaultActiveCurrentViewFromList = async (
  moduleName: string,
  viewId: string,
  selectedViewType: string,
  viewListItem: RAFTreeViewMenu[],
  disableDashboardAnalytic: boolean,
  permissionValue?: RoleRow,
  viewName?: string
) => {
  return new Promise<RAFTreeViewMenu>(async (resolve) => {
    const allViewMenuDataSource = isNotEmptyArray(viewListItem)
      ? viewListItem
      : await getViewsReportsDashboards(
          moduleName,
          disableDashboardAnalytic,
          false
        );

    const viewMenuDataSource = isNotEmptyArray(allViewMenuDataSource)
      ? allViewMenuDataSource.filter((x) => {
          const permissionName = x.PermissionName ?? `${moduleName}::Read`;
          const permission = isNotNullAndUndefined(permissionValue)
            ? hasPermission(permissionValue, permissionName)
            : true;
          return permission;
        })
      : [];

    const recentViews =
      JSON.parse(
        getSessionStorage(StorageKey.recentViewId_modulename + moduleName, true)
      ) || [];

    const recentViewId = isNotNullAndUndefined(viewId)
      ? viewId
      : recentViews["viewId"];

    const recentViewName = isNotNullAndUndefined(viewId)
      ? viewName
      : recentViews["viewName"];

    let currentView: RAFTreeViewMenu;
    if (
      isNotNullAndUndefined(recentViewId) &&
      !IsNullOrWhiteSpace(recentViewId) &&
      isNotEmptyArray(viewMenuDataSource)
    ) {
      currentView = viewMenuDataSource.find(
        (x) => x.UID === recentViewId || x.Name === recentViewId
      );
    }

    if (
      isNullOrUndefined(currentView) &&
      isNotNullAndUndefined(recentViewName) &&
      !IsNullOrWhiteSpace(recentViewName) &&
      isNotEmptyArray(viewMenuDataSource)
    ) {
      currentView = viewMenuDataSource.find(
        (x) => x.Name === recentViewId || x.Name === recentViewName
      );
    }
    if (
      isNotNullAndUndefined(currentView) &&
      isNotNullAndUndefined(currentView.UID)
    ) {
      if (
        isNotNullAndUndefined(selectedViewType) &&
        currentView.Category !== selectedViewType
      ) {
        currentView = null;
      }
    }
    if (isNullOrUndefined(currentView)) {
      let allViews = isNotEmptyArray(viewMenuDataSource)
        ? moduleName === RAFEntityName.Template
          ? viewMenuDataSource
          : viewMenuDataSource.filter((x) => x.Category === selectedViewType)
        : null;
      if (isNotEmptyArray(allViews)) {
        const focusedViews = allViews.filter((x) => x.IsFocused === true);
        const defaultViews = allViews.filter((x) => x.IsDefault === true);
        if (isNotEmptyArray(focusedViews)) {
          const defaultFocusedViews = focusedViews.filter(
            (x) => x.IsDefault === true
          );
          if (isNotEmptyArray(defaultFocusedViews)) {
            currentView = defaultFocusedViews[0];
          } else {
            currentView = focusedViews[0];
          }
        } else if (isNotEmptyArray(defaultViews)) {
          currentView = defaultViews[0];
        } else {
          currentView = allViews[0];
        }
      } else {
        const emptyView: RAFTreeViewMenu = {
          UID: null,
          ViewType: null,
          ParentID: null,
          IsSystem: null,
          IsPrivate: null,
          DisplayName: "No view found",
          Name: "No view found",
          Category: selectedViewType,
        };
        currentView = emptyView;
      }
    }
    if (
      isNotNullAndUndefined(currentView) &&
      (isNotNullAndUndefined(currentView.UID) ||
        isNotNullAndUndefined(currentView.Name))
    ) {
      setSessionStorage(
        StorageKey.recentViewId_modulename + moduleName,
        true,
        JSON.stringify({
          viewId: isNotNullAndUndefined(currentView.UID)
            ? currentView.UID
            : currentView.Name,
          viewName: currentView.DisplayName,
          category: currentView.Category,
        })
      );
    }
    resolve(currentView);
  });
};
