import React, { PropsWithChildren } from "react";
import { isNotNullAndUndefined } from "../../../RAFComponents/helpers/utils";
import RAFAttributeRelatedListProvider, {
    RAFAttributesContext
} from "../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import {
    RAFEntityType,
    RAFPageType
} from "../../../constants/Common/RMConstants";
import { RAFEntityContext } from "../Providers/RAFEntityProvider";
import RAFPageLayoutProvider from "../Providers/RAFPageLayoutProvider";
import RAFPageLayout from "../RAFPageLayout";

interface IProps {
  moduleName?: string;
  progressDivId?: string;
}

export default function EditContent({ moduleName, progressDivId }: PropsWithChildren<IProps>) {
  const entityRow = React.useContext(RAFEntityContext);
  const entity = entityRow.entity;
  return (
    <RAFAttributeRelatedListProvider moduleName={moduleName} progressDivId={progressDivId}>
      <RAFAttributesContext.Consumer>
        {({ attributeRelatedList }) => {
          if (entity.EntityType === RAFEntityType.UserForm) {
            return (
              <div className="h-100">
                {/* <RAFUserFormRender
                moduleName={entity.EntityName}
                attributeRelatedList={attributeRelatedList}
                rafPageType={RAFPageType.Edit}
                entityUID={entity.UID}
                isPropertyName
              /> */}
                <RAFPageLayoutProvider rafPageType={RAFPageType.Edit} mode={RAFPageType.Edit}
                  progressDivId={isNotNullAndUndefined(progressDivId) ? progressDivId : "#dlg_update_" + entity.EntityName}
                >
                  <RAFPageLayout />
                </RAFPageLayoutProvider>
              </div>
            );
          } else if (
            isNotNullAndUndefined(attributeRelatedList) &&
            isNotNullAndUndefined(attributeRelatedList.Attributes)
          ) {
            return (
              <RAFPageLayoutProvider rafPageType={RAFPageType.Edit} mode={RAFPageType.Edit}
                progressDivId={isNotNullAndUndefined(progressDivId) ? progressDivId : "#dlg_update_" + entity.EntityName}
              >
                <RAFPageLayout />
              </RAFPageLayoutProvider>
            );
          }
        }}
      </RAFAttributesContext.Consumer>
    </RAFAttributeRelatedListProvider>
  );
}
