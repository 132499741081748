import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import React, { Fragment, PropsWithChildren, useState } from "react";
import { PreventFocusOnDialogOpen } from "../../RAFComponents/Dialog/SFDialogUtils";
import { getDisplayNameByModuleName } from "../../RAFComponents/helpers/RAFMenuHelper";
import { RAFRelatedModuleSection } from "../../RAFMaster/RMModules/RAFPage/PageLayoutJM";
import {
  RAFButtonConstant,
} from "../../constants/Common/Constants";
import { RAFEntityName } from "../../constants/Common/EntityConstants";
import { RAFRelatedSectionStyle } from "../../constants/Common/RMConstants";
import CreateActivity from "../ActiveContacts/Activity/CreateActivity";
import CreateContact from "../ActiveContacts/Contact/CreateContact";
import CreateDeal from "../ActiveContacts/Deal/CreateDeal";
import DocumentSummary from "../ActiveContacts/Document/DocumentSummary";
import RAFManageUploadDocumentFile from "../ActiveContacts/Document/ManageDocument/RAFManageUploadDocumentFile";
import CreateTicket from "../ActiveContacts/Ticket/CreateTicket";
import RAFCreate from "./Create";
import { RAFEntityContext } from "./Providers/RAFEntityProvider";
import { RAFObjectContext } from "./Providers/RAFObjectContextProvider";

interface IProps {
  relatedEntity?: string;
  btnName?: string;
  secondaryRelatedToUID?: string;
  relatedSection?: RAFRelatedModuleSection;
}

export default function RAFRelatedButtons({
  // children,
  relatedEntity,
  relatedSection,
  secondaryRelatedToUID,
  btnName,
  ...props
}: PropsWithChildren<IProps>) {
  const rafObject = React.useContext(RAFObjectContext);
  const currentObject = rafObject.rafObject;
  const entityRow = React.useContext(RAFEntityContext);
  const entity = entityRow.entity;
  const [createContent, setCreateContent] = useState(false);
  const [showUploadContent, setshowUploadContent] = useState(false);

  const createContentDialogOpen = () => {
    setCreateContent(true);
  };

  const createContentDialogClose = () => {
    setCreateContent(false);
  };

  const onSaveCreateContent = () => {
    setCreateContent(false);
  };

  const createDialogContent = () => {
    let initialValues;

    if (relatedEntity === RAFEntityName.Contact) {
      return (
        <CreateContact
          onSave={onSaveCreateContent}
          onClose={createContentDialogClose}
          isActive
          initialValues={initialValues}
          {...props}
        />
      );
    } else if (relatedEntity === RAFEntityName.Deal) {
      return (
        <CreateDeal
          onSave={(objectId, objectName) => onSaveCreateContent}
          onClose={createContentDialogClose}
          isActive
          initialValues={initialValues}
          {...props}
        />
      );
    } else if (relatedEntity === RAFEntityName.Ticket) {
      return (
        <CreateTicket
          onSave={onSaveCreateContent}
          onClose={createContentDialogClose}
          isActive
          initialValues={initialValues}
          {...props}
        />
      );
    } else if (relatedEntity === RAFEntityName.Activity) {
      return (
        <div>
          {/* <CreateActivitySummary
            relatedToUID={rafObject.objectId}
            relatedTo={rafObject.objectName}
            moduleName={entity.EntityName}
            secondaryRelatedToUID={props.secondaryRelatedToUID}
            showEmptyState
            {...props}
          /> */}
          <CreateActivity
            onUpdate={() => {
              //relatedActivityUpdated();
              onSaveCreateContent();
            }}
            relatedToUID={rafObject.objectId}
            relatedTo={rafObject.objectName}
            entityName={entity.EntityName}
            secondaryRelatedToUID={secondaryRelatedToUID}
          />
        </div>
      );
    } else {
      return (
        <RAFCreate
          onSave={onSaveCreateContent}
          onClose={createContentDialogClose}
          isActive
          moduleName={relatedEntity}
          initialValues={initialValues}
        />
      );
    }
  };

  const uploadDocument = () => {
    setshowUploadContent(true);
  };

  const onDocumentUploaded = () => {
    uploadDialogClose();
  };

  const uploadAndLinkDocumentContent = () => {
    if (showUploadContent === true) {
      return (
        <RAFManageUploadDocumentFile
          onSave={(UID) => {
            onDocumentUploaded();
          }}
          onClose={uploadDialogClose}
          selectedFolder={null}
          //selectedDocumentRow={this.state.selectedDocumentRow}
          relatedToUID={rafObject.objectId}
          relatedTo={rafObject.objectName}
          relatedToType={entity.EntityName}
          relatedEntity={entity.EntityName}
          mode={"create"}
          isActive
          {...props}
        />
      );
    } else {
      return <div></div>;
    }
  };

  const uploadDialogClose = () => {
    setshowUploadContent(false);
  };

  if (relatedSection.ModuleName === RAFEntityName.Document) {
    if (
      relatedSection.Mode === RAFRelatedSectionStyle.AddButton ||
      relatedSection.Mode === RAFRelatedSectionStyle.AddwithSummary
    ) {
      return (
        <div>
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center">
              <ButtonComponent
                type="button"
                cssClass="e-small d-none primary-custom-button d-sm-inline-block"
                iconCss="fas fa-plus"
                onClick={() => uploadDocument()}
              >
                Add
              </ButtonComponent>
              <ButtonComponent
                type="button"
                cssClass="e-small primary-custom-button d-sm-none"
                iconCss="fas fa-plus"
                onClick={() => uploadDocument()}
              ></ButtonComponent>
            </div>
            {relatedSection.Mode === RAFRelatedSectionStyle.AddwithSummary && (
              <div>
                <span className="">{"2 " + relatedSection.DisplayName}</span>
              </div>
            )}
          </div>

          {showUploadContent === true && (
            <DialogComponent
              header={"Upload File"}
              showCloseIcon
              visible={showUploadContent}
              cssClass="centerDialog-sm createEditForm form-center-dialog dlg-new-style fixed-header"
              content={uploadAndLinkDocumentContent}
              isModal
              target="body"
              closeOnEscape={false}
              close={uploadDialogClose}
              id="manageUploadDocumentDialog"
              zIndex={1200}
              open={PreventFocusOnDialogOpen}
            ></DialogComponent>
          )}
        </div>
      );
    } else if (relatedSection.Mode === RAFRelatedSectionStyle.Relatedsection) {
      return (
        <div>
          <div className="e-card mb-1 mb-md-2">
            <div className="e-card-content py-0 px-0">
              <DocumentSummary
                relatedToUID={rafObject.objectId}
                relatedTo={rafObject.objectName}
                relatedToType={entity.EntityName}
                relatedEntity={entity.EntityName}
                allowFiltering={false}
                isRelatedSection
                {...props}
                collapsePanelArrowPosistion="title"
              />
            </div>
          </div>
        </div>
      );
    }
  } else {
    if (
      relatedSection.Mode === RAFRelatedSectionStyle.AddButton ||
      relatedSection.Mode === RAFRelatedSectionStyle.AddwithSummary
    ) {
      return (
        <div>
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center flex-nowrap">
              <ButtonComponent
                className="custom-button-md d-none primary-custom-button d-sm-inline-block"
                onClick={() => createContentDialogOpen()}
                content={"Add " + relatedSection.DisplayName}
                iconCss={RAFButtonConstant.Create.IconCss}
                type={"button"}
              ></ButtonComponent>
              <ButtonComponent
                className="custom-button-md d-sm-none primary-custom-button"
                onClick={() => createContentDialogOpen()}
                content={"Add " + relatedSection.DisplayName}
                iconCss={RAFButtonConstant.Create.IconCss}
                type={"button"}
              ></ButtonComponent>
            </div>
            {relatedSection.Mode === RAFRelatedSectionStyle.AddwithSummary && (
              <div>
                <span className="">{"2 " + relatedSection.DisplayName}</span>
              </div>
            )}
          </div>

          <Fragment>
            {createContent && (
              <DialogComponent
                // header={`Create ${getURLPrefixByModuleName(
                //   relatedEntity
                // )}`}
                // header={
                //   "Create " +
                //   getDisplayNameByModuleName(entity.EntityName, entity.DisplayName)
                // }
                id={`dlg_create_${relatedEntity}`}
                header={`Create ${getDisplayNameByModuleName(relatedEntity)}`}
                showCloseIcon
                visible={createContent}
                cssClass="rightDialog createEditForm full-height form-center-dialog"
                content={createDialogContent}
                isModal
                target="body"
                closeOnEscape={false}
                close={createContentDialogClose}
                zIndex={1200}
                open={PreventFocusOnDialogOpen}
              ></DialogComponent>
            )}
          </Fragment>
        </div>
      );
    } else if (relatedSection.Mode === RAFRelatedSectionStyle.Relatedsection) {
      return <div>Related section</div>;
    }
  }
}
