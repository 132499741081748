import React, { PropsWithChildren, ReactNode } from "react";
import { useRecoilValue } from "recoil";
import { onToggleSideMenu } from "../../../RAFComponents/helpers/RAFMenuHelper";
import { isNotNullAndUndefined } from "../../../RAFComponents/helpers/utils";
import { LookUpRow } from "../../../RAFComponents/models/CompositeTypes/LookUpRow";
import {
  BPTypeDefaultNodeTitle,
  atomSelectedBPType,
} from "../../../RAFMaster/RMModules/Category/BusinessProcessTypeHelper";
import { BusinessProcessTypeRow } from "../../../RAFMaster/RMModules/Category/BusinessProcessTypeRow";
import {
  HeaderTabItems,
  RAFHeaderTabItemsRow,
  atomSelectedLeftMenuTab,
} from "../../../components/shared/NavigationMenu/NavigationHelper";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import { RAFTreeViewMenu } from "../../../constants/Common/Constants";
import { RAFEntityName } from "../../../constants/Common/EntityConstants";
import { atomSelectedRecordType } from "../../ActiveContacts/Document/DocumentIndex/DocumentIndexHelper";
import { RAFSystemContentLibraryNode } from "../../ActiveContacts/Document/Library/DocumentHelper";
import { RecordCategoryRow } from "../../ActiveContacts/RecordCategory/RecordCategoryRow";
import { atomSelectedTagName } from "../../ActiveContacts/Tag/TagHelper";
import { atomSelectedViewState } from "./IndexHelper";
import RafListPageHeader from "./RafListPageHeader";

interface IProps {
  viewID?: string;
  title?: string;
  iconName?: string;
  moduleName?: string;
  selectedType?: string;
  iconClassName?: string;
  secondaryHeader?: string;
  tabHeaderItems?: {
    id: number;
    text: string;
    value: string;
    disabled: boolean;
  }[];
  selectedTabIndex?: number;
  showBackArrow?: boolean;
  showHamBurgerBtn?: boolean;
  showViewTypes?: boolean;
  hideSecondaryHeader?: boolean;
  showViewMenuTitleBtn?: boolean;
  showSelectedCategory?: boolean;
  isCheckTagTitle?: boolean;
  disableDashboardAnalytic?: boolean;
  //defaultViewItems: RAFViewRow[];
  currentView?: RAFTreeViewMenu;
  rightSection?: React.ReactFragment | ReactNode;
  viewMenuDataSource?: RAFTreeViewMenu[];
  backArrowClicked?: () => void;
  onSelectedType?: (item) => void;
  updateCurrentView?: (item: RAFTreeViewMenu) => void;
  secondaryModuleName?: string;
}

function RafIndexPageHeader({ ...props }: PropsWithChildren<IProps>) {
  const onToggleMenu = () => {
    onToggleSideMenu();
  };

  const currentView: RAFTreeViewMenu = useRecoilValue(
    atomSelectedViewState(props.moduleName)
  );
  const selectedTagNameStateValue: LookUpRow = useRecoilValue(
    atomSelectedTagName(props.moduleName)
  );

  const selectedBPTypeStateValue: BusinessProcessTypeRow = useRecoilValue(
    atomSelectedBPType(props.moduleName)
  );

  const selectedLeftMenuTabValue: RAFHeaderTabItemsRow = useRecoilValue(
    atomSelectedLeftMenuTab(props.moduleName)
  );

  const selectedRecordTypeValue: RecordCategoryRow = useRecoilValue(
    atomSelectedRecordType(props.moduleName)
  ); //:DocumentCategoryRow

  const getSecondaryHeaderText = () => {
    if (
      props.isCheckTagTitle === true &&
      isNotNullAndUndefined(selectedLeftMenuTabValue)
    ) {
      let text = null;

      if (
        isNotNullAndUndefined(currentView) &&
        isNotNullAndUndefined(currentView.DisplayName)
      ) {
        if (isNotNullAndUndefined(text)) {
          text = `${text} - ${currentView.DisplayName}`;
        } else {
          text = `${currentView.DisplayName}`;
        }
      }

      if (selectedLeftMenuTabValue.value === HeaderTabItems.Category.value) {
        if (props.moduleName === RAFEntityName.ContentLibrary) {
          const categoryName = isNotNullAndUndefined(selectedRecordTypeValue)
            ? isNotNullAndUndefined(selectedRecordTypeValue.Title)
              ? selectedRecordTypeValue.Title
              : selectedRecordTypeValue.Title
            : RAFSystemContentLibraryNode.RecordCategory.text;
          text = isNotNullAndUndefined(text)
            ? `${HeaderTabItems.Category.text} - ${categoryName}`
            : `${categoryName}`;
        } else {
          const selectedProcesstype =
            isNotNullAndUndefined(selectedBPTypeStateValue) &&
            isNotNullAndUndefined(selectedBPTypeStateValue.Title)
              ? selectedBPTypeStateValue.Title
              : props.moduleName === RAFEntityName.FormLibrary ||
                props.moduleName === RAFEntityName.BusinessProcessTemplate
              ? BPTypeDefaultNodeTitle
              : null;
          //props.moduleName === RAFEntityName.BusinessProcessTemplate) ? null : null;
          if (isNotNullAndUndefined(selectedProcesstype)) {
            text = isNotNullAndUndefined(text)
              ? `${text} - ${selectedProcesstype}`
              : `${selectedProcesstype}`;
          }
        }
      } else if (selectedLeftMenuTabValue.value === HeaderTabItems.Tags.value) {
        const tagValue =
          isNotNullAndUndefined(selectedTagNameStateValue) &&
          isNotNullAndUndefined(selectedTagNameStateValue.Value)
            ? selectedTagNameStateValue.Value
            : "All Tags";

        text = isNotNullAndUndefined(text)
          ? `${text} - Tag(${tagValue})`
          : `Tag(${tagValue})`;
      } else if (
        selectedLeftMenuTabValue.value === HeaderTabItems.Folders.value
      ) {
        text = isNotNullAndUndefined(text) ? `${text} - Folder` : `Folder`;
      }
      return text;
    } else {
      let text = null;
      if (isNotNullAndUndefined(props.secondaryHeader)) {
        text = props.secondaryHeader;
      }
      if (props.showSelectedCategory === true) {
        const selectedProcesstype =
          isNotNullAndUndefined(selectedBPTypeStateValue) &&
          isNotNullAndUndefined(selectedBPTypeStateValue.Title)
            ? selectedBPTypeStateValue.Title
            : props.moduleName === RAFEntityName.FormLibrary ||
              props.moduleName === RAFEntityName.BusinessProcessTemplate
            ? null
            : null;
        //props.moduleName === RAFEntityName.BusinessProcessTemplate) ? BPTypeDefaultNodeTitle : null;
        if (isNotNullAndUndefined(selectedProcesstype)) {
          text = isNotNullAndUndefined(text)
            ? `${text} - ${selectedProcesstype}`
            : selectedProcesstype;
        }
      }
      if (
        isNotNullAndUndefined(currentView) &&
        isNotNullAndUndefined(currentView.DisplayName)
      ) {
        if (isNotNullAndUndefined(text)) {
          text = `${text} - ${currentView.DisplayName}`;
        } else {
          text = currentView.DisplayName;
        }
      }
      if (
        isNotNullAndUndefined(selectedTagNameStateValue) &&
        isNotNullAndUndefined(selectedTagNameStateValue.Value)
      ) {
        // text = `${text} - Tag(${selectedTagNameStateValue.Value})`;
        text = `${text}`;
      }

      return text;
    }
  };

  const secondaryHeader =
    props.moduleName === RAFEntityName.ContentLibrary
      ? props.secondaryHeader
      : getSecondaryHeaderText();

  return (
    <RafListPageHeader
      title={props.title}
      secondaryTitle={secondaryHeader}
      showHamburgerBtn={props.showHamBurgerBtn}
      // hideSecondaryHeader={(props.moduleName === CareEsioEntity.CareRecipient.EntityName
      //   || props.moduleName === CareEsioEntity.Employee.EntityName
      //   || props.moduleName === RAFEntityName.Document
      //   || props.moduleName === RAFEntityName.ContentLibrary
      // )
      //   ? true : props.hideSecondaryHeader ? true : false}
      {...props}
    />
  );
}

export default React.memo(RafIndexPageHeader);
