import React, { PropsWithChildren, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import {
  RAFEventName,
  triggerRAFEvent,
} from "../../../RAFComponents/helpers/RAFDocumentEvents";
import { getDisplayNameByModuleName } from "../../../RAFComponents/helpers/RAFMenuHelper";
import { isNotNullAndUndefined } from "../../../RAFComponents/helpers/utils";
import RAFRelatedFocusList from "../../ActiveContacts/Dashboards/TaskDashboard/RAFRelatedFocusList";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import { RAFButtonConstant } from "../../../constants/Common/Constants";

interface IProps {
  centerSection?: React.ReactFragment | ReactNode;
  headerValue?: string;
  moduleName?: string;
  secondaryHeaderValue?: string;
  detailsBtn?: React.ReactFragment | ReactNode;
  customHeaderAvatar?: React.ReactFragment | ReactNode;
  customHeader?: React.ReactFragment | ReactNode;
  customSecondaryHeader?: React.ReactFragment | ReactNode;
  moduleavatar?: string;
  onBackArrowClicked?: () => void;
  objectRow?: any;
  showRecordInfo?: boolean;
  showRelatedFocusList?: boolean;
  showSecondaryHeader?: boolean;
  mode?: string;
  showToggleBtn?: boolean;
  enableEditButton?: {
    visible: boolean;
    uiType?: "Intitle" | "default";
    onClick?: () => void;
  };
  versionNumber?: number;
}

function RAFDetailsPageHeading({
  objectRow,
  showRecordInfo = true,
  showRelatedFocusList = true,
  showSecondaryHeader = true,
  ...props
}: PropsWithChildren<IProps>) {
  let navigate = useNavigate();

  const onBackArrowClicked = () => {
    if (props.onBackArrowClicked) {
      props.onBackArrowClicked();
    } else {
      navigate(-1);
    }
  };

  const onToggleMenu = () => {
    // if (BrowserIsDevice) {
    //   navigate('/menu');
    // }
    // else {
    triggerRAFEvent(RAFEventName.ToggleSideMenu, null);
    // }
  };

  return (
    <div className="page-heading" id="summary">
      <div className="w-100">
        <div className="row gx-2">
          <div
            className={
              isNotNullAndUndefined(props.centerSection)
                ? "col overflow-auto details-left-section"
                : "col"
            }
          >
            <div className="row align-items-center g-2 flex-nowrap">
              {/* {((isNotNullAndUndefined(props.mode) && props.mode === "Dialog") || props.showToggleBtn === false) ? (
                <></>
              ) : (
                <div className="col-auto">
                  <div
                    className={`cursor backArrowIcon backArrowIcon-md sidemenu-toggle-btn`}
                    onClick={() => onToggleMenu()}
                    id="gridPageHeadingHamburgerMenuBtn"
                  >
                    <span className="fas fa-bars"></span>
                  </div>
                </div>
              )} */}
              <div className="col-auto">
                <div
                  className={
                    isNotNullAndUndefined(props.moduleavatar)
                      ? `backArrowIcon backArrowIcon-md`
                      : "backArrowIcon backArrowIcon-md"
                  }
                  // className="backArrowIcon"
                  onClick={onBackArrowClicked}
                >
                  <i className="fass fa-arrow-left-long"></i>
                </div>
              </div>
              {isNotNullAndUndefined(props.customHeaderAvatar) && (
                <div className="col-auto">{props.customHeaderAvatar}</div>
              )}

              <div className="col">
                {isNotNullAndUndefined(props.customHeader) ? (
                  props.customHeader
                ) : (
                  <div className="w-100 ms-1">
                    <div className="row">
                      <div className="col-12">
                        <div className="d-flex align-items-baseline flex-nowrap">
                          <div className="overflow-auto">
                            <span className="detailsHeader__headerText header_5 ecllipseFirstLine">
                              {props.headerValue}
                            </span>
                          </div>
                          {isNotNullAndUndefined(props.versionNumber) && (
                            <div className="ms-1">
                              <span className="raf_badge raf_badge_neutral">{`${props.versionNumber}`}</span>
                            </div>
                          )}
                          {isNotNullAndUndefined(props.enableEditButton) &&
                            props.enableEditButton.visible === true &&
                            props.enableEditButton.uiType === "Intitle" && (
                              <div className="ms-1">
                                <RAFButtonComponent
                                  action={RAFButtonConstant.Edit}
                                  iconBtn
                                  className="btn_brand_primary transparent e-custom-actionbutton"
                                  onClick={props.enableEditButton.onClick}
                                  enableToolTip={false}
                                ></RAFButtonComponent>
                              </div>
                            )}
                          {/* {showRelatedFocusList === true &&
                            isNotNullAndUndefined(props.moduleName) &&
                            isNotNullAndUndefined(props.headerValue) && (
                              <div>
                                <RAFRelatedFocusList
                                  moduleName={props.moduleName}
                                  headerValue={props.headerValue}
                                />
                              </div>
                            )} */}
                        </div>
                      </div>
                      {isNotNullAndUndefined(props.customSecondaryHeader)
                        ? props.customSecondaryHeader
                        : showSecondaryHeader && (
                            <div className="col-auto">
                              <div className="d-flex align-items-baseline flex-nowrap">
                                <div className="pe-2 overflow-auto">
                                  <span className="secondary-header-bold-text ecllipseFirstLine word-break-all">
                                    {isNotNullAndUndefined(
                                      props.secondaryHeaderValue
                                    )
                                      ? props.secondaryHeaderValue
                                      : getDisplayNameByModuleName(
                                          props.moduleName
                                        )}
                                  </span>
                                </div>
                                {/* {props.showRecordInfo && <>
                            <div className="separator-dot">
                              <i className="fas fa-circle me-2"></i>
                            </div>
                            <RAFRecordInfo
                              createdBy={createdBy}
                              createdDate={createdDate}
                              modifiedDate={modifiedDate}
                              modifiedBy={modifiedBy}
                              lastActivityDate={lastActivityDate}
                              dropdownBtnClassName="custom-button-sm p-0 remove-minWidth"
                              hideClockIcon
                              {...!BrowserIsDevice ?
                                { content: "Updated " }
                                :
                                {}}
                                            spanContentClassName="body_3_light medium"

                            ></RAFRecordInfo>
                          </>
                          } */}
                                {/* <RAFRelatedFocusList
                              moduleName={props.moduleName}
                              headerValue={props.headerValue}
                            /> */}
                              </div>
                            </div>
                          )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div
            className={
              isNotNullAndUndefined(props.centerSection)
                ? "col-auto col-lg d-flex justify-content-between"
                : "col-auto"
            }
          >
            {props.centerSection ? (
              <div className="details-center-section">
                {props.centerSection}
              </div>
            ) : null}
          </div>
          <div className="col-auto d-flex align-items-center">
            {isNotNullAndUndefined(props.detailsBtn) ? props.detailsBtn : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(RAFDetailsPageHeading);
