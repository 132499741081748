import {
  MenuEventArgs,
  NodeSelectEventArgs
} from "@syncfusion/ej2-navigations";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { TreeViewComponent } from "@syncfusion/ej2-react-navigations";
import {
  DialogComponent
} from "@syncfusion/ej2-react-popups";
import { DropDownButtonComponent } from "@syncfusion/ej2-react-splitbuttons";
import * as R from "ramda";
import React from "react";
import { IconList, IconPickerItem } from "react-fa-icon-picker";
import RAFIconImage from "../../../RAFComponents/Navigation/RAFIconImage";
import memobind from "../../../RAFComponents/components/Memobind";
import { getSessionStorage, hideProgress, removeSessionStorage, setSessionStorage, showProgress } from "../../../RAFComponents/helpers/AppHelper";
import { RAFButtonConstant, StorageKey } from "../../../constants/Common/Constants";
import { onToggleSideMenu } from "../../../RAFComponents/helpers/RAFMenuHelper";
import {
  IsNullOrWhiteSpace,
  deepEqual,
  isEmptyOrNull,
  isNotEmptyArray,
  isNotNullAndUndefined,
  isNullOrUndefined
} from "../../../RAFComponents/helpers/utils";
import { RAFTreeNodeModel } from "../../../RAFComponents/models/Common/RAFTreeNodeModel";
import { UserInfoRow } from "../../../RAFComponents/models/Common/UserInfoRow";
import {
  navigateToDashboard,
  toggleSideMenu
} from "../../../helpers/ACutils";
import Internal from "../../../assets/PortalType/Internal.jpg";
import PartnerConnect from "../../../assets/PortalType/Partner Connect.jpg";
import Project from "../../../assets/PortalType/Project.jpg";
import Public from "../../../assets/PortalType/Public.jpg";
import { NavigateParamsProps, withRouter } from "../../../router";
import {
  CurrentWorkspaceContext,
  WorkspaceListContext
} from "../Providers/WorkspaceContextProvider";
import ManageWorkspace from "./ManageWorkspace";
import { RAFPortalType, defaultPortalItems, systemPortalNames } from "./WorkspaceHelper";
import { WorkspaceRow } from "./WorkspaceRow";
import "./WorkspaceStyle.scss";

interface IProps {
  onClose?: () => void;
}

interface IState {
  selectedWorkspaceRow: WorkspaceRow;
  workspaceUID: string;
  selectedRAFTreeNodeUID: string;
  showManageWorkspaceContent: boolean;
}

class SwitchWorkspace extends React.Component<
  NavigateParamsProps & IProps,
  IState
> {
  _isMounted = false;

  portalTreeView: TreeViewComponent;
  constructor(props) {
    super(props);
    this.state = {
      selectedWorkspaceRow: null,
      workspaceUID: null,
      selectedRAFTreeNodeUID: null,
      showManageWorkspaceContent: false,
    };
    this.nodeSelected = this.nodeSelected.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  // componentDidUpdate(prevProps, prevState) {
  //     if (!deepEqual(prevProps.params.id, this.props.params.id)) {
  //         let id = this.props.params.id;
  //         if (isNotNullAndUndefined(id)) {
  //         }
  //     }
  // }

  private workspaceDialogClose(isNavigate?: boolean): void {
    if (this.props.onClose) {
      this.props.onClose();
    }
    if (isNavigate === true) {
      navigateToDashboard(this.props.navigate);
    }
  }

  redirectToWSList = () => {
    this.hideSideBar();
    if (this.props.navigate) {
      this.props.navigate('/Portal/List/');
    } else {
      setTimeout(() => {
        window.location.href = "/Portal/List/";
      });
    }
  };

  onToggleMenu = () => {
    //console.log("sidebarobj", sidebarobj);
    let windowWidth = window.innerWidth;
    if (windowWidth >= 767) {
      onToggleSideMenu();
    } else {
      toggleSideMenu();
    }
  };

  hideSideBar = () => {
    let workspaceDiv = document.getElementById("portalSidebar");
    if (isNotNullAndUndefined(workspaceDiv) && isNotNullAndUndefined(workspaceDiv.classList)
      && !workspaceDiv.classList.contains("hidden")
    ) {
      workspaceDiv.classList.add("hidden");
    }
    let listSidebarRight = document.getElementById("listSidebar_right");
    if (isNotNullAndUndefined(listSidebarRight)) {
      listSidebarRight.classList.add("hidden");
    }
  };

  onMoreMenuClicked(
    itemID,
    portalList,
    currentWorkspace,
    setCurrentWorkspace,
    args: MenuEventArgs
  ) {
    let workspaceRow = isNotEmptyArray(portalList) ? portalList.find(x => x.UID === itemID) : null;

    if (isNotNullAndUndefined(workspaceRow) && isNotNullAndUndefined(workspaceRow.UID)) {
      if (args.item.id === "Settings") {
        if (!systemPortalNames.includes(workspaceRow.PortalName)) {
          this.workspaceDialogClose();
          this.hideSideBar();
          this.props.navigate("/portal/manage/" + workspaceRow.UID);
          // this.onToggleMenu();
        }
      }
      if (args.item.id === "Edit") {
        if (!systemPortalNames.includes(workspaceRow.PortalName))
          this.openManageWorkspaceDialog(workspaceRow.UID);
      }
    }

    if (args.item.id === "Add") {
      if (isNotNullAndUndefined(workspaceRow)) {
        if (workspaceRow.PortalType === RAFPortalType.Internal || workspaceRow.PortalType === RAFPortalType.Tenant) {
          this.openManageWorkspaceDialog(null, workspaceRow);
        }
      } else {
        this.openManageWorkspaceDialog(null, null);
      }
    }

    if (args.item.id === "Switch") {
      //this.hideSideBar();
      if (isNotNullAndUndefined(workspaceRow) && !systemPortalNames.includes(workspaceRow.PortalName)) {
        if (!systemPortalNames.includes(workspaceRow.PortalName)) {
          this.onClickSwitchWorkspace(
            workspaceRow,
            portalList,
            currentWorkspace,
            setCurrentWorkspace
          );
        }
      } else {
        this.onClickSwitchToDefaultWorkspace(
          itemID,
          setCurrentWorkspace
        );
      }
    }

    if (args.item.id === "Manage") {
      if (!systemPortalNames.includes(workspaceRow.PortalName)) {
        this.hideSideBar();
        this.workspaceDialogClose();
        this.props.navigate("/portal/list/");
      }
    }
  }

  onClickSwitchToDefaultWorkspace = (itemID: string, setCurrentWorkspace: any) => {
    let progressDiv = showProgress('.body', true);
    let selectedPortal = defaultPortalItems.find(x => x.PortalName === itemID);

    let currentUser: UserInfoRow = JSON.parse(getSessionStorage(StorageKey.currentUser));
    if (isNotNullAndUndefined(currentUser) && isNotNullAndUndefined(currentUser.UserUID)) {
      currentUser.CurrentPortal = null;
      currentUser.CurrentPortalUID = null;
    }

    setSessionStorage(StorageKey.CurrentPortal, true, JSON.stringify(selectedPortal));
    setSessionStorage(StorageKey.currentUser, false, JSON.stringify(currentUser));
    setCurrentWorkspace(selectedPortal);
    hideProgress(progressDiv);
    navigateToDashboard(this.props.navigate);
  };

  //treeview_start
  private generatePortalTreeDataSource(
    portalList: WorkspaceRow[],
    currentWorkspaceUID?: string,
    selectedRAFTreeNodeUID?: string
  ) {
    let outVal: RAFTreeNodeModel[] = [];
    let inVal: WorkspaceRow[] = R.clone(portalList);
    // let selectedItemID = !IsNullOrWhiteSpace(currentWorkspaceUID) ? currentWorkspaceUID : defaultPortalItems[0].PortalName;
    // defaultPortalItems.forEach((x) => {
    //   outVal.push({
    //     id: x.PortalName,
    //     text: x.PortalName,
    //     iconCss: isNotNullAndUndefined(x.Icon) ? x.Icon : "FaAvianex",
    //   });
    // });

    inVal && inVal.forEach((x) => {
      outVal.push({
        id: x.UID,
        text: x.PortalName,
        parentID: isNotNullAndUndefined(x.ParentUID) ? x.ParentUID : null,// defaultPortalItems[0].PortalName,
        iconCss: isNotNullAndUndefined(x.Icon) ? x.Icon : "FaAvianex",
        expanded: true,
      });
    });
    //setting has children start
    outVal.forEach((x) => {
      //const tmpChildren = inVal.filter(y => y.ParentUID === x.id);
      const tmpChildren = outVal.filter((y) => y.parentID === x.id);
      if (isNotNullAndUndefined(tmpChildren) && tmpChildren.length > 0) {
        x.hasChildren = true;
        //x.expanded = true;
      }
    });
    //setting has children end
    //setting has selected start
    outVal.forEach((x) => {
      if (!IsNullOrWhiteSpace(currentWorkspaceUID) && x.id === currentWorkspaceUID) {
        x.selected = true;
        x.htmlAttributes = {
          class: "e-list-item e-level-1 user-selected-tree-item e-active e-node-focus",
        };
      } else {
        x.selected = false;
        x.htmlAttributes = {
          class: "e-list-item e-level-1",
        };
      }
    });
    //setting has selected end
    //setting expanded start
    if (isNotNullAndUndefined(selectedRAFTreeNodeUID)) {
      const objSelectedTreeNode = outVal.find(
        (x) => x.id === selectedRAFTreeNodeUID
      );
      if (isNotNullAndUndefined(objSelectedTreeNode)) {
        let objParentId = objSelectedTreeNode.parentID;
        while (!IsNullOrWhiteSpace(objParentId)) {
          const objParent = outVal.find((x) => x.id === objParentId);
          if (
            isNotNullAndUndefined(objParent) &&
            !IsNullOrWhiteSpace(objParent.id)
          ) {
            objParent.expanded = true;
            objParentId = objParent.parentID;
          } else {
            objParentId = null;
          }
        }
      }
    }
    //setting expanded end
    return outVal;
  }

  private portalTreeViewRef(t) {
    this.portalTreeView = t;
  }

  private nodeTemplate(
    portalList: WorkspaceRow[],
    currentWorkspace: WorkspaceRow,
    setCurrentWorkspace: any,
    data: any
  ): JSX.Element {
    const itemID = data["id"];
    let workspaceRow: WorkspaceRow = portalList.find((x) => x.UID === itemID);
    let hasChildren = data.hasChildren;
    let portalName: string = data["text"];
    let iconName: IconList = data.iconCss as IconList;
    // let isCurrentWorkspace =
    //   isNotNullAndUndefined(currentWorkspace) && isNotNullAndUndefined(workspaceRow) &&
    //     currentWorkspace.UID === workspaceRow.UID
    //     ? true
    //     : false;

    let currentWorkspaceUID = isNotNullAndUndefined(currentWorkspace) ?
      isNotNullAndUndefined(currentWorkspace.UID) ? currentWorkspace.UID : currentWorkspace.PortalName : null;
    //let portalType = isNotNullAndUndefined(workspaceRow) ? workspaceRow.PortalType : null;

    const allowAdd = isNotNullAndUndefined(workspaceRow) ? (workspaceRow.PortalType === RAFPortalType.Tenant || workspaceRow.PortalType === RAFPortalType.Internal) ? true : false : false;
    return (
      <div
        className={isNotNullAndUndefined(hasChildren) && hasChildren === true ? "treeview w-100 hasChildren" : "treeview w-100"}
      >
        <div className="w-100">
          <div className="row align-items-center justify-content-between g-3">
            <div className="col d-flex align-items-center overflow-auto">
              <div className="treeview-Icon treeview-Icon-md pe-1 pb-1">
                {isEmptyOrNull(iconName) ? (
                  <div>
                    <i className="fa fa-file-invoice text-white"></i>
                    {/* <RAFIconImage
                                              iconCssClass={"fa fa-file-invoice text-white"}
                                              moduleavatar="form-library-avatar transparent-avatar"
                                              iconSize="30"
                                              fontSize="20"
                                          ></RAFIconImage> */}
                  </div>
                ) : (
                  <div>
                    <span
                    // className="form-library-avatar avatar-text transparent-avatar"
                    // style={{
                    //   width: "30px",
                    //   height: "30px",
                    //   minWidth: "30px",
                    //   maxWidth: "30px",
                    //   fontSize: "20px",
                    // }}
                    >
                      <IconPickerItem icon={iconName} size={20} color="#fff" />
                    </span>
                  </div>
                )}
              </div>
              <div>
                <div className="ecllipseFirstLine portal-header-text">{portalName}</div>
                {currentWorkspaceUID === itemID ? "" :
                  <div className="portal-switchbtn-div">
                    <ButtonComponent type="button" className="link-button custom-button-sm d-flex align-items-center"
                      iconCss={`${RAFButtonConstant.Switch.IconCss} m-0 me-1`} id={`btn_${RAFButtonConstant.Switch.Id}_${workspaceRow.UID}`}>
                      <span className="ecllipseFirstLine">
                        {RAFButtonConstant.Switch.DisplayName}
                      </span>
                    </ButtonComponent>
                  </div>
                }
              </div>
            </div>
            <div className="col-auto" onClick={(e) => e.stopPropagation()}>
              <DropDownButtonComponent
                items={[
                  {
                    id: RAFButtonConstant.Switch.Id,
                    text: RAFButtonConstant.Switch.DisplayName,
                    iconCss: RAFButtonConstant.Switch.IconCss,
                    disabled:
                      currentWorkspaceUID === itemID ? true : false
                  },
                  {
                    id: "Add",
                    text: "Add",
                    iconCss: "fas fa-plus",
                    disabled: allowAdd === true ? false : true
                  },
                  {
                    id: "Settings",
                    text: "Settings",
                    iconCss: "fas fa-tools",
                    disabled:
                      (isNullOrUndefined(workspaceRow) || systemPortalNames.includes(portalName)) ? true : false
                  },
                  //{id: 'Edit', text: 'Edit', iconCss: 'fas fa-pencil', disabled: systemPortalNames.includes(portalName) ? true : false },
                  //{id: 'Manage', text: 'Manage', iconCss: 'fas fa-eye', },
                ]}
                cssClass="e-sidebar__right__treeview__actionBtn hover-show-child-div e-caret-hide"
                style={{
                  width: "26px",
                  height: "26px",
                  minWidth: "26px",
                  minHeight: "26px",
                }}
                select={this.onMoreMenuClicked.bind(
                  this,
                  itemID,
                  portalList,
                  currentWorkspace,
                  setCurrentWorkspace
                )}
                iconCss="fas fa-ellipsis-vertical"
              ></DropDownButtonComponent>
            </div>
          </div>
        </div>
      </div>
    );
  }

  onClickSwitchWorkspace = (
    selectedItem: WorkspaceRow,
    portalList: WorkspaceRow[],
    currentWorkspace: WorkspaceRow,
    setCurrentWorkspace: any
  ) => {
    let item = (isNotNullAndUndefined(selectedItem) && isNotNullAndUndefined(selectedItem.UID) &&
      isNotEmptyArray(portalList))
      ? portalList.find((X) => X.UID === selectedItem.UID)
      : null;
    if (isNotNullAndUndefined(item) && isNotNullAndUndefined(item.UID)) {
      if (
        !deepEqual(currentWorkspace, item)
      ) {
        let progressDiv = showProgress('.body', true);
        //let currentUserTenant = msalInstance.currentTenantId;
        // let logInAsRequest: LogInAsRequest = new LogInAsRequest();
        // logInAsRequest.UserUID = msalInstance.currentUserId;
        // logInAsRequest.TenantUID = currentUserTenant;
        // logInAsRequest.PortalUID = item.UID;
        removeSessionStorage(StorageKey.CurrentPortal, true);

        // postDataAndGetResponse(
        //   'User/LogInAs',
        //   logInAsRequest,
        //   null,
        //   ContentType.applicationJson,
        //   false
        // )
        //   .then(() => {
        hideProgress(progressDiv);
        let currentUser: UserInfoRow = JSON.parse(getSessionStorage(StorageKey.currentUser));
        if (isNotNullAndUndefined(currentUser) && isNotNullAndUndefined(currentUser.UserUID)) {
          currentUser.CurrentPortal = item.PortalName;
          currentUser.CurrentPortalUID = item.UID;
        }

        setSessionStorage(StorageKey.CurrentPortal, true, JSON.stringify(item));
        setSessionStorage(StorageKey.currentUser, false, JSON.stringify(currentUser));
        //removeSessionStorage(StorageKey.currentUser, false);
        setCurrentWorkspace(item);
        this.workspaceDialogClose(true);
        // })
        // .catch((error) => error);
      }
    }
  };

  setCurrentWorkspace = (
    selectedItem: WorkspaceRow,
    portalList: WorkspaceRow[],
    currentWorkspace: WorkspaceRow,
    setCurrentWorkspace: any
  ) => {
    let stepTemplateUID: string = selectedItem.UID;
    let item =
      isNotNullAndUndefined(portalList) && portalList.length > 0
        ? portalList.find((X) => X.UID === stepTemplateUID)
        : null;
    if (
      isNullOrUndefined(currentWorkspace) ||
      (isNotNullAndUndefined(currentWorkspace) &&
        isNotNullAndUndefined(currentWorkspace.UID) &&
        isNotNullAndUndefined(item) &&
        currentWorkspace.UID !== item.UID)
    ) {
      setCurrentWorkspace(item);
      this.workspaceDialogClose(true);
    }
  };

  onEditClick = (uid) => {
    this.workspaceDialogClose();
    this.props.navigate("/portal/manage/" + uid);
  };

  private nodeSelected(e: NodeSelectEventArgs) {
    if (e.isInteracted) {
      let stepTemplateUID: string = e.nodeData["id"].toString();
    }
  }

  //treeview_end

  //manage_portal_Start

  openManageWorkspaceDialog = (
    workspaceUID?: string,
    selectedWorkspaceRow?: WorkspaceRow
  ) => {
    this.setState(
      { showManageWorkspaceContent: true, workspaceUID, selectedWorkspaceRow },
      () => {
        document.body.classList.remove("overflow-hidden");
      }
    );
  };

  manageWorkspaceDialogClose = () => {
    this.setState(
      { showManageWorkspaceContent: false, selectedWorkspaceRow: null },
      () => {
        document.body.classList.remove("overflow-hidden");
      }
    );
  };

  manageWorkspaceContent = (updateWorkspaceList, workspaceList) => {
    if (
      isNotNullAndUndefined(this.state.showManageWorkspaceContent) &&
      this.state.showManageWorkspaceContent === true
    ) {
      let { selectedWorkspaceRow, workspaceUID } = this.state;
      return (
        <div>
          <div>
            <ManageWorkspace
              onSave={(entityId) =>
                this.refreshOnUpdateOrCreate(entityId, updateWorkspaceList)
              }
              onDelete={() =>
                this.refreshOnUpdateOrCreate(null, updateWorkspaceList)
              }
              onClose={() => this.manageWorkspaceDialogClose()}
              workspaceUID={workspaceUID}
              workspaceList={workspaceList}
              selectedWorkspaceRow={selectedWorkspaceRow}
              mode={
                isNullOrUndefined(workspaceUID) &&
                  isNullOrUndefined(selectedWorkspaceRow)
                  ? "create"
                  : isNotNullAndUndefined(workspaceUID)
                    ? "update"
                    : isNotNullAndUndefined(selectedWorkspaceRow) &&
                      isNotNullAndUndefined(selectedWorkspaceRow.UID)
                      ? "createChild"
                      : "create"
              }
              isActive
            />
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  refreshOnUpdateOrCreate = (entityId: string, updateWorkspaceList) => {
    this.setState(
      {
        showManageWorkspaceContent: false,
        selectedWorkspaceRow: null,
        selectedRAFTreeNodeUID: entityId,
      },
      () => {
        document.body.classList.remove("overflow-hidden");
        updateWorkspaceList();
      }
    );
  };
  //manage_portal_end

  getPortalCardView = (
    workspaceList: WorkspaceRow[],
    currentWorkspace: WorkspaceRow,
    currentWorkspaceUID: string,
    setCurrentWorkspace
  ) => {
    const allWorkspaceList: WorkspaceRow[] = isNotNullAndUndefined(
      workspaceList
    )
      ? workspaceList
      : [];

    return (
      <ul className="sidebar-content workspace-content row g-4 mb-3 d-none">
        {allWorkspaceList &&
          allWorkspaceList.map((item) => {
            // let initial = item && item.Title.charAt(0).toUpperCase();
            let WorkspaceType =
              isNotNullAndUndefined(item) &&
                isNotNullAndUndefined(item.PortalType)
                ? item.PortalType.toString()
                : "Default";
            //const workspaceImg = `${window.location.origin}/images/PortalType/${WorkspaceType}`;
            let workspaceImg;
            let img;
            switch (WorkspaceType) {
              case RAFPortalType.Internal:
                img = (
                  <img
                    src={Internal}
                    alt={RAFPortalType.Internal}
                    className="w-100 h-100"
                  />
                );
                break;
              case RAFPortalType.PartnerConnect:
                img = (
                  <img
                    src={PartnerConnect}
                    alt={RAFPortalType.PartnerConnect}
                    className="w-100 h-100"
                  />
                );
                break;
              case RAFPortalType.Project:
                img = (
                  <img
                    src={Project}
                    alt={RAFPortalType.Project}
                    className="w-100 h-100"
                  />
                );
                break;
              case RAFPortalType.Public:

                // img = <img src={workspaceImg} alt={WorkspaceType} className="w-100 h-100" />;
                img = (
                  <img
                    src={Public}
                    alt={RAFPortalType.Public}
                    className="w-100 h-100"
                  />
                );
                break;
              default:
                img = (
                  <img
                    src={Public}
                    alt={"Not set"}
                    className="w-100 h-100"
                  />
                );
                break;
            }
            // img = <img src={workspaceImg} alt={WorkspaceType} className="w-100 h-100" />;

            return (
              <li
                key={item.UID}
                className={
                  isNotNullAndUndefined(currentWorkspaceUID) &&
                    currentWorkspaceUID === item.UID
                    ? "menu-item menu mainMenu col-12 col-md-6 active"
                    : "menu-item menu mainMenu col-12 col-md-6"
                }
                id={`viewDropdown_item_${item.UID}`}
                // onClick={() => this.onSelectWorkspaceItem(item)}
                onClick={() => {
                  if (
                    isNullOrUndefined(currentWorkspace) ||
                    (isNotNullAndUndefined(currentWorkspaceUID) &&
                      currentWorkspaceUID !== item.UID)
                  ) {
                    setCurrentWorkspace(item);
                  }
                  this.workspaceDialogClose();
                }}
              >
                <div className="e-card menu-item-text p-0">
                  <div onClick={(e) => e.stopPropagation()}>
                    <DropDownButtonComponent
                      items={[
                        { id: "Manage", text: "Manage" },
                        { id: "Edit", text: "Edit" },
                      ]}
                      cssClass="e-caret-hide e-small e-round unset-custom-button-md border-0 workspace-btn"
                      //select={this.onMoreMenuClicked.bind(this, item.UID)}
                      select={memobind(
                        this,
                        "onMoreMenuClicked",
                        item.UID
                      )}
                      iconCss="fa fa-ellipsis-v"
                    ></DropDownButtonComponent>
                  </div>
                  <div className="workspace-img-div">{img}</div>
                  <div className="e-card-content w-100">
                    <div className="row g-2 flex-column">
                      <div className="col-auto">
                        <span className="header-text text-start ecllipseFirstLine">
                          {item.PortalName}
                        </span>
                      </div>
                      <div className="col-auto">
                        <span className="secondary-header-text text-start ecllipseSecondLine">
                          {isNotNullAndUndefined(item.PortalType)
                            ? item.PortalType
                            : "Portal Type not set"}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="p-3 py-2 w-100">
                    <RAFIconImage
                      iconCssClass={"fas fa-user"}
                      moduleavatar="transparent-avatar"
                      iconSize="30"
                      fontSize="16"
                      color="#8a9196"
                    ></RAFIconImage>
                  </div>
                  {/* <div className='w-100'>
                                              <div onClick={(e) => e.stopPropagation()}>
                                                  <DropDownButtonComponent
                                                      items={[
                                                          { id: 'Manage', text: 'Manage', },
                                                          { id: 'Edit', text: 'Edit', },
                                                      ]} cssClass="e-caret-hide e-small e-round unset-custom-button-md bg-transparent border-0 workspace-btn"
                                                      //select={this.onMoreMenuClicked.bind(this, data.UID)}
                                                      iconCss="fa fa-ellipsis-v"
                                                  >
                                                  </DropDownButtonComponent>
                                              </div>
                                              <div className='row g-2'>
                                                  <div className='col-12 d-flex justify-content-center align-items-center'>
                                                      <span className={"avatar-text-circle avatar-initial-material-" + initial}>
                                                          <IconPickerItem color={"inherit"} icon={isNotNullAndUndefined(item) && isNotNullAndUndefined(item.Icon) ? item.Icon as IconList : null} size={18} containerStyles={{ display: "flex" }} />
                                                      </span>
                                                  </div>
                                                  <div className='col-12'>
                                                      <span className="menu-item-text-content text-center ecllipseSecondLine" style={{ cursor: 'pointer' }}>{item.Title}</span>
                                                  </div>
                                                  {/* <div className='col-12'>
                                                      <ButtonComponent className='e-info e-outline' content='Manage' type='button'></ButtonComponent>
                                                  </div> */}
                  {/*</div>
                                          </div> */}
                </div>
              </li>
            );
          })}
      </ul>
    );
  };

  getPortalItemTreeView = (
    workspaceList: WorkspaceRow[],
    currentWorkspace: WorkspaceRow,
    currentWorkspaceUID: string,
    selectedRAFTreeNodeUID: string,
    setCurrentWorkspace
  ) => {
    return (
      <div className="h-100 overflow-auto customScrollBar">
        <TreeViewComponent
          // cssClass="raf-treeview hover-parent-div"
          cssClass="e-sidebar__right__treeview fixedTemplate"
          id="tree1"
          ref={memobind(this, "portalTreeViewRef")}
          allowDragAndDrop={false}
          nodeTemplate={memobind(
            this,
            "nodeTemplate",
            workspaceList,
            currentWorkspace,
            setCurrentWorkspace
          )}
          fullRowSelect
          allowMultiSelection={false}
          //nodeSelected={this.nodeSelected}
          fields={{
            dataSource: this.generatePortalTreeDataSource(
              workspaceList,
              currentWorkspaceUID,
              selectedRAFTreeNodeUID
            ) as any,
          }}
        />
      </div>
    );
  };

  render() {
    return (
      <WorkspaceListContext.Consumer>
        {({ workspaceList, updateWorkspaceList }) => {
          return (
            <CurrentWorkspaceContext.Consumer>
              {({ currentWorkspace, setCurrentWorkspace }) => {

                let currentWorkspaceUID = isNotNullAndUndefined(
                  currentWorkspace
                )
                  ? currentWorkspace.UID
                  : null;
                let selectedRAFTreeNodeUID = isNotNullAndUndefined(
                  this.state.selectedRAFTreeNodeUID
                )
                  ? this.state.selectedRAFTreeNodeUID
                  : currentWorkspaceUID;

                return (
                  <div className="e-sidebar__right__section pt-2 h-100 overflow-auto">
                    {this.getPortalItemTreeView(
                      workspaceList,
                      currentWorkspace,
                      currentWorkspaceUID,
                      selectedRAFTreeNodeUID,
                      setCurrentWorkspace
                    )}

                    {/* <div className="e-dlg-footerContent px-3">
                                          <ButtonComponent type="button" cssClass='e-flat e-success w-100'
                                              onClick={() => this.redirectToWSList()}
                                          >Manage</ButtonComponent>
                                      </div> */}
                    <div className="e-sidebar__right__btnOutter">
                      <ButtonComponent type="button"
                        id="btn_addDashboardView_sideNav"
                        className="e-sidebar__right__btn"
                        content="Manage"
                        //iconCss="fas fa-plus"
                        onClick={() => this.redirectToWSList()} />
                    </div>

                    {this.state.showManageWorkspaceContent && (
                      <DialogComponent
                        header={"Create Portal"}
                        showCloseIcon
                        visible={this.state.showManageWorkspaceContent}
                        cssClass="centerDialog-sm createEditForm form-center-dialog"
                        id="manageWorkSpaceDialog"
                        content={this.manageWorkspaceContent.bind(
                          this,
                          updateWorkspaceList,
                          workspaceList
                        )}
                        isModal
                        target="body"
                        closeOnEscape={false}
                        close={this.manageWorkspaceDialogClose.bind(this)}
                      ></DialogComponent>
                    )}
                  </div>
                );
              }}
            </CurrentWorkspaceContext.Consumer>
          );
        }}
      </WorkspaceListContext.Consumer>
    );
  }
}

export default withRouter(SwitchWorkspace);
