import { getSessionStorage, RetrieveRecord, setSessionStorage } from "../../../../RAFComponents/helpers/AppHelper";
import { ContentType,StorageKey } from "../../../../constants/Common/Constants";
import { getSaveRequest, isNotNullAndUndefined, IsNullOrWhiteSpace } from "../../../../RAFComponents/helpers/utils";
import { ListServiceRequest } from "../../../../RAFComponents/models/Common/ListRequest";
import * as repositoryActions from "../../../../RAFComponents/store/actions/repositoryActions";
import { WorkspaceRow } from "../WorkspaceRow";
import { RAFEntityName } from "../../../../constants/Common/EntityConstants";

export function getWorkspaceRetrieve(workspaceUID: string) {
    return new Promise<WorkspaceRow>((resolve) => {
        RetrieveRecord(workspaceUID, RAFEntityName.Portal).then((responseEntity) => {
            if (IsNullOrWhiteSpace(responseEntity)) {
                resolve(null);
            } else {
                resolve(responseEntity);
            }
        })
            .catch(error => error);
    });
}

export function getWorkspaceList(clearSessionStorage?: boolean) {
    return new Promise<WorkspaceRow[]>((resolve) => {
        const workspaceListStorage = getSessionStorage(StorageKey.PortalList, true);
        if (clearSessionStorage !== true && isNotNullAndUndefined(workspaceListStorage) && !IsNullOrWhiteSpace(workspaceListStorage)) {
            const allWorkspaceList: WorkspaceRow[] = JSON.parse(workspaceListStorage);
            resolve(allWorkspaceList);
        }
        else {
            let listServiceRequest = new ListServiceRequest();
            listServiceRequest.Skip = 0;
            listServiceRequest.Take = 0;
            //listServiceRequest.ViewName = 'all_workspace';

            return repositoryActions.postDataAndGetResponse(
                `Portal/List`,
                listServiceRequest,
                null,
                ContentType.applicationJson, false
            ).then((response) => {
                if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data) && isNotNullAndUndefined(response.data.Entities)) {
                    const allWorkspaceList: WorkspaceRow[] = response.data.Entities;
                    setSessionStorage(StorageKey.PortalList, true, JSON.stringify(allWorkspaceList));
                    resolve(allWorkspaceList);
                } else {
                    resolve(null);
                }
            })
                .catch((error) => error);
        }
    });
};

export function WorkspaceSave(workspaceRow: WorkspaceRow) {
    return new Promise<string>((resolve) => {
        repositoryActions.postDataAndGetResponse('Portal/Save', getSaveRequest(workspaceRow, workspaceRow.UID), null, ContentType.applicationJson)
            .then((response) => {
                if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data)) {
                    resolve(response.data.EntityId);
                } else {
                    resolve(null);
                }
            })
            .catch((error) => error);
    });
}