import React, { PropsWithChildren } from "react";
import {
  isNotNullAndUndefined,
  IsNullOrWhiteSpace,
} from "../../RAFComponents/helpers/utils";
import { RAFAttributesContext } from "../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import { ColumnField } from "../../RAFMaster/RMModules/RAFPage/PageLayoutJM";
import { RAFEntityContext } from "./Providers/RAFEntityProvider";
import { getFormElement, getFormElementByAttribute } from "./RAFFieldHelper";

interface IProps {
  data?: ColumnField;
  prefix?: string;
  validateFields?: boolean;
  mode?: "Edit" | "Details" | "Create";
}

export default function RAFField({
  children,
  ...props
}: PropsWithChildren<IProps>) {
  const { data, prefix, mode } = props;
  let validateFields = isNotNullAndUndefined(props.validateFields)
    ? props.validateFields
    : true;

  return (
    <div className="col-12">
      {data && isNotNullAndUndefined(data.AttributeUID) && (
        <RAFEntityContext.Consumer>
          {({ entity }) => {
            return (
              <RAFAttributesContext.Consumer>
                {({ queryAttributes }) => {
                  return (
                    <div>
                      {isNotNullAndUndefined(queryAttributes) ? (
                        <div>
                          {getFormElement(
                            isNotNullAndUndefined(entity)
                              ? entity.EntityName
                              : null,
                            queryAttributes,
                            data.AttributeUID,
                            data.IsHidden,
                            data.DefaultValue,
                            data.Placeholder,
                            IsNullOrWhiteSpace(prefix) ? null : prefix,
                            validateFields,
                            isNotNullAndUndefined(data.ShowLabel) &&
                              data.ShowLabel === false
                              ? data.ShowLabel
                              : true,
                            mode
                          )}
                        </div>
                      ) : (
                        isNotNullAndUndefined(data.FieldName) && (
                          <div>
                            {getFormElementByAttribute(
                              isNotNullAndUndefined(entity)
                                ? entity.EntityName
                                : null,
                              {
                                AttributeUID: data.AttributeUID,
                                DataType: data.DataType,
                                DisplayName: data.DisplayName,
                                FieldName: data.FieldName,
                                IsEditable: data.IsEditable,
                                Visible: !data.IsHidden,
                                DefaultValue: data.DefaultValue,
                                IsRequired: data.IsRequired,
                                UIType: data.UIType,
                                RelatedEntities: data.RelatedEntity,
                                ValueJson: data.ValueJson,
                                UISettings: data.UISettings,
                              },
                              data.AttributeUID,
                              data.IsHidden,
                              data.DefaultValue,
                              IsNullOrWhiteSpace(prefix) ? null : prefix,
                              data.Placeholder,
                              validateFields,
                              isNotNullAndUndefined(data.ShowLabel) &&
                                data.ShowLabel === false
                                ? data.ShowLabel
                                : true,
                              mode
                            )}
                          </div>
                        )
                      )}
                    </div>
                  );
                }}
              </RAFAttributesContext.Consumer>
            );
          }}
        </RAFEntityContext.Consumer>
      )}
    </div>
  );
}
