import { msalInstance } from "../../../../..";
import {
  RAFCustomFilter,
  RAFCustomOperator,
} from "../../../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import {
  showSuccessToast,
  showWarningToast,
} from "../../../../../RAFComponents/Utility/RAFToastComponent";
import {
  filterUserViewDropdownItem,
  getSessionStorage,
  hasDefaultModuleView,
  isCurrentTenantCareESIOPartner,
} from "../../../../../RAFComponents/helpers/AppHelper";
import { hasPermission } from "../../../../../RAFComponents/helpers/PermissionHelper";
import {
  getCurrentMenuCategory,
  getLeftMenuItems,
  getURLNameByModuleName,
} from "../../../../../RAFComponents/helpers/RAFMenuHelper";
import {
  IsNotNullOrWhiteSpace,
  IsNullOrWhiteSpace,
  getSaveRequest,
  isNotEmptyArray,
  isNotNullAndUndefined,
  isNullOrUndefined,
  propertyOf,
} from "../../../../../RAFComponents/helpers/utils";
import { ListServiceRequest } from "../../../../../RAFComponents/models/Common/ListRequest";
import { SaveRequest } from "../../../../../RAFComponents/models/Common/ListResponse";
import { MenuRow } from "../../../../../RAFComponents/models/Common/RAFMenuModel";
import { RAFViewRow } from "../../../../../RAFComponents/models/Common/RAFViewRow";
import * as repositoryActions from "../../../../../RAFComponents/store/actions/repositoryActions";
import { ChecklistItemTransPermissionConstants, InvoiceTransactionPermissionConstants, PayrollTransactionPermissionConstants, QualitySafetyPermissionConstants, ServiceTransactionPermissionConstants, ServiceTransactionSupportLogPermissionConstants, XeroSyncPermissionConstants } from "../../../../../constants/CareESIO/CareESIOPermissionConstant";
import { CareEsioEntity } from "../../../../../constants/CareESIO/CareEsioConstants";
import {
  ContentType,
  RAFReportType,
  RAFTenantName,
  StorageKey,
} from "../../../../../constants/Common/Constants";
import { RAFEntityName } from "../../../../../constants/Common/EntityConstants";
import { PermissionConstants } from "../../../../../constants/Common/PermissionConstants";
import { RAFWorkspaceRelatedtoType } from "../../../../../constants/Common/RMConstants";
import { MenuCategory } from "../../../../../constants/MenuConstant/RAFMenuCategory";
import { getModuleAvatarIcon } from "../../../../../helpers/Common/AvatarHelper";
import { getViewsReportsDashboards } from "../../../List/IndexHelper";
import { RoleRow } from "../../../Role/RoleRow";
import { ConvertWorkspaceItemRowToWorkspaceItemDto } from "../../WorkspaceItemMappingRegister";
import { WorkspaceRow } from "../../WorkspaceRow";
import { IWorkspaceItemDto, WorkspaceItemRow } from "../WorkspaceItemRow";
import { CareESIOMainMenuData } from "./CareESIOMainMenuData";
import { SkytrustMainMenuData } from "./SkytrustMainMenuData";

export class PortalTreeViewItemRow {
  UID: string;
  Name: string;
  Text: string;
  Icon: string;
  URL: string;
  Entity: string;
  ParentUID: string;
  ItemType?: string;
  HasChildren: boolean;
  IsExternalIcon: boolean;
  NodeChild: PortalTreeViewItemRow[];
  PermissionName?: string;
}

export function isWorkspaceItemExist(
  workspaceItemRow: WorkspaceItemRow,
  workspaceItemsList?: WorkspaceItemRow[]
) {
  return new Promise<boolean>((resolve) => {
    if (
      isNotNullAndUndefined(workspaceItemsList) &&
      workspaceItemsList.length! > 0
    ) {
      let isExist = checkIsItemExist(workspaceItemRow, workspaceItemsList);
      resolve(isExist);
    } else {
      getWorkspaceItemsByWorkspaceId(workspaceItemRow.PortalUID).then(
        (workspaceItemList) => {
          if (
            isNotNullAndUndefined(workspaceItemList) &&
            workspaceItemList.length > 0
          ) {
            let isExist = checkIsItemExist(workspaceItemRow, workspaceItemList);
            resolve(isExist);
          } else {
            resolve(false);
          }
        }
      );
    }
  });
}

function checkIsItemExist(
  workspaceItemRow: WorkspaceItemRow,
  workspaceItemList: WorkspaceItemRow[]
) {
  if (
    isNotNullAndUndefined(workspaceItemRow) &&
    isNotNullAndUndefined(workspaceItemList) &&
    workspaceItemList.length > 0
  ) {
    let items = workspaceItemList.filter(
      (x) =>
        (x.RelatedtoType === RAFWorkspaceRelatedtoType.View &&
          x.RelatedtoUID === workspaceItemRow.RelatedtoUID &&
          x.UID !== workspaceItemRow.UID) ||
        (x.Title === workspaceItemRow.Title && x.UID !== workspaceItemRow.UID)
    );
    if (isNotNullAndUndefined(items) && items.length > 0) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function checkUniqueAndSaveWorkspaceItem(
  workspaceItemRow: WorkspaceItemRow,
  workspaceItemsList?: WorkspaceItemRow[]
) {
  return new Promise<string>((resolve) => {
    isWorkspaceItemExist(workspaceItemRow, workspaceItemsList).then(
      (isExist) => {
        if (!isExist) {
          saveWorkspaceItem(workspaceItemRow).then((entityId) => {
            if (isNotNullAndUndefined(entityId)) {
              if (isNotNullAndUndefined(workspaceItemRow.UID)) {
                showSuccessToast("Portal Item updated successfully");
              } else {
                showSuccessToast("Portal Item added successfully");
              }
              resolve(entityId);
            } else {
              showWarningToast("Sorry something went wrong. Please try again!");
              resolve(null);
            }
          });
        } else {
          showWarningToast(
            "Workspace item with the name you specified already exists. Specify a different Workspace item name."
          );
          resolve(null);
        }
      }
    );
  });
}

export function saveWorkspaceItem(workspaceItemRow) {
  return new Promise<string>((resolve) => {
    let workspaceItemDTO: IWorkspaceItemDto =
      ConvertWorkspaceItemRowToWorkspaceItemDto(workspaceItemRow);
    return repositoryActions
      .postDataAndGetResponse(
        "PortalItem/Save",
        getSaveRequest(workspaceItemDTO, workspaceItemRow.UID),
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data)
        ) {
          resolve(response.data.EntityId);
        } else {
          resolve(null);
        }
      });
  });
}

export function getWorkspaceItemsByWorkspaceId(workspaceUID: string) {
  return new Promise<WorkspaceItemRow[]>((resolve) => {
    if (isNotNullAndUndefined(workspaceUID)) {
      let relatedFilter: RAFCustomFilter = {};
      relatedFilter.Condition = "and";
      relatedFilter.Rules = [];
      let filter1: RAFCustomFilter = {};
      let filterVal1: string[] = [];

      filterVal1.push(workspaceUID);
      filter1.Operator = RAFCustomOperator.Equal;
      filter1.Value = filterVal1;
      filter1.Field = propertyOf<WorkspaceItemRow>("PortalUID");
      relatedFilter.Rules.push(filter1);

      let listServiceRequest = new ListServiceRequest();
      //listServiceRequest.CustomFilter = relatedFilter;
      listServiceRequest.Skip = 0;
      listServiceRequest.Take = 0;

      listServiceRequest["RelatedField"] =
        propertyOf<WorkspaceItemRow>("PortalUID");
      listServiceRequest["RelatedFieldValue"] = workspaceUID;

      //listServiceRequest.ViewName = 'related_workspace_item';

      // /let sortByTitle = { field: propertyOf<WorkspaceItemRow>("Title"), order: 'ascending' };
      let sortByDate = {
        field: propertyOf<WorkspaceItemRow>("DisplayOrder"),
        order: "ascending",
      };
      let sortQuery: string[] = [];

      if (
        isNotNullAndUndefined(sortByDate) &&
        isNotNullAndUndefined(sortByDate.field)
      ) {
        sortQuery.push(
          sortByDate.order === "descending"
            ? `${sortByDate.field} desc`
            : sortByDate.field
        );
      }
      // if (isNotNullAndUndefined(sortByTitle) && isNotNullAndUndefined(sortByTitle.field)) {
      //     sortQuery.push(sortByTitle.order === 'descending' ? `${sortByTitle.field} desc` : sortByTitle.field);
      // }

      listServiceRequest.Sort = sortQuery;

      return repositoryActions
        .postDataAndGetResponse(
          `PortalItem/RelatedList`,
          listServiceRequest,
          null,
          ContentType.applicationJson,
          false
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotNullAndUndefined(response.data.Entities)
          ) {
            resolve(response.data.Entities);
          } else {
            resolve(null);
          }
        });
    } else {
      resolve(null);
    }
  });
}

export function saveWorkspaceItemList(
  saveRequest: SaveRequest<WorkspaceItemRow>[]
) {
  return new Promise<boolean>((resolve) => {
    repositoryActions
      .postDataAndGetResponse(
        "PortalItem/SaveList",
        saveRequest,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data)
        ) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
  });
}

export const getLeftNavigationMenuItems = (
  currentWorkspace: WorkspaceRow,
  workspaceItems: WorkspaceItemRow[],
  permissionValue: RoleRow
) => {
  const allPortalTreeViewItems = getWorkSpaceLeftMenuItems(currentWorkspace, workspaceItems, permissionValue);

  const portalTreeViewItems = filterItemByModuleReadPermission(allPortalTreeViewItems, permissionValue);
  return portalTreeViewItems;
};

const filterItemByModuleReadPermission = (portalTreeViewItems: PortalTreeViewItemRow[], permissionValue: RoleRow) => {
  if (isNotEmptyArray(portalTreeViewItems) && isNotNullAndUndefined(permissionValue)) {
    const newPortalTreeViewItems = portalTreeViewItems.filter(x => {
      if (IsNotNullOrWhiteSpace(x.PermissionName)) {
        const permission = hasPermission(permissionValue, x.PermissionName);
        if (permission) {
          if (isNotEmptyArray(x.NodeChild)) {
            x.NodeChild = filterItemByModuleReadPermission(x.NodeChild, permissionValue);
          }
          return x;
        }
      } else if (isNotNullAndUndefined(x.URL) && x.URL.toLowerCase().includes('/roster')) {
        const permission = hasPermission(permissionValue, ServiceTransactionPermissionConstants.ServiceTransactionRead);
        if (permission) {
          return x;
        }
      } else if (isNotNullAndUndefined(x.URL) && x.URL.toLowerCase().includes('/bookings')) {
        const permission = hasPermission(permissionValue, ServiceTransactionPermissionConstants.ServiceTransactionMyShifts);
        if (permission) {
          return x;
        }
      } else if (isNotNullAndUndefined(x.URL) && x.URL.toLowerCase().includes('/shiftoffers')) {
        const permission = hasPermission(permissionValue, ServiceTransactionPermissionConstants.ServiceTransactionViewShiftOffer);
        if (permission) {
          return x;
        }
      } else if (isNotNullAndUndefined(x.URL) && x.URL.toLowerCase().includes('/billing')) {
        const permission = hasPermission(permissionValue, InvoiceTransactionPermissionConstants.InvoiceTransactionViewBilling);
        if (permission) {
          return x;
        }
      } else if (isNotNullAndUndefined(x.URL) && x.URL.toLowerCase().includes('/payroll')) {
        const permission = hasPermission(permissionValue, PayrollTransactionPermissionConstants.PayrollTransactionViewTimeSheet);
        if (permission) {
          return x;
        }
      } else if (isNotNullAndUndefined(x.URL) && x.URL.toLowerCase().includes('/supportlog')) {
        const permission = hasPermission(permissionValue, ServiceTransactionSupportLogPermissionConstants.ServiceTransactionSupportLogViewSupportLog);
        if (permission) {
          return x;
        }
      } else if (isNotNullAndUndefined(x.URL) && x.URL.toLowerCase().includes('/xerosync')) {
        const permission = hasPermission(permissionValue, XeroSyncPermissionConstants.XeroSyncViewTimeSheet);
        if (permission) {
          return x;
        }
      } else if (isNotNullAndUndefined(x.URL) && x.URL.toLowerCase().includes('/quality/list')) {
        const permission = hasPermission(permissionValue, QualitySafetyPermissionConstants.QualitySafetyView);
        if (permission) {
          return x;
        }
      } else if (isNotNullAndUndefined(x.URL) && x.URL.toLowerCase().includes('/report/list')) {
        const permission = hasPermission(permissionValue, PermissionConstants.AnalyticsRead);
        if (permission) {
          return x;
        }
      } else if (x.Entity === CareEsioEntity.ChecklistItemTrans.EntityName) {
        const permission = hasPermission(permissionValue, ChecklistItemTransPermissionConstants.ChecklistItemTransAdminComplianceDocuments);
        if (permission) {
          return x;
        }
      } else if (x.Entity === RAFEntityName.Report) {
        const permission = hasPermission(permissionValue, PermissionConstants.AnalyticsRead);
        if (permission) {
          return x;
        }
      } else if (isNotNullAndUndefined(x.Entity)) {
        const permission = hasPermission(permissionValue, `${x.Entity}::read`);
        if (permission) {
          return x;
        }
      } else {
        return x;
      }
    });
    return newPortalTreeViewItems;
  } else {
    return portalTreeViewItems;
  }
};

const getWorkSpaceLeftMenuItems = (
  currentWorkspace: WorkspaceRow,
  workspaceItems: WorkspaceItemRow[],
  permissionValue: RoleRow
) => {
  const currentMenuCategory = getCurrentMenuCategory();
  const isCareESIOTenant = (msalInstance.currentTenantName === RAFTenantName.CareESIO || isCurrentTenantCareESIOPartner() === true) ? true : false;
  if ((isNullOrUndefined(currentMenuCategory) ||
    currentMenuCategory === MenuCategory.Default ||
    currentMenuCategory === MenuCategory.ContentManagement)) {
    if (isCareESIOTenant) {
      return CareESIOMainMenuData;
    } else {
      return SkytrustMainMenuData;
    }
  } else {
    const leftDefaultMenuItems: MenuRow[] = getLeftMenuItems(
      currentWorkspace,
      workspaceItems
    );
    const portalTreeViewItems =
      getPortalLeftMenuTreeViewItems(leftDefaultMenuItems, permissionValue);
    return portalTreeViewItems;
  }
};

export const getPortalLeftMenuTreeViewItems = (
  menuItems: MenuRow[],
  permissionValue: RoleRow
) => {
  //conver to tree view and get child items of module
  const portalTreeViewItems: PortalTreeViewItemRow[] = [];
  if (isNotEmptyArray(menuItems)) {
    menuItems.forEach((item) => {
      if (isNotNullAndUndefined(item.ParentUID)) {
        //isParentExist condition needed
      } else {
        const childItems = isNotNullAndUndefined(item.UID)
          ? menuItems.filter((x) => x.ParentUID === item.UID)
          : [];
        const treeViewChildItems: PortalTreeViewItemRow[] = [];
        if (isNotEmptyArray(childItems)) {
          childItems.forEach((child) => {
            const treeViewChildItem = convertMenuRowToWorkspaceViewItem(
              child,
              []
            );
            if (isNotNullAndUndefined(treeViewChildItem)) {
              treeViewChildItems.push(treeViewChildItem);
            }
          });
        } else if (
          item.itemType === RAFWorkspaceRelatedtoType.Module &&
          item.HasChildren === true
        ) {
          const childViewItems =
            getViewItemsForCurrentMenuFromSessionStorage(item, permissionValue);
          if (isNotEmptyArray(childViewItems)) {  //check view permissions here 
            childViewItems.forEach((child) => {
              if (isNotNullAndUndefined(child)) {
                treeViewChildItems.push(child);
              }
            });
          }
        }
        const treeViewItem = convertMenuRowToWorkspaceViewItem(
          item,
          treeViewChildItems
        );
        if (isNotNullAndUndefined(treeViewItem)) {
          portalTreeViewItems.push(treeViewItem);
        }
      }
    });
  }
  return portalTreeViewItems;
};

export const convertMenuRowToWorkspaceViewItem = (
  menuItem: MenuRow,
  treeViewChildItems: PortalTreeViewItemRow[]
) => {
  //convert menurow to PortalTreeViewItemRow
  let treeViewItem: PortalTreeViewItemRow = null;
  if (isNotNullAndUndefined(menuItem)) {
    treeViewItem = {
      UID: menuItem.UID ?? menuItem.Name,
      Name: menuItem.Name,
      Text: menuItem.Text,
      Icon: menuItem.Icon,
      URL: menuItem.Url,
      Entity: menuItem.Entity,
      ParentUID: menuItem.ParentUID,
      IsExternalIcon: menuItem.isExternalIcon ?? false,
      ItemType: menuItem.itemType,
      NodeChild: isNotEmptyArray(treeViewChildItems) ? treeViewChildItems : [],
      HasChildren: isNotNullAndUndefined(menuItem.HasChildren)
        ? menuItem.HasChildren
        : isNotEmptyArray(treeViewChildItems) ||
          menuItem.itemType === RAFWorkspaceRelatedtoType.Module
          ? true
          : false,
    };
  }
  return treeViewItem;
};

export const getViewItemsForCurrentMenuFromSessionStorage = (
  menuItem: MenuRow,
  permissionValue: RoleRow
) => {
  // get view from SessionStorage to bind data in initial load
  let portalTreeViewItems: PortalTreeViewItemRow[] = [];
  if (isNotNullAndUndefined(menuItem)) {
    const viewsStorage = getSessionStorage(
      StorageKey.allViews_modulename + menuItem.Entity,
      true
    );
    const allViews: RAFViewRow[] = !IsNullOrWhiteSpace(viewsStorage)
      ? JSON.parse(viewsStorage)
      : null;

    const addDefaultView = hasDefaultModuleView(menuItem.Entity)
      ? menuItem.Entity === RAFEntityName.StandardizedLibrary ||
        menuItem.Entity === CareEsioEntity.CareShiftLog.EntityName
        ? false
        : true
      : false;

    const userViewDropdownItem = filterUserViewDropdownItem(
      menuItem.Entity,
      addDefaultView ? true : false,
      addDefaultView
        ? []
        : menuItem.Entity === RAFEntityName.StandardizedLibrary ||
          menuItem.Entity === CareEsioEntity.CareShiftLog.EntityName
          ? []
          : allViews,
      true
    );
    const defaultView = isNotEmptyArray(userViewDropdownItem)
      ? userViewDropdownItem.filter(
        (x) =>
          x.ViewType === RAFReportType.Default ||
          x.ViewType === RAFReportType.Table
      )
      : [];
    portalTreeViewItems = convertViewRowToWorkspaceViewItem(
      defaultView,
      menuItem.Entity,
      menuItem.UID ?? menuItem.Name,
      menuItem.Url,
      permissionValue
    );
  }
  return portalTreeViewItems;
};

export const convertViewRowToWorkspaceViewItem = (
  viewItems: RAFViewRow[],
  entity: string,
  parentUID: string,
  defaultURL: string,
  permissionValue: RoleRow
) => {
  //convert view row to PortalTreeViewItemRow
  let portalTreeViewItem: PortalTreeViewItemRow[] = [];
  if (isNotEmptyArray(viewItems)) {
    viewItems.forEach((item) => {
      const permissionName = item.PermissionName ?? `${item.Entity}::read`;
      const hasPermissionToView = isNotNullAndUndefined(permissionValue) ? hasPermission(permissionValue, permissionName) : true;
      if (
        hasPermissionToView === true &&
        (item.ViewType === RAFReportType.Default ||
          item.ViewType === RAFReportType.Table)
      ) {
        const entityName = getURLNameByModuleName(entity, false);
        let url;
        if (isNotNullAndUndefined(item.UID)) {
          if (isNotNullAndUndefined(defaultURL)) {
            const lastLetter = defaultURL.charAt(defaultURL.length - 1);
            if (lastLetter === "/") {
              url = `${defaultURL}${item.UID}`;
            } else {
              url = `${defaultURL}/${item.UID}`;
            }
          } else {
            if (
              isNotNullAndUndefined(entityName) &&
              msalInstance.currentTenantName === RAFTenantName.skytrust
            ) {
              url = `/${entityName.toLowerCase()}/list/${item.UID}`;
            } else {
              url = `/raf/list/${entity}/${item.UID}`;
            }
          }
        } else {
          if (
            isNotNullAndUndefined(entityName) &&
            msalInstance.currentTenantName === RAFTenantName.skytrust
          ) {
            url = `/${entityName.toLowerCase()}/list`;
          } else {
            url = `/raf/list/${entity}`;
          }
        }

        portalTreeViewItem.push({
          UID: item.UID ?? `${entity}_${item.ViewName}`,
          Name: item.ViewName,
          Text: item.DisplayName,
          Icon: getModuleAvatarIcon(entity),
          //URL: url,
          URL: defaultURL,
          Entity: entity,
          ParentUID: parentUID,
          IsExternalIcon: false,
          ItemType: RAFWorkspaceRelatedtoType.View,
          NodeChild: [],
          HasChildren: false,
          PermissionName: item.PermissionName ?? `${entity}::read`,
        });
      }
    });
  }
  return portalTreeViewItem;
};

export const getWorkspaceViewItems = async (
  workspaceItems: WorkspaceItemRow[]
) => {
  const promises = workspaceItems.map((x) => getWorkspaceViewItem(x));
  const results = await Promise.all(promises);
  return results;
};

const getWorkspaceViewItem = (workspaceItem: WorkspaceItemRow) => {
  return new Promise<PortalTreeViewItemRow>(async (resolve) => {
    const viewItems = await getViewsReportsDashboards(
      workspaceItem.Entity,
      true,
      false
    );
    const tableViews = isNotEmptyArray(viewItems)
      ? viewItems.filter((x) => x.Category === RAFReportType.Table)
      : [];
    //ParentId: workspaceItem.Entity,
    const ParentId = workspaceItem.Entity;
    const objectArrayWithParentId = isNotEmptyArray(tableViews)
      ? tableViews.map((obj) => ({
        ...obj, // Copy the existing object properties
        ParentId, // Add the new variable
      }))
      : [];
    let portalTreeViewItemRow: PortalTreeViewItemRow =
      new PortalTreeViewItemRow();
    //  {
    //     Name: workspaceItem.Entity,
    //     Text: workspaceItem.Title,
    //     Icon: workspaceItem.Icon,
    //     ParentUID: null,
    //     IsExternalIcon: isNotNullAndUndefined(workspaceItem.Icon) ? true : false,
    //     url: workspaceItem.Relatedto,
    //     nodeChild: objectArrayWithParentId
    // };
    resolve(portalTreeViewItemRow);
  });
};
