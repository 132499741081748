import React, { PropsWithChildren, Reducer, useEffect, useReducer } from 'react';
import { LoginUserContext } from '../../../RAFAuthentication/RAFLoginUserContextProvider';
import { isNotNullAndUndefined, IsNullOrWhiteSpace, wrapChildrenWith } from '../../../RAFComponents/helpers/utils';
import { UserInfoRow } from '../../../RAFComponents/models/Common/UserInfoRow';
import { UserGroupRow } from '../../ActiveContacts/UserGroup/UserGroupRow';
import { getMyTeams } from '../../ActiveContacts/UserGroup/UserGroupServiceHelper';

const WithLoginUserContext = (Component) => {
    return (props) => (
        <LoginUserContext.Consumer>
            {({ userInfo }) => <Component {...props} userInfo={userInfo} />}
        </LoginUserContext.Consumer>
    );
};

interface IState {
    teams?: UserGroupRow[],
}

interface IProps {//extends RouteComponentProps {
    userInfo?: UserInfoRow,
}

export const MyTeamsContext = React.createContext({ teams: [] as UserGroupRow[], updateMyTeamList: null });

function MyTeamsContextProvider({ ...props }: PropsWithChildren<IProps>) {

    const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
        (state, newState) => ({ ...state, ...newState }),
        {
            teams: []
        }
    );

    useEffect(() => {
        initTeams();
    }, [props.userInfo]);

    const initTeams = () => {
        if (isNotNullAndUndefined(props.userInfo) && !IsNullOrWhiteSpace(props.userInfo.UserUID)) {
            getMyTeams().then(response => {
                if (isNotNullAndUndefined)
                    setState({ teams: response });
                else
                    setState({ teams: [] });
            });
        }
    };

    return (
        <MyTeamsContext.Provider
            value={{
                teams: state.teams,
                updateMyTeamList: initTeams
            }}
        >
            {wrapChildrenWith(props.children, {})}
        </MyTeamsContext.Provider>
    );
}

export default WithLoginUserContext(MyTeamsContextProvider);
