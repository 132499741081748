import React, { PropsWithChildren, Reducer, useEffect, useReducer } from "react";

import { msalInstance } from "../../..";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFPermissionRender from "../../../RAFComponents/helpers/PermissionHelper";
import { isNotEmptyArray, isNotNullAndUndefined } from "../../../RAFComponents/helpers/utils";
import { FollowRequest } from "../../../RAFComponents/models/Common/ListRequest";
import * as repositoryActions from "../../../RAFComponents/store/actions/repositoryActions";
import { ContentType } from "../../../constants/Common/Constants";


const GetFollowStatus = (objectId: string, moduleName: string) => {
    return new Promise<boolean>((resolve) => {
        if (isNotNullAndUndefined(objectId) && isNotNullAndUndefined(moduleName)) {
            const followRequest = new FollowRequest();
            followRequest.EntityName = moduleName;
            followRequest.EntityId = objectId;
            repositoryActions
                .postDataAndGetResponse(
                    "DataList/Followers",
                    followRequest,
                    null,
                    ContentType.applicationJson
                )
                .then((response) => {
                    if (
                        isNotNullAndUndefined(response) &&
                        isNotEmptyArray(response.data)
                    ) {
                        if (response.data.includes(msalInstance.currentUserId)) {
                            resolve(true);
                        } else {
                            resolve(false);
                        }
                    } else {
                        resolve(false);
                    }
                })
                .catch((error) => resolve(false));
        } else {
            resolve(false);
        }
    });
};

const submitFollowAndUnFollowApi = (objectId: string, moduleName: string, isFollowing: boolean) => {
    return new Promise<boolean>((resolve) => {
        if (isNotNullAndUndefined(objectId) && isNotNullAndUndefined(moduleName)) {
            const followRequest = new FollowRequest();
            followRequest.EntityName = moduleName;
            followRequest.EntityId = objectId;
            followRequest.Followers = [msalInstance.currentUserId];
            const url = isFollowing ? "DataList/UnFollow" : "DataList/Follow";
            repositoryActions
                .postDataAndGetResponse(
                    url,
                    followRequest,
                    null,
                    ContentType.applicationJson
                )
                .then((response) => {
                    if (
                        isNotNullAndUndefined(response) &&
                        isNotNullAndUndefined(response.data) &&
                        isNotNullAndUndefined(response.data.EntityId)
                    ) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                })
                .catch((error) => resolve(false));
        } else {
            resolve(false);
        }
    });
};

interface IProps {
    objectUID: string;
    entityName: string;
    className?: string;
}

interface IState {
    isFollowing: boolean;
    isLoading: boolean;
}

function RAFFollowButton({ objectUID, entityName, ...props }: PropsWithChildren<IProps>) {

    const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
        (state, newState) => ({ ...state, ...newState }),
        {
            isFollowing: false,
            isLoading: false,
        }
    );

    useEffect(() => {
        refresh();
    }, [objectUID, entityName]);

    const refresh = async () => {
        const isFollowing = await GetFollowStatus(objectUID, entityName);
        setState({ isFollowing, isLoading: false });
    };

    const onClickFollowButton = async () => {
        setState({ isLoading: true });
        const onSubmit = await submitFollowAndUnFollowApi(objectUID, entityName, state.isFollowing);
        refresh();
    };

    return (
        <RAFPermissionRender permissionName={`${entityName}::Update`}>
            <div className="col-auto  ">
                <RAFButtonComponent
                    className={`${props.className ? `${props.className} ` : ''}custom-button-md`}
                    isPrimary
                    btnContent={state.isFollowing ? "Following" : "Follow"}
                    iconCss={state.isFollowing ? "fad fa-circle-check fa-smd" : "fa fa-plus"}
                    onClick={() => {
                        if (state.isLoading === false) {
                            onClickFollowButton();
                        }
                    }}
                />
            </div>
        </RAFPermissionRender>
    );
}

export default React.memo(RAFFollowButton);