import React, { Fragment, useContext, useEffect, useState } from 'react';
import Intercom from '@intercom/messenger-js-sdk';
import { update } from "@intercom/messenger-js-sdk";
import { LoginUserContext } from '../../../RAFAuthentication/RAFLoginUserContextProvider';
import { UserInfoRow } from '../../../RAFComponents/models/Common/UserInfoRow';
import { isNotNullAndUndefined } from '../../../RAFComponents/helpers/utils';
import { getUserTenantSettings } from '../TenantSettings/TenantSettingsHelper';
import { useLocation } from 'react-router-dom';

export default function RAFMessengerComponent() {
    const loginUserContext = useContext(LoginUserContext);
    const userInfoRow: UserInfoRow = isNotNullAndUndefined(loginUserContext) ? loginUserContext.userInfo : null;

    const [tenantSettings, setTenantSettings] = useState(null);

    const location = useLocation();

    useEffect(() => {
        const fetchTenantSettings = async () => {
            const settings = await getUserTenantSettings();
            setTenantSettings(settings);
        };

        fetchTenantSettings();


    }, []);

    useEffect(() => {
        if (tenantSettings && isNotNullAndUndefined(tenantSettings.EnableCustomerSupportChat) && tenantSettings.EnableCustomerSupportChat === "Yes") {
            Intercom({
                app_id: 'vw59f8ip',
                user_id: userInfoRow.UserUID, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
                name: userInfoRow.DisplayName, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
                email: userInfoRow.Email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
                //created_at: user.createdAt, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
                created_at: 1704067200,
                hide_default_launcher: (location.pathname !== '/'),
            });
        }
    }, [tenantSettings, location]);


    return <Fragment></Fragment>;
}