import React, { PropsWithChildren, Reducer, useReducer } from 'react';
import { LoginUserContext } from '../../../RAFAuthentication/RAFLoginUserContextProvider';
import { wrapChildrenWith } from '../../../RAFComponents/helpers/utils';
import { MenuRow } from '../../../RAFComponents/models/Common/RAFMenuModel';
import { UserInfoRow } from '../../../RAFComponents/models/Common/UserInfoRow';

const WithLoginUserContext = (Component) => {
    return (props) => (
        <LoginUserContext.Consumer>
            {({ userInfo }) => <Component {...props} userInfo={userInfo} />}
        </LoginUserContext.Consumer>
    );
};

interface IState {
    selectedMenuItem: MenuRow;
}

interface IProps {//extends RouteComponentProps {
    userInfo?: UserInfoRow,
}

export const RAFNavigationContext = React.createContext({ selectedMenuItem: null as MenuRow, updateSelectedMenuItem: null });

function RAFNavigationContextProvider({ ...props }: PropsWithChildren<IProps>) {

    const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
        (state, newState) => ({ ...state, ...newState }),
        {
            selectedMenuItem: null
        }
    );

    const updateSelectedMenuItem = (selectedMenuItem: MenuRow) => {
        setState({ selectedMenuItem });
    };

    return (
        <RAFNavigationContext.Provider
            value={{
                selectedMenuItem: state.selectedMenuItem,
                updateSelectedMenuItem: updateSelectedMenuItem,
            }}
        >
            {wrapChildrenWith(props.children, {})}
        </RAFNavigationContext.Provider>
    );
}

export default WithLoginUserContext(RAFNavigationContextProvider);
