import React, { PropsWithChildren } from "react";
import { isNotNullAndUndefined } from "../../../RAFComponents/helpers/utils";
import RAFAttributeRelatedListProvider, {
    RAFAttributesContext
} from "../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import {
    RAFEntityType,
    RAFPageType
} from "../../../constants/Common/RMConstants";
import { RAFEntityContext } from "../Providers/RAFEntityProvider";
import RAFPageLayoutProvider from "../Providers/RAFPageLayoutProvider";
import RAFPageLayout from "../RAFPageLayout";

interface IProps {
  moduleName?: string;
  progressDivId?: string;
  isPreviewMode?: boolean;
}

export default function CreateContent({
  moduleName,
  isPreviewMode,
  progressDivId,
}: PropsWithChildren<IProps>) {
  return (
    <RAFAttributeRelatedListProvider moduleName={moduleName} progressDivId={progressDivId}>
      <RAFAttributesContext.Consumer>
        {({ attributeRelatedList }) => {
          return (
            <RAFEntityContext.Consumer>
              {({ entity }) => {
                if (entity.EntityType === RAFEntityType.UserForm) {
                  return (
                    <div className="h-100">
                      {/* <RAFUserFormRender
                            moduleName={entity.EntityName}
                            attributeRelatedList={attributeRelatedList}
                            rafPageType={RAFPageType.Create}
                            entityUID={entity.UID}
                            isPropertyName
                          /> */}
                      <RAFPageLayoutProvider
                        rafPageType={RAFPageType.Create}
                        mode={RAFPageType.Create}
                        progressDivId={isNotNullAndUndefined(progressDivId) ? progressDivId : "#dlg_create_" + entity.EntityName}
                      >
                        <RAFPageLayout isPreviewMode={isPreviewMode} />
                      </RAFPageLayoutProvider>
                    </div>
                  );
                } else if (
                  isNotNullAndUndefined(attributeRelatedList) &&
                  isNotNullAndUndefined(attributeRelatedList.Attributes)
                ) {
                  return (
                    <RAFPageLayoutProvider rafPageType={RAFPageType.Create} mode={RAFPageType.Create}
                      progressDivId={isNotNullAndUndefined(progressDivId) ? progressDivId : "#dlg_create_" + entity.EntityName}
                    >
                      <RAFPageLayout isPreviewMode={isPreviewMode} />
                    </RAFPageLayoutProvider>
                  );
                }
              }}
            </RAFEntityContext.Consumer>
          );
        }}
      </RAFAttributesContext.Consumer>
    </RAFAttributeRelatedListProvider>
  );
}
