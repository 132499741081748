import { RetrieveRecord, getSessionStorage, setSessionStorage } from "../../../RAFComponents/helpers/AppHelper";
import { IsNotNullOrWhiteSpace, isNotEmptyArray, isNotNullAndUndefined } from "../../../RAFComponents/helpers/utils";
import { ListServiceRequest } from "../../../RAFComponents/models/Common/ListRequest";
import * as repositoryActions from "../../../RAFComponents/store/actions/repositoryActions";
import { ContentType, StorageKey } from "../../../constants/Common/Constants";
import { RAFEntityName } from "../../../constants/Common/EntityConstants";
import { TenantSettingsRow } from "./TenantSettingsRow";

export const getUserTenantSettings = () => {
    return new Promise<TenantSettingsRow>((resolve) => {
        const userTenantSettingsStorage = getSessionStorage(StorageKey.UserTenantSettings, true);
        if (IsNotNullOrWhiteSpace(userTenantSettingsStorage)) {
            resolve(JSON.parse(userTenantSettingsStorage));
        } else {
            let listServiceRequest = new ListServiceRequest();
            listServiceRequest.Skip = 0;
            listServiceRequest.Take = 1;
            listServiceRequest.EntityName = RAFEntityName.TenantSettings;
            repositoryActions
                .postDataAndGetResponse(
                    "DataList/List",
                    listServiceRequest,
                    null,
                    ContentType.applicationJson
                )
                .then(async (response) => {
                    if (isNotNullAndUndefined(response)) {
                        let responseDate = null;
                        if (response.status === 204) {
                            responseDate = null;
                        } else if (isNotNullAndUndefined(response.data)) {
                            if (isNotNullAndUndefined(response.data.Entity)) {
                                responseDate = response.data.Entity;
                            } else if (isNotEmptyArray(response.data.ResultTable)) {
                                responseDate = response.data.ResultTable;
                            } else if (isNotNullAndUndefined(response.data.Entities)) {
                                responseDate = response.data.Entities;
                            } else {
                                responseDate = null;
                            }
                        }
                        if (isNotEmptyArray(responseDate)) {
                            const objectUID = responseDate[0].UID;
                            const tenantSettingsRow = await RetrieveRecord(objectUID, RAFEntityName.TenantSettings);
                            if (isNotNullAndUndefined(tenantSettingsRow)) {
                                setSessionStorage(
                                    StorageKey.UserTenantSettings,
                                    true,
                                    JSON.stringify(tenantSettingsRow)
                                );
                            }
                            resolve(tenantSettingsRow);
                        } else {
                            resolve(null);
                        }
                    } else {
                        resolve(null);
                    }
                })
                .catch((error) => error);
        }
    });
};