import { propertyOf } from "../../../../RAFComponents/helpers/utils";
import { RAFEntityBase } from "../../../../RAFComponents/models/Common/RAFEntityBase";
import { ILookupRow } from "../../../../RAFComponents/models/CompositeTypes/ILookupRow";

export class ModuleItemRow {
    isChecked?: boolean;
    RelatedtoUID?: string;
    Relatedto?: string;
    Title?: string;
    CollectionName?: string;
    Entity?: string;
}

export class IWorkspaceItemRow {
    UID?: string;
    Title?: string;
    RelatedtoUID?: string;
    RelatedtoType?: string;
    Relatedto?: string;
    DisplayOrder?: number;
    IsSystem?: boolean;
    Icon?: string;
    PortalUID?: string;
    Portal?: string;
    Entity?: string;
    Parent?: string;
    ParentUID?: string;
    CreatedDate?: Date;
    CreatedByUID?: string;
    CreatedBy?: string;
    ModifiedDate?: Date;
    ModifiedByUID?: string;
    ModifiedBy?: string;
    RecordOwnerUID?: string;
    RecordOwner?: string;
    LastActivityDate?: Date;
}

export class IWorkspaceItemDto {
    UID?: string;
    Title?: string;
    Relatedto?: string;
    RelatedtoUID?: string;
    RelatedtoType?: string;
    DisplayOrder?: number;
    IsSystem?: boolean; // false
    Icon?: string;
    PortalUID?: string;
    Portal?: string;
    Entity?: string;
    Parent?: string;
    ParentUID?: string;
    CreatedDate?: Date;
    CreatedByUID?: string;
    CreatedBy?: string;
    ModifiedDate?: Date;
    ModifiedByUID?: string;
    ModifiedBy?: string;
    RecordOwnerUID?: string;
    RecordOwner?: string;
    LastActivityDate?: Date;
}

export class WorkspaceItemRow extends IWorkspaceItemRow implements RAFEntityBase, ILookupRow {

    ModuleItems?: ModuleItemRow[];

    getClassName?() { //use entityname
        return 'portal_item';
    }
    getIdField?(): string {
        return propertyOf<WorkspaceItemRow>("UID");
    }
    getListUrl?(): string { //use route name(entity displayname)
        return "PortalItem/List";
    }
    getSaveUrl?(): string { //use route name(entity displayname)
        return "PortalItem/Save";
    }
    getGroupByUrl?(): string {
        return "PortalItem/GroupBy";
    }
    getLookupUrl(): string { //use route name(entity displayname)
        return "PortalItem/Lookup";
    }
    isOptionCreatable?(): boolean {
        return false;
    }
}
