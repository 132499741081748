import { MapperConfiguration, MappingPair } from '@dynamic-mapper/mapper';
import { IWorkspaceItemDto, WorkspaceItemRow } from './WorkspaceItem/WorkspaceItemRow';

export const ConvertWorkspaceItemRowToWorkspaceItemDto = (WorkspaceItemRow: WorkspaceItemRow): IWorkspaceItemDto => {

    const WorkspaceItemRowToIWorkspaceItemDto = new MappingPair<WorkspaceItemRow, IWorkspaceItemDto>();
    const WorkspaceItemRowToWorkspaceItemDtoConfiguration = new MapperConfiguration(cfg => {
        cfg.createAutoMap(WorkspaceItemRowToIWorkspaceItemDto, {})
            //.forSourceMember("SelectRelatedto", opt => opt.ignore())
            .forSourceMember("ModuleItems", opt => opt.ignore())
            ;
    });

    const mapper = WorkspaceItemRowToWorkspaceItemDtoConfiguration.createMapper();
    return mapper.map(WorkspaceItemRowToIWorkspaceItemDto, WorkspaceItemRow);
};

export const ConvertWorkspaceItemDtoToWorkspaceItemRow = (WorkspaceItemtDto: IWorkspaceItemDto): WorkspaceItemRow => {
    const IWorkspaceItemDtoToWorkspaceItemRow = new MappingPair<IWorkspaceItemDto, WorkspaceItemRow>();
    const IWorkspaceItemDtoToWorkspaceItemRowConfiguration = new MapperConfiguration(cfg => {
        cfg.createAutoMap(IWorkspaceItemDtoToWorkspaceItemRow, {
            ModuleItems: opt => opt.mapFrom(() => []),

            //SelectRelatedto: opt => opt.mapFrom(src => (isNotNullAndUndefined(src) && isNotNullAndUndefined(src.RelatedtoType) && src.RelatedtoType === "View" ? src.Relatedto : null)),
        });
    });
    const mapper = IWorkspaceItemDtoToWorkspaceItemRowConfiguration.createMapper();
    return mapper.map(IWorkspaceItemDtoToWorkspaceItemRow, WorkspaceItemtDto);
};

