import React, {
  Fragment,
  PropsWithChildren,
  ReactNode,
  Reducer,
  useReducer,
} from "react";
import { useNavigate } from "react-router-dom";
import { getURLNameByModuleName } from "../../../RAFComponents/helpers/RAFMenuHelper";
import {
  hideProgress,
  showProgress,
} from "../../../RAFComponents/helpers/AppHelper";
import {
  Guid,
  IsNotNullOrWhiteSpace,
  IsNullOrWhiteSpace,
  isNotEmptyArray,
  isNotNullAndUndefined,
  isNullOrUndefined,
} from "../../../RAFComponents/helpers/utils";
import { RAFAttributesContext } from "../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import { RAFPageType } from "../../../constants/Common/RMConstants";
import { EntitySimpleJM } from "../../../RAFMaster/RMModules/Entity/EntityRow";
import { getPageLayoutByPageTypeAndEntity } from "../../../RAFMaster/RMModules/RAFPage/RAFPageHelper";
import { NavigateParamsProps } from "../../../router";
import { RAFEntityContext } from "../Providers/RAFEntityProvider";
import { RAFObjectContext } from "../Providers/RAFObjectContextProvider";
import { getDetailsCustomElement, getDetailsElement } from "../RAFFieldHelper";
import { RAFDataModelRow } from "../../../RAFMaster/RMModules/RAFPage/PageLayoutJM";
import { RAFDataType } from "../../../RAFComponents/models/Common/RAFDataType";
import {
  BrowserIsDevice,
  RAFAttributeName,
} from "../../../constants/Common/Constants";
import RAFCollapseWithPlusIcon from "../../../RAFComponents/Navigation/RAFCollapseWithPlusIcon";
import CustomCardWidget from "../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import { FormLibraryRow } from "../../../RAFComponents/models/Common/FormLibraryRow";
import { flattenElements } from "../../../RAFMaster/helpers/RMutils";
interface IProps {
  contextModuleName?: string;
  contextObjectUID?: string;
  defaultComponent?: React.ReactFragment | ReactNode;
  tagComponent?: React.ReactFragment | ReactNode;
  isDynamic?: boolean;
  progressDivId?: string;
  uiStyle?: "Tab";
}

interface IState {
  selectedTabItem: string;
}

export default function DetailsSectionFieldsCustom({
  contextModuleName,
  contextObjectUID,
  defaultComponent,
  tagComponent,
  isDynamic,
  progressDivId,
  children,
  ...props
}: PropsWithChildren<IProps & NavigateParamsProps>) {
  const [detailsPageLayout, setDetailPageLayout] = React.useState(null);
  //const [defaultPageLayout, setDefaultPageLayout] = React.useState(null);

  const entityContext = React.useContext(RAFEntityContext);
  const entityRow: EntitySimpleJM = entityContext.entity;
  const formLibrary: FormLibraryRow = entityContext.formLibrary;

  const attributeContext = React.useContext(RAFAttributesContext);
  const attributeRelatedList = attributeContext.attributeRelatedList;
  const queryAttributes = attributeContext.queryAttributes;

  const navigate = useNavigate();

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      selectedTabItem: null,
    }
  );

  const pages =
    isNotNullAndUndefined(formLibrary) &&
      isNotNullAndUndefined(formLibrary.FormStructure)
      ? formLibrary.FormStructure["pages"]
      : null;

  async function checkScrollable() {
    const div = await document.querySelector("#raf_details_page_tab_header");
    const leftButton = await document.getElementById(
      "btn_raf_details_page_tab_header_left_scroll_iconBtn"
    );
    const rightButton = await document.getElementById(
      "btn_raf_details_page_tab_header_right_scroll_iconBtn"
    );

    if (div && leftButton && rightButton) {
      // Check if the div is scrollable
      if (div.scrollWidth > div.clientWidth) {
        // The div is scrollable, show the arrow buttons
        leftButton.style.display = "block";
        rightButton.style.display = "block";

        // Add click event listeners to the arrow buttons
        leftButton.addEventListener("click", () => {
          // Scroll the div to the left
          div.scrollTo({
            left: div.scrollLeft - (div.clientWidth - 100),
            behavior: "smooth",
          });
        });

        rightButton.addEventListener("click", () => {
          // Scroll the div to the right
          div.scrollTo({
            left: div.scrollLeft + (div.clientWidth - 100),
            behavior: "smooth",
          });
        });
      } else {
        // The div is not scrollable, hide the arrow buttons
        leftButton.style.display = "none";
        rightButton.style.display = "none";
      }

      // Stop observing the DOM changes
      observer.disconnect();
    }
  }

  // Create a MutationObserver to watch for changes in the DOM
  const observer = new MutationObserver(checkScrollable);

  // Start observing the document with the configured parameters
  observer.observe(document, { childList: true, subtree: true });

  React.useEffect(() => {
    if (isNullOrUndefined(entityRow)) {
      return;
    }
    let progressDiv = isNotNullAndUndefined(progressDivId)
      ? showProgress(progressDivId)
      : showProgress("body", true);
    getPageLayoutByPageTypeAndEntity(entityRow.UID, RAFPageType.Details).then(
      (detailsPageLayout) => {
        // getPageLayoutByPageTypeAndEntity(
        //   entityRow.UID,
        //   RAFPageType.Default
        // ).then((defaultPageLayout) => {
        hideProgress(progressDiv);
        if (
          isNotNullAndUndefined(detailsPageLayout) &&
          isNotNullAndUndefined(detailsPageLayout.UID)
        ) {
          setDetailPageLayout(detailsPageLayout);
        }
        // else {
        //   if (
        //     isNotNullAndUndefined(defaultPageLayout) &&
        //     isNotNullAndUndefined(defaultPageLayout.UID)
        //   ) {
        //     setDefaultPageLayout(defaultPageLayout);
        //   }
        // }
        //});
      }
    );
  }, [entityRow, contextModuleName, contextObjectUID]);

  let fieldsToShow: boolean;
  let detailsFormComponents: RAFDataModelRow[] = [];
  // let defalultFormComponents: RAFDataModelRow[] = [];

  if (isNotEmptyArray(pages)) {
    fieldsToShow = true;
  } else if (
    isNotNullAndUndefined(detailsPageLayout) &&
    isNotNullAndUndefined(detailsPageLayout.Components) &&
    isNotNullAndUndefined(detailsPageLayout.Components.Components)
  ) {
    detailsFormComponents = detailsPageLayout.Components.Components;

    if (
      isNotNullAndUndefined(detailsFormComponents) &&
      detailsFormComponents.length > 0
    ) {
      detailsFormComponents.forEach((component) => {
        let columns = component.Components;
        if (isNotNullAndUndefined(columns) && columns.length > 0) {
          columns.forEach((column) => {
            if (
              isNotNullAndUndefined(column) &&
              isNotNullAndUndefined(column.Components) &&
              column.Components.length > 0
            ) {
              fieldsToShow = true;
            }
          });
        }
      });
    } else {
      fieldsToShow = false;
    }
  } else {
    fieldsToShow = false;
  }

  function onNavigationClicked(relatedEntity: string, relatedUID: string) {
    if (
      isNotNullAndUndefined(relatedEntity) &&
      isNotNullAndUndefined(relatedUID)
    ) {
      let url =
        "/" + getURLNameByModuleName(relatedEntity) + "/view/" + relatedUID;
      if (isNotNullAndUndefined(isDynamic) && isDynamic === true) {
        url = "/RAF/View/" + relatedEntity + "/" + relatedUID;
      }
      navigate(url);
    }
  }

  let defaultFormComponent: RAFDataModelRow = new RAFDataModelRow();
  defaultFormComponent.UID = Guid.newGuid();
  defaultFormComponent.DisplayName = "Panel";
  defaultFormComponent.Name = "panel_" + defaultFormComponent.UID;
  defaultFormComponent.Visible = true;
  defaultFormComponent.DataType = RAFDataType.Panel;
  defaultFormComponent.IsCollapsed = false;
  defaultFormComponent.ColumnCount = 2;
  defaultFormComponent.ShowHeader = true;
  defaultFormComponent.Components = [
    {
      UID: Guid.newGuid(),
      DisplayName: "Column1",
      Name: "column_" + Guid.newGuid(),
      Visible: true,
      DataType: RAFDataType.Column,
      FieldToSave: false,
      Components: [],
    },
    {
      UID: Guid.newGuid(),
      DisplayName: "Column2",
      Name: "column_" + Guid.newGuid(),
      Visible: true,
      DataType: RAFDataType.Column,
      FieldToSave: false,
      Components: [],
    },
  ];

  let formFields = isNotNullAndUndefined(queryAttributes)
    ? queryAttributes.filter(
      (x) =>
        x.AttributeName !== RAFAttributeName.RecordInfo &&
        x.AttributeName !== RAFAttributeName.UID &&
        x.AttributeName !== RAFAttributeName.Entity &&
        x.AttributeName !== RAFAttributeName.FormLibrary &&
        x.AttributeName !== RAFAttributeName.TagsListJson &&
        x.AttributeName !== RAFAttributeName.TagsListJsonText &&
        x.AttributeName !== RAFAttributeName.Portal &&
        x.AttributeName !== RAFAttributeName.BusinessProcess &&
        x.AttributeName !== RAFAttributeName.ProcessStatus &&
        x.DataType !== RAFDataType.Formula &&
        x.DataType !== RAFDataType.AutoNumber &&
        x.ShowInSelect === true &&
        x.ShowInWhere === true
    )
    : null;

  if (isNotNullAndUndefined(formFields) && formFields.length > 0) {
    formFields.forEach((item, index) => {
      let formComponent: RAFDataModelRow = new RAFDataModelRow();
      formComponent.UID = item.AttributeUID;
      formComponent.Name = item.AttributeName;
      formComponent.DataType = item.DataType;
      formComponent.DisplayName = item.DisplayName;
      formComponent.DisplayOrder = item.DisplayOrder;
      formComponent.AttributeSettings = {
        EnableMassUpdate: item.EnableMassUpdate,
        DefaultValue: item.DefaultValue,
        IsRequired: item.IsRequired,
        UIType: item.UIType,
      };
      formComponent.ShowInList = item.ShowInSelect;
      formComponent.ShowInFilter = item.ShowInWhere;
      formComponent.Visible = item.Visible;
      formComponent.IsDefault = item.IsDefault;
      formComponent.IsMasterDetail = item.IsMasterDetail;
      formComponent.ValueJson = item.ValueJson;
      formComponent.EntityUID = item.EntityUID;
      if (index % 2 === 0) {
        defaultFormComponent.Components[0].Components.push({
          ...formComponent,
        });
      } else {
        defaultFormComponent.Components[1].Components.push({
          ...formComponent,
        });
      }
    });
  }
  const onTabSelected = (selectedTabItem) => {
    setState({ selectedTabItem });
  };

  const renderPageTabDetails = () => {
    let selectedPage = pages.find((x) => x["name"] === state.selectedTabItem);
    if (isNullOrUndefined(selectedPage)) {
      selectedPage = pages[0];
    }
    let selectedFieldModel = flattenElements(selectedPage["elements"]);
    let selectedFieldPanels = selectedPage["elements"];

    selectedFieldPanels = selectedFieldPanels.filter(
      (x) => x["visible"] !== false
    );

    selectedFieldModel = selectedFieldModel.filter(
      (x) => x["visible"] !== false
    );
    if (isNotEmptyArray(selectedFieldPanels)) {
      return (
        <div className="mt-3">
          <div
            className={`row align-items-baseline summary-section-outer customSummary label-left-align${props.uiStyle ? " g-2" : " gx-2"
              }`}
          >
            {selectedFieldPanels.map((panel, index) => {
              let panelFields = flattenElements(panel["elements"]);
              panelFields = panelFields.filter((x) => x["visible"] !== false);
              selectedFieldModel = selectedFieldModel.concat(panelFields);
              return (
                <div key={index}>
                  <CustomCardWidget cardContentClassName="py-0 px-0">
                    <RAFCollapseWithPlusIcon
                      toggleArrowIcon="Arrow"
                      title={panel["title"]}
                      allowFullRowClick
                      isCollapsed={false}
                      showSeparator={false}
                      showHeader={
                        isNotNullAndUndefined(panel["title"]) ? true : false
                      }
                      collapsePanelHeaderClass="collapsePanel__header__fixedHeight__lg"
                      collapsePanelContentClassname="relatedListOuterDiv p-2 p-md-3"
                    >
                      {isNotEmptyArray(panelFields) && (
                        <div>
                          <div className="">
                            <div
                              className={`row align-items-baseline summary-section-outer customSummary label-left-align${props.uiStyle ? " g-2" : " gx-2"
                                }`}
                            >
                              {panelFields.map((field, index) => {
                                return (
                                  <Fragment key={field["name"] + "_" + index}>
                                    <RAFAttributesContext.Consumer>
                                      {({ queryAttributes }) => {
                                        let selectedField =
                                          queryAttributes.find(
                                            (x) =>
                                              x.AttributeName === field["name"]
                                          );
                                        if (
                                          isNotNullAndUndefined(selectedField)
                                        ) {
                                          let hasCustomComponents = false;
                                          if (
                                            field["type"] === "address" ||
                                            field["type"] === "email" ||
                                            field["type"] === "phone"
                                          ) {
                                            hasCustomComponents = true;
                                          }
                                          return (
                                            <>
                                              {queryAttributes && (
                                                <>
                                                  <RAFObjectContext.Consumer>
                                                    {({
                                                      rafObject /*saveObject*/,
                                                    }) => {
                                                      let detailsValueDiv;
                                                      if (
                                                        field["name"] ===
                                                        "address"
                                                      ) {
                                                        let address =
                                                          rafObject["address"];
                                                        if (
                                                          isNullOrUndefined(
                                                            address
                                                          )
                                                        ) {
                                                          address = {
                                                            line1: null,
                                                            city: null,
                                                            state: null,
                                                            country: null,
                                                            postal_code: null,
                                                          };
                                                        }
                                                        detailsValueDiv = (
                                                          <CustomCardWidget cardContentClassName="py-0 px-2-5">
                                                            <div className="summarySection p-0">
                                                              <div className="summaryText">
                                                                <span
                                                                  className={
                                                                    "key_header"
                                                                  }
                                                                >
                                                                  {
                                                                    field[
                                                                    "title"
                                                                    ]
                                                                  }
                                                                </span>
                                                                <div className="col-12 mt-2">
                                                                  <div className="d-flex align-self-center flex-column">
                                                                    <div className="m-0 details-value">
                                                                      {address[
                                                                        "line1"
                                                                      ] ??
                                                                        "Not set"}
                                                                    </div>
                                                                    <div className="d-flex">
                                                                      {IsNotNullOrWhiteSpace(
                                                                        address[
                                                                        "city"
                                                                        ]
                                                                      ) ? (
                                                                        <div className="m-0 details-value">
                                                                          {
                                                                            address[
                                                                            "city"
                                                                            ]
                                                                          }{" "}
                                                                        </div>
                                                                      ) : null}
                                                                      {IsNotNullOrWhiteSpace(
                                                                        address[
                                                                        "state"
                                                                        ]
                                                                      ) ? (
                                                                        <div className="m-0 details-value">
                                                                          {" "}
                                                                          ,{" "}
                                                                          {
                                                                            address[
                                                                            "state"
                                                                            ]
                                                                          }
                                                                        </div>
                                                                      ) : null}
                                                                    </div>
                                                                    <div className="d-flex">
                                                                      {IsNotNullOrWhiteSpace(
                                                                        address[
                                                                        "country"
                                                                        ]
                                                                      ) ? (
                                                                        <div className="m-0 details-value">
                                                                          {
                                                                            address[
                                                                            "country"
                                                                            ]
                                                                          }
                                                                        </div>
                                                                      ) : null}
                                                                      {IsNotNullOrWhiteSpace(
                                                                        address[
                                                                        "postal_code"
                                                                        ]
                                                                      ) ? (
                                                                        <div className="m-0 details-value">
                                                                          -{" "}
                                                                          {
                                                                            address[
                                                                            "postal_code"
                                                                            ]
                                                                          }
                                                                        </div>
                                                                      ) : null}
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </CustomCardWidget>
                                                        );
                                                      } else if (
                                                        field["type"] ===
                                                        "email"
                                                      ) {
                                                        let email =
                                                          rafObject["email"];

                                                        if (
                                                          isNullOrUndefined(
                                                            email
                                                          )
                                                        ) {
                                                          email = {
                                                            personal: null,
                                                            work: null,
                                                          };
                                                        }
                                                        detailsValueDiv = (
                                                          <>
                                                            <CustomCardWidget cardContentClassName="py-0 px-2-5">
                                                              <div className="summarySection p-0">
                                                                <div className="summaryText">
                                                                  <span
                                                                    className={
                                                                      "key_header"
                                                                    }
                                                                  >
                                                                    Personal
                                                                    Email
                                                                  </span>
                                                                  <div className="col-12 mt-2">
                                                                    <div className="d-flex align-self-center flex-column">
                                                                      <div className="m-0 details-value">
                                                                        {IsNotNullOrWhiteSpace(
                                                                          email[
                                                                          "personal"
                                                                          ]
                                                                        )
                                                                          ? email[
                                                                          "personal"
                                                                          ]
                                                                          : "Not set"}
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </CustomCardWidget>
                                                            <CustomCardWidget
                                                              cardClassName="mt-2"
                                                              cardContentClassName="py-0 px-2-5"
                                                            >
                                                              <div className="summarySection p-0">
                                                                <div className="summaryText">
                                                                  <span
                                                                    className={
                                                                      "key_header"
                                                                    }
                                                                  >
                                                                    Work Email
                                                                  </span>
                                                                  <div className="col-12 mt-2">
                                                                    <div className="d-flex align-self-center flex-column">
                                                                      <div className="m-0 details-value">
                                                                        {IsNotNullOrWhiteSpace(
                                                                          email[
                                                                          "work"
                                                                          ]
                                                                        )
                                                                          ? email[
                                                                          "work"
                                                                          ]
                                                                          : "Not set"}
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </CustomCardWidget>
                                                          </>
                                                        );
                                                      } else if (
                                                        field["type"] ===
                                                        "phone"
                                                      ) {
                                                        let phone =
                                                          rafObject["phone"];

                                                        if (
                                                          isNullOrUndefined(
                                                            phone
                                                          )
                                                        ) {
                                                          phone = {
                                                            personal: null,
                                                            work: null,
                                                          };
                                                        }
                                                        detailsValueDiv = (
                                                          <>
                                                            <CustomCardWidget cardContentClassName="py-0 px-2-5">
                                                              <div className="summarySection p-0">
                                                                <div className="summaryText">
                                                                  <span
                                                                    className={
                                                                      "key_header"
                                                                    }
                                                                  >
                                                                    Personal
                                                                    Phone
                                                                  </span>
                                                                  <div className="col-12 mt-2">
                                                                    <div className="d-flex align-self-center flex-column">
                                                                      <div className="m-0 details-value">
                                                                        {IsNotNullOrWhiteSpace(
                                                                          phone[
                                                                          "personal"
                                                                          ]
                                                                        )
                                                                          ? phone[
                                                                          "personal"
                                                                          ]
                                                                          : "Not set"}
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </CustomCardWidget>
                                                            <CustomCardWidget
                                                              cardClassName="mt-2"
                                                              cardContentClassName="py-0 px-2-5"
                                                            >
                                                              <div className="summarySection p-0">
                                                                <div className="summaryText">
                                                                  <span
                                                                    className={
                                                                      "key_header"
                                                                    }
                                                                  >
                                                                    Work Phone
                                                                  </span>
                                                                  <div className="col-12 mt-2">
                                                                    <div className="d-flex align-self-center flex-column">
                                                                      <div className="m-0 details-value">
                                                                        {IsNotNullOrWhiteSpace(
                                                                          phone[
                                                                          "work"
                                                                          ]
                                                                        )
                                                                          ? phone[
                                                                          "work"
                                                                          ]
                                                                          : "Not set"}
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </CustomCardWidget>
                                                          </>
                                                        );
                                                      } else {
                                                        detailsValueDiv = (
                                                          <div>
                                                            {getDetailsCustomElement(
                                                              rafObject,
                                                              queryAttributes,
                                                              field["name"],
                                                              isNotNullAndUndefined(
                                                                field["visible"]
                                                              )
                                                                ? field[
                                                                "visible"
                                                                ]
                                                                : true,
                                                              true,
                                                              false, //field.NavigationEnabled
                                                              (
                                                                relatedEntities,
                                                                refUID
                                                              ) =>
                                                                onNavigationClicked(
                                                                  relatedEntities,
                                                                  refUID
                                                                ),
                                                              field["title"]
                                                            )}
                                                          </div>
                                                        );
                                                      }

                                                      return (
                                                        <div>
                                                          {props.uiStyle ===
                                                            "Tab" &&
                                                            hasCustomComponents ===
                                                            false ? (
                                                            <CustomCardWidget cardContentClassName="py-0 px-2-5">
                                                              {detailsValueDiv}
                                                            </CustomCardWidget>
                                                          ) : (
                                                            detailsValueDiv
                                                          )}
                                                        </div>
                                                      );
                                                    }}
                                                  </RAFObjectContext.Consumer>
                                                </>
                                              )}
                                            </>
                                          );
                                        } else {
                                          return null;
                                        }
                                      }}
                                    </RAFAttributesContext.Consumer>
                                  </Fragment>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      )}
                    </RAFCollapseWithPlusIcon>
                  </CustomCardWidget>
                </div>
              );
            })}
          </div>
        </div>
      );
    } else if (isNotEmptyArray(selectedFieldModel)) {
      return (
        <div>
          <div className="mt-3">
            <div
              className={`row align-items-baseline summary-section-outer customSummary label-left-align${props.uiStyle ? " g-2" : " gx-2"
                }`}
            >
              {selectedFieldModel.map((field, index) => {
                return (
                  <Fragment key={field["name"] + "_" + index}>
                    <RAFAttributesContext.Consumer>
                      {({ queryAttributes }) => {
                        let selectedField = queryAttributes.find(
                          (x) => x.AttributeName === field["name"]
                        );
                        if (isNotNullAndUndefined(selectedField)) {
                          let hasCustomComponents = false;
                          if (
                            field["name"] === "address" ||
                            field["name"] === "email" ||
                            field["name"] === "phone"
                          ) {
                            hasCustomComponents = true;
                          }
                          return (
                            <>
                              {queryAttributes && (
                                <>
                                  <RAFObjectContext.Consumer>
                                    {({ rafObject /*saveObject*/ }) => {
                                      let detailsValueDiv;
                                      if (field["name"] === "address") {
                                        let address = rafObject["address"];
                                        if (isNullOrUndefined(address)) {
                                          address = {
                                            line1: null,
                                            city: null,
                                            state: null,
                                            country: null,
                                            postal_code: null,
                                          };
                                        }
                                        detailsValueDiv = (
                                          <CustomCardWidget cardContentClassName="py-0 px-2-5">
                                            <div className="summarySection p-0">
                                              <div className="summaryText">
                                                <span className={"key_header"}>
                                                  {field["title"]}
                                                </span>
                                                <div className="col-12 mt-2">
                                                  <div className="d-flex align-self-center flex-column">
                                                    <div className="m-0 details-value">
                                                      {address["line1"] ??
                                                        "Not set"}
                                                    </div>
                                                    <div className="d-flex">
                                                      {isNotNullAndUndefined(
                                                        address["city"]
                                                      ) ? (
                                                        <div className="m-0 details-value">
                                                          {address["city"]}{" "}
                                                        </div>
                                                      ) : null}
                                                      {isNotNullAndUndefined(
                                                        address["state"]
                                                      ) ? (
                                                        <div className="m-0 details-value">
                                                          {" "}
                                                          , {address["state"]}
                                                        </div>
                                                      ) : null}
                                                    </div>
                                                    <div className="d-flex">
                                                      {isNotNullAndUndefined(
                                                        address["country"]
                                                      ) ? (
                                                        <div className="m-0 details-value">
                                                          {address["country"]}
                                                        </div>
                                                      ) : null}
                                                      {isNotNullAndUndefined(
                                                        address["postal_code"]
                                                      ) ? (
                                                        <div className="m-0 details-value">
                                                          -{" "}
                                                          {
                                                            address[
                                                            "postal_code"
                                                            ]
                                                          }
                                                        </div>
                                                      ) : null}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </CustomCardWidget>
                                        );
                                      } else if (field["name"] === "email") {
                                        let email = rafObject["email"];
                                        if (isNullOrUndefined(email)) {
                                          email = {
                                            personal: null,
                                            work: null,
                                          };
                                        }
                                        detailsValueDiv = (
                                          <>
                                            <CustomCardWidget cardContentClassName="py-0 px-2-5">
                                              <div className="summarySection p-0">
                                                <div className="summaryText">
                                                  <span
                                                    className={"key_header"}
                                                  >
                                                    Personal Email
                                                  </span>
                                                  <div className="col-12 mt-2">
                                                    <div className="d-flex align-self-center flex-column">
                                                      <div className="m-0 details-value">
                                                        {isNotNullAndUndefined(
                                                          email["personal"]
                                                        )
                                                          ? email["personal"]
                                                          : "Not set"}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </CustomCardWidget>
                                            <CustomCardWidget
                                              cardClassName="mt-2"
                                              cardContentClassName="py-0 px-2-5"
                                            >
                                              <div className="summarySection p-0">
                                                <div className="summaryText">
                                                  <span
                                                    className={"key_header"}
                                                  >
                                                    Work Email
                                                  </span>
                                                  <div className="col-12 mt-2">
                                                    <div className="d-flex align-self-center flex-column">
                                                      <div className="m-0 details-value">
                                                        {isNotNullAndUndefined(
                                                          email["work"]
                                                        )
                                                          ? email["work"]
                                                          : "Not set"}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </CustomCardWidget>
                                          </>
                                        );
                                      } else if (field["name"] === "phone") {
                                        let phone = rafObject["phone"];

                                        if (isNullOrUndefined(phone)) {
                                          phone = {
                                            personal: null,
                                            work: null,
                                          };
                                        }
                                        detailsValueDiv = (
                                          <>
                                            <CustomCardWidget cardContentClassName="py-0 px-2-5">
                                              <div className="summarySection p-0">
                                                <div className="summaryText">
                                                  <span
                                                    className={"key_header"}
                                                  >
                                                    Personal Phone
                                                  </span>
                                                  <div className="col-12 mt-2">
                                                    <div className="d-flex align-self-center flex-column">
                                                      <div className="m-0 details-value">
                                                        {isNotNullAndUndefined(
                                                          phone["personal"]
                                                        )
                                                          ? phone["personal"]
                                                          : "Not set"}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </CustomCardWidget>
                                            <CustomCardWidget
                                              cardClassName="mt-2"
                                              cardContentClassName="py-0 px-2-5"
                                            >
                                              <div className="summarySection p-0">
                                                <div className="summaryText">
                                                  <span
                                                    className={"key_header"}
                                                  >
                                                    Work Phone
                                                  </span>
                                                  <div className="col-12 mt-2">
                                                    <div className="d-flex align-self-center flex-column">
                                                      <div className="m-0 details-value">
                                                        {isNotNullAndUndefined(
                                                          phone["work"]
                                                        )
                                                          ? phone["work"]
                                                          : "Not set"}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </CustomCardWidget>
                                          </>
                                        );
                                      } else {
                                        detailsValueDiv = (
                                          <div>
                                            {getDetailsCustomElement(
                                              rafObject,
                                              queryAttributes,
                                              field["name"],
                                              isNotNullAndUndefined(
                                                field["visible"]
                                              )
                                                ? field["visible"]
                                                : true,
                                              true,
                                              false, //field.NavigationEnabled
                                              (relatedEntities, refUID) =>
                                                onNavigationClicked(
                                                  relatedEntities,
                                                  refUID
                                                ),
                                              field["title"]
                                            )}
                                          </div>
                                        );
                                      }

                                      return (
                                        <div>
                                          {props.uiStyle === "Tab" &&
                                            hasCustomComponents === false ? (
                                            <CustomCardWidget cardContentClassName="py-0 px-2-5">
                                              {detailsValueDiv}
                                            </CustomCardWidget>
                                          ) : (
                                            detailsValueDiv
                                          )}
                                        </div>
                                      );
                                    }}
                                  </RAFObjectContext.Consumer>
                                </>
                              )}
                            </>
                          );
                        } else {
                          return null;
                        }
                      }}
                    </RAFAttributesContext.Consumer>
                  </Fragment>
                );
              })}
            </div>
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  const renderTabDetails = () => {
    let section = detailsFormComponents.find(
      (x) => x.DisplayName === state.selectedTabItem
    );
    if (isNullOrUndefined(section)) {
      section = detailsFormComponents[0];
    }
    if (
      isNotNullAndUndefined(detailsFormComponents) &&
      detailsFormComponents.length > 0 &&
      isNotNullAndUndefined(section)
    ) {
      return (
        <div className="mt-3">
          {isNotNullAndUndefined(section.Components) &&
            section.Components.length > 0 ? (
            section.Components.map((column, index) => {
              return (
                <div
                  key={column.UID + "_" + index}
                  className={`row align-items-baseline summary-section-outer customSummary label-left-align${props.uiStyle ? " g-2" : " gx-2"
                    }`}
                >
                  {isNotNullAndUndefined(column.Components) &&
                    column.Components.map((field, index) => {
                      let formItemWidth =
                        isNotNullAndUndefined(field["Width"]) &&
                          window.innerWidth > 576
                          ? field["Width"]
                          : null;
                      let formItemMinWidth =
                        isNotNullAndUndefined(field["Width"]) &&
                          window.innerWidth > 576
                          ? field["Width"]
                          : null;
                      let formItemMaxWidth =
                        isNotNullAndUndefined(field["MaxWidth"]) &&
                          window.innerWidth > 576
                          ? field["MaxWidth"]
                          : null;
                      let formItemTitleLocation = field["TitleLocation"];
                      let formItemDisplayInNewLine =
                        isNotNullAndUndefined(field["DisplayInNewLine"]) &&
                          window.innerWidth > 576
                          ? field["DisplayInNewLine"]
                          : null;

                      let outerDivClass;
                      if (
                        IsNullOrWhiteSpace(formItemTitleLocation) ||
                        formItemTitleLocation === "Top"
                      ) {
                        outerDivClass =
                          isNotNullAndUndefined(field.AttributeSettings) &&
                            isNotNullAndUndefined(
                              field.AttributeSettings.Render
                            ) &&
                            field.AttributeSettings.Render === false
                            ? `hidden`
                            : ``;
                      } else {
                        outerDivClass =
                          isNotNullAndUndefined(field.AttributeSettings) &&
                            isNotNullAndUndefined(
                              field.AttributeSettings.Render
                            ) &&
                            field.AttributeSettings.Render === false
                            ? `mt-4 hidden`
                            : `mt-4`;
                      }

                      return (
                        <Fragment key={field.UID + "_" + index}>
                          <RAFAttributesContext.Consumer>
                            {({ queryAttributes }) => {
                              return (
                                <>
                                  {queryAttributes && (
                                    <>
                                      <RAFObjectContext.Consumer>
                                        {({ rafObject /*saveObject*/ }) => {
                                          let detailsValueDiv = (
                                            <div
                                              style={{
                                                width:
                                                  isNotNullAndUndefined(
                                                    formItemWidth
                                                  ) && formItemDisplayInNewLine
                                                    ? formItemWidth
                                                    : "100%",
                                                ...(isNotNullAndUndefined(
                                                  formItemMinWidth
                                                ) && formItemDisplayInNewLine
                                                  ? {
                                                    minWidth:
                                                      formItemMinWidth,
                                                  }
                                                  : {}),
                                                ...(isNotNullAndUndefined(
                                                  formItemMaxWidth
                                                ) && formItemDisplayInNewLine
                                                  ? {
                                                    maxWidth:
                                                      formItemMaxWidth,
                                                  }
                                                  : {}),
                                              }}
                                            >
                                              {getDetailsElement(
                                                rafObject,
                                                queryAttributes,
                                                field.UID,
                                                field.Visible,
                                                field.ShowLabel,
                                                field.NavigationEnabled,
                                                (relatedEntities, refUID) =>
                                                  onNavigationClicked(
                                                    relatedEntities,
                                                    refUID
                                                  )
                                              )}
                                            </div>
                                          );
                                          return (
                                            <div
                                              id={"formItemDiv_" + field.UID}
                                              style={{
                                                width:
                                                  isNotNullAndUndefined(
                                                    formItemWidth
                                                  ) && !formItemDisplayInNewLine
                                                    ? formItemWidth
                                                    : "100%",
                                                ...(isNotNullAndUndefined(
                                                  formItemMinWidth
                                                ) && !formItemDisplayInNewLine
                                                  ? {
                                                    minWidth:
                                                      formItemMinWidth,
                                                  }
                                                  : {}),
                                                ...(isNotNullAndUndefined(
                                                  formItemMaxWidth
                                                ) && !formItemDisplayInNewLine
                                                  ? {
                                                    maxWidth:
                                                      formItemMaxWidth,
                                                  }
                                                  : {}),
                                                // ...(isNotNullAndUndefined(formItemDisplayInNewLine)
                                                //   ? { flex: "1 0" }
                                                //   : {}),
                                              }}
                                            >
                                              {props.uiStyle === "Tab" ? (
                                                <CustomCardWidget cardContentClassName="py-0 px-2-5">
                                                  {detailsValueDiv}
                                                </CustomCardWidget>
                                              ) : (
                                                detailsValueDiv
                                              )}
                                            </div>
                                          );
                                        }}
                                      </RAFObjectContext.Consumer>
                                    </>
                                  )}
                                </>
                              );
                            }}
                          </RAFAttributesContext.Consumer>
                        </Fragment>
                      );
                    })}
                </div>
              );
            })
          ) : (
            <></>
          )}
        </div>
      );
    }
  };

  if (fieldsToShow) {
    if (isNotEmptyArray(pages)) {
      const allPagesHaveTitle = pages.every((page) => page.hasOwnProperty("title"));

      return (
        <div>
          <div>
            {pages.length > 1 && allPagesHaveTitle ? (
              <div
                id="raf_details_page_tab_header_container"
                className="raf_tab_header hideScrollBar full-oval-tab raf_md"
              >
                {!BrowserIsDevice && (
                  <RAFButtonComponent
                    iconBtn
                    iconCss="fas fa-chevron-left"
                    className="bg-white border-0 custom-button-lg"
                    id="raf_details_page_tab_header_left_scroll"
                  ></RAFButtonComponent>
                )}
                <div
                  id="raf_details_page_tab_header"
                  className="raf_tab_header hideScrollBar full-oval-tab raf_md"
                >
                  {pages.map((page, index) => {
                    return (
                      <div
                        className={`raf_tab_header_item${(isNotNullAndUndefined(state.selectedTabItem) &&
                          state.selectedTabItem === page["name"]) ||
                          (isNullOrUndefined(state.selectedTabItem) &&
                            index === 0)
                          ? " active"
                          : ""
                          }`}
                        key={page["name"]}
                        onClick={() => onTabSelected(page["name"])}
                        style={{
                          display: isNotNullAndUndefined(page["title"])
                            ? "flex"
                            : "none",
                        }}
                      >
                        <span>{isNotNullAndUndefined(page["title"]) ? page["title"] : ''}</span>
                      </div>
                    );
                  })}
                </div>
                {!BrowserIsDevice && (
                  <RAFButtonComponent
                    iconBtn
                    iconCss="fas fa-chevron-right"
                    className="bg-white border-0 custom-button-lg"
                    id="raf_details_page_tab_header_right_scroll"
                  ></RAFButtonComponent>
                )}
              </div>
            )
              :
              ''
            }
            {renderPageTabDetails()}
          </div>
        </div>
      );
    } else if (isNotNullAndUndefined(detailsPageLayout)) {
      return (
        <div>
          {props.uiStyle ? (
            <div>
              <div
                id="raf_details_page_tab_header_container"
                className="raf_tab_header hideScrollBar full-oval-tab raf_md"
              >
                {/* <div className="px-3 d-flex align-items-center pointer"
                  onClick={() => setSelectedRecipientRightContent(null)}
                >
                  <i className={`${RAFButtonConstant.ArrowBack.IconCss} content_neutral_dark`}></i>
                  {/* <span className="subtitle_2 ps-2">Back</span> */}
                {/*</div> */}
                {!BrowserIsDevice && (
                  <RAFButtonComponent
                    iconBtn
                    iconCss="fas fa-chevron-left"
                    className="bg-white border-0 custom-button-lg"
                    id="raf_details_page_tab_header_left_scroll"
                  ></RAFButtonComponent>
                )}
                <div
                  id="raf_details_page_tab_header"
                  className="raf_tab_header hideScrollBar full-oval-tab raf_md"
                >
                  {isNotNullAndUndefined(detailsFormComponents) &&
                    detailsFormComponents.length > 0
                    ? detailsFormComponents.map((section, index) => {
                      return (
                        <div
                          className={`raf_tab_header_item${(isNotNullAndUndefined(state.selectedTabItem) &&
                            state.selectedTabItem ===
                            section.DisplayName) ||
                            (isNullOrUndefined(state.selectedTabItem) &&
                              index === 0)
                            ? " active"
                            : ""
                            }`}
                          key={section.DisplayName}
                          onClick={() => onTabSelected(section.DisplayName)}
                        >
                          <span>{section.DisplayName}</span>
                        </div>
                      );
                    })
                    : ""}
                </div>
                {!BrowserIsDevice && (
                  <RAFButtonComponent
                    iconBtn
                    iconCss="fas fa-chevron-right"
                    className="bg-white border-0 custom-button-lg"
                    id="raf_details_page_tab_header_right_scroll"
                  ></RAFButtonComponent>
                )}
              </div>
              {renderTabDetails()}
            </div>
          ) : (
            <div className="row g-0 gy-3">
              {isNotNullAndUndefined(detailsFormComponents) &&
                detailsFormComponents.length > 0 ? (
                detailsFormComponents.map((section, index) => {
                  return (
                    <div className="col-12" key={section.UID + "_" + index}>
                      <CustomCardWidget
                        removeContentPadding
                        cardClassName="overflow-hidden"
                      >
                        <RAFCollapseWithPlusIcon
                          toggleArrowIcon="Arrow"
                          title={section.DisplayName}
                          collapsePanelHeaderClass={
                            "collapsePanel__header__fixedHeight__lg"
                          }
                          allowFullRowClick
                          isCollapsed={section.IsCollapsed}
                          showSeparator={true}
                        >
                          <Fragment>
                            {isNotNullAndUndefined(section.Components) &&
                              section.Components.length > 0 ? (
                              section.Components.map((column, index) => {
                                return (
                                  <div
                                    key={column.UID + "_" + index}
                                    className="row gx-2 align-items-baseline summary-section-outer customSummary label-left-align"
                                  >
                                    {isNotNullAndUndefined(column.Components) &&
                                      column.Components.map((field, index) => {
                                        let formItemWidth =
                                          isNotNullAndUndefined(
                                            field["Width"]
                                          ) && window.innerWidth > 576
                                            ? field["Width"]
                                            : null;
                                        let formItemMinWidth =
                                          isNotNullAndUndefined(
                                            field["Width"]
                                          ) && window.innerWidth > 576
                                            ? field["Width"]
                                            : null;
                                        let formItemMaxWidth =
                                          isNotNullAndUndefined(
                                            field["MaxWidth"]
                                          ) && window.innerWidth > 576
                                            ? field["MaxWidth"]
                                            : null;
                                        let formItemTitleLocation =
                                          field["TitleLocation"];
                                        let formItemDisplayInNewLine =
                                          isNotNullAndUndefined(
                                            field["DisplayInNewLine"]
                                          ) && window.innerWidth > 576
                                            ? field["DisplayInNewLine"]
                                            : null;

                                        let outerDivClass;
                                        if (
                                          IsNullOrWhiteSpace(
                                            formItemTitleLocation
                                          ) ||
                                          formItemTitleLocation === "Top"
                                        ) {
                                          outerDivClass =
                                            isNotNullAndUndefined(
                                              field.AttributeSettings
                                            ) &&
                                              isNotNullAndUndefined(
                                                field.AttributeSettings.Render
                                              ) &&
                                              field.AttributeSettings.Render ===
                                              false
                                              ? `hidden`
                                              : ``;
                                        } else {
                                          outerDivClass =
                                            isNotNullAndUndefined(
                                              field.AttributeSettings
                                            ) &&
                                              isNotNullAndUndefined(
                                                field.AttributeSettings.Render
                                              ) &&
                                              field.AttributeSettings.Render ===
                                              false
                                              ? `mt-4 hidden`
                                              : `mt-4`;
                                        }

                                        return (
                                          <Fragment
                                            key={field.UID + "_" + index}
                                          >
                                            <RAFAttributesContext.Consumer>
                                              {({ queryAttributes }) => {
                                                return (
                                                  <>
                                                    {queryAttributes && (
                                                      <>
                                                        <RAFObjectContext.Consumer>
                                                          {({
                                                            rafObject /*saveObject*/,
                                                          }) => {
                                                            return (
                                                              <div
                                                                id={
                                                                  "formItemDiv_" +
                                                                  field.UID
                                                                }
                                                                style={{
                                                                  width:
                                                                    isNotNullAndUndefined(
                                                                      formItemWidth
                                                                    ) &&
                                                                      !formItemDisplayInNewLine
                                                                      ? formItemWidth
                                                                      : "100%",
                                                                  ...(isNotNullAndUndefined(
                                                                    formItemMinWidth
                                                                  ) &&
                                                                    !formItemDisplayInNewLine
                                                                    ? {
                                                                      minWidth:
                                                                        formItemMinWidth,
                                                                    }
                                                                    : {}),
                                                                  ...(isNotNullAndUndefined(
                                                                    formItemMaxWidth
                                                                  ) &&
                                                                    !formItemDisplayInNewLine
                                                                    ? {
                                                                      maxWidth:
                                                                        formItemMaxWidth,
                                                                    }
                                                                    : {}),
                                                                  // ...(isNotNullAndUndefined(formItemDisplayInNewLine)
                                                                  //   ? { flex: "1 0" }
                                                                  //   : {}),
                                                                }}
                                                              >
                                                                <div
                                                                  style={{
                                                                    width:
                                                                      isNotNullAndUndefined(
                                                                        formItemWidth
                                                                      ) &&
                                                                        formItemDisplayInNewLine
                                                                        ? formItemWidth
                                                                        : "100%",
                                                                    ...(isNotNullAndUndefined(
                                                                      formItemMinWidth
                                                                    ) &&
                                                                      formItemDisplayInNewLine
                                                                      ? {
                                                                        minWidth:
                                                                          formItemMinWidth,
                                                                      }
                                                                      : {}),
                                                                    ...(isNotNullAndUndefined(
                                                                      formItemMaxWidth
                                                                    ) &&
                                                                      formItemDisplayInNewLine
                                                                      ? {
                                                                        maxWidth:
                                                                          formItemMaxWidth,
                                                                      }
                                                                      : {}),
                                                                  }}
                                                                >
                                                                  {getDetailsElement(
                                                                    rafObject,
                                                                    queryAttributes,
                                                                    field.UID,
                                                                    field.Visible,
                                                                    field.ShowLabel,
                                                                    field.NavigationEnabled,
                                                                    (
                                                                      relatedEntities,
                                                                      refUID
                                                                    ) =>
                                                                      onNavigationClicked(
                                                                        relatedEntities,
                                                                        refUID
                                                                      )
                                                                  )}
                                                                </div>
                                                              </div>
                                                            );
                                                          }}
                                                        </RAFObjectContext.Consumer>
                                                      </>
                                                    )}
                                                  </>
                                                );
                                              }}
                                            </RAFAttributesContext.Consumer>
                                          </Fragment>
                                        );
                                      })}
                                  </div>
                                );
                              })
                            ) : (
                              <></>
                            )}
                          </Fragment>
                        </RAFCollapseWithPlusIcon>
                      </CustomCardWidget>
                    </div>
                  );
                })
              ) : (
                <></>
              )}
              {tagComponent}
            </div>
          )}
        </div>
      );
    }
  } else if (isNotNullAndUndefined(defaultFormComponent)) {
    return (
      <div className="p-3 pb-2 pt-1">
        <div className="row gx-2 align-items-baseline summary-section-outer customSummary label-left-align">
          {isNotNullAndUndefined(defaultFormComponent.Components) &&
            defaultFormComponent.Components.length > 0 ? (
            defaultFormComponent.Components.map((column, index) => {
              return (
                <div
                  className={
                    defaultFormComponent.Components.length === 1
                      ? "col"
                      : defaultFormComponent.Components.length === 2
                        ? "col-md-6"
                        : defaultFormComponent.Components.length === 3
                          ? "col-md-4"
                          : "col-md-3"
                  }
                  key={column.UID + "_" + index}
                >
                  <div className="w-100 h-100 d-flex flex-column justify-content-center">
                    {isNotNullAndUndefined(column.Components) &&
                      column.Components.map((field, index) => {
                        return (
                          <Fragment key={field.UID + "_" + index}>
                            <RAFObjectContext.Consumer>
                              {({ rafObject /*saveObject*/ }) => {
                                return (
                                  <div id={"formItemDiv_" + field.UID}>
                                    {getDetailsElement(
                                      rafObject,
                                      queryAttributes,
                                      field.UID,
                                      field.Visible,
                                      true,
                                      field.DataType === RAFDataType.Lookup,
                                      (relatedEntities, refUID) =>
                                        onNavigationClicked(
                                          relatedEntities,
                                          refUID
                                        )
                                    )}
                                  </div>
                                );
                              }}
                            </RAFObjectContext.Consumer>
                          </Fragment>
                        );
                      })}
                  </div>
                </div>
              );
            })
          ) : (
            <></>
          )}
          {tagComponent}
        </div>
      </div>
    );
  } else {
    return (
      <div className="p-3 pb-2 pt-1">
        <div className="row g-3 flex-nowrap">{defaultComponent}</div>
      </div>
    );
  }
}
