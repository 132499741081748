import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import React, { Component } from "react";
import { FormRenderProps } from "react-final-form";
import ACIconPicker from "../../../../components/shared/ACFormFields/ACIconPicker";
import ACLookup from "../../../../components/shared/ACFormFields/ACLookup";
import ACTextBox from "../../../../components/shared/ACFormFields/ACTextBox";
import { getSessionStorage, hideProgress, showProgress } from "../../../../RAFComponents/helpers/AppHelper";
import { StorageKey } from "../../../../constants/Common/Constants";
import { deepEqual, IDialogProps, isNotNullAndUndefined, RAFFormComponentProps } from "../../../../RAFComponents/helpers/utils";
import RAFForm from "../../../../RAFComponents/Inputs/RAFForm";
import { setFormValue } from "../../../../RAFComponents/Inputs/RFFUtils";
import { NavigateParamsProps } from "../../../../router";
import { RAFNavigationContext } from "../../../Common/Providers/RAFNavigationContextProvider";
import { WorkspaceItemsContext } from "../../../Common/Providers/WorkspaceContextProvider";
import { WorkspaceRow } from "../WorkspaceRow";
import { checkUniqueAndSaveWorkspaceItem, getWorkspaceItemsByWorkspaceId } from "./Helper/WorkspaceItemHelper";
import { WorkspaceItemRow } from "./WorkspaceItemRow";

interface IState {
    isActive: boolean,
    workspaceItemRow: WorkspaceItemRow;
}

interface IProps {
    isActive: boolean,
    entity: string,
    isSystem: boolean,
    relatedto: string,
    relatedtoType: string,
    title: string,
    relatedtoUID?: string,
}

class AddRelatedPortalItem extends Component<IDialogProps & RAFFormComponentProps & NavigateParamsProps & IProps, IState> {
    deleteDialog: any;

    _isMounted = false;
    public rafForm: FormRenderProps | null;
    public submitButton: ButtonComponent;
    _isSaveAndCreateNew = false;

    constructor(props) {
        super(props);
        this.state = {
            isActive: false,
            workspaceItemRow: null,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.refresh();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate = (prevProps) => {
        if (!deepEqual(prevProps.isActive, this.props.isActive) ||
            !deepEqual(prevProps.entity, this.props.entity) ||
            !deepEqual(prevProps.isSystem, this.props.isSystem) ||
            !deepEqual(prevProps.relatedto, this.props.relatedto) ||
            !deepEqual(prevProps.relatedtoType, this.props.relatedtoType) ||
            !deepEqual(prevProps.title, this.props.title) ||
            !deepEqual(prevProps.relatedtoUID, this.props.relatedtoUID)
        ) {
            this.setState({ isActive: false, workspaceItemRow: null }, () => {
                this.refresh();
            });
        }
    };

    refresh = () => {
        let { isActive } = this.props;
        if (this.props.isActive === true) {
            let workspaceItemRow: WorkspaceItemRow = new WorkspaceItemRow();
            workspaceItemRow.Icon = "FaRegWindowRestore";
            workspaceItemRow.Title = this.props.title;
            workspaceItemRow.Entity = this.props.entity;
            workspaceItemRow.IsSystem = this.props.isSystem;
            workspaceItemRow.Relatedto = this.props.relatedto;
            workspaceItemRow.RelatedtoUID = this.props.relatedtoUID;
            workspaceItemRow.RelatedtoType = this.props.relatedtoType;
            const recentClickedWorkspace: WorkspaceRow = JSON.parse(
                getSessionStorage(StorageKey.CurrentPortal, true)
            );
            if (isNotNullAndUndefined(recentClickedWorkspace) && isNotNullAndUndefined(recentClickedWorkspace.UID)) {
                workspaceItemRow.Portal = recentClickedWorkspace.PortalName;
                workspaceItemRow.PortalUID = recentClickedWorkspace.UID;
            }
            if (this._isMounted) {
                this.setState({ isActive, workspaceItemRow });
            }
        }
    };


    addWorkSpaceItemToWorkspace = (workspaceItemRow, updateCurrentWorkspaceItems) => {
        //if (isNotNullAndUndefined(workspaceItemRow.RelatedtoUID)) {
        let progressDiv = showProgress('#addToWorkspaceDialogContent');
        getWorkspaceItemsByWorkspaceId(workspaceItemRow.PortalUID).then((workspaceItemList) => {
            let maximundisplayorder = isNotNullAndUndefined(workspaceItemList) && workspaceItemList.length > 0 ? Math.max.apply(Math, workspaceItemList.map(o => o.DisplayOrder)) : 0;
            workspaceItemRow.DisplayOrder = isNotNullAndUndefined(maximundisplayorder) ? maximundisplayorder + 1 : 1;
            checkUniqueAndSaveWorkspaceItem(workspaceItemRow, workspaceItemList).then(entityId => {
                hideProgress(progressDiv);
                if (isNotNullAndUndefined(entityId)) {
                    updateCurrentWorkspaceItems(workspaceItemRow.PortalUID);
                    this.props.onClose();
                }
            });
        });
        //} else {
        //showWarningToast('Unable to add this view to workspace!');
        //}
    };

    onPortalItemChange = () => {
        setFormValue(this.rafForm, 'ParentUID', null);
        setFormValue(this.rafForm, 'Parent', null);
    };

    render() {
        let { workspaceItemRow, isActive } = this.state;

        if (isNotNullAndUndefined(workspaceItemRow) && isActive === true) {
            return (
                <div>
                    <WorkspaceItemsContext.Consumer>
                        {({ workspaceItems, updateCurrentWorkspaceItems }) => {
                            return (
                                <RAFNavigationContext.Consumer>
                                    {({ selectedMenuItem }) => {
                                        let activeMenuItem = isNotNullAndUndefined(selectedMenuItem) && isNotNullAndUndefined(selectedMenuItem.UID) &&
                                            isNotNullAndUndefined(workspaceItems) && workspaceItems.length > 0 ? workspaceItems.find(x => x.UID === selectedMenuItem.UID) : null;

                                        if (isNotNullAndUndefined(activeMenuItem) && isNotNullAndUndefined(activeMenuItem.UID) && workspaceItemRow.PortalUID === activeMenuItem.PortalUID) {
                                            if (isNotNullAndUndefined(activeMenuItem.ParentUID) && isNotNullAndUndefined(activeMenuItem.Parent)) {
                                                workspaceItemRow.Parent = activeMenuItem.Parent;
                                                workspaceItemRow.ParentUID = activeMenuItem.ParentUID;
                                            } else {
                                                workspaceItemRow.Parent = activeMenuItem.Title;
                                                workspaceItemRow.ParentUID = activeMenuItem.UID;
                                            }
                                        }

                                        return (
                                            <RAFForm type={WorkspaceItemRow}
                                                formRef={g => this.rafForm = g}
                                                submitOnEnterKey={false} initialValues={workspaceItemRow}
                                                onSubmit={(value) => this.addWorkSpaceItemToWorkspace(value, updateCurrentWorkspaceItems)}
                                            >  <div className="e-dlg-body-content py-3">
                                                    <ACLookup<WorkspaceItemRow> field="Portal" label="Choose a Portal" required
                                                        url={'Portal/Lookup'}
                                                        placeholder="Choose a Portal"
                                                        onChanged={this.onPortalItemChange}
                                                    />
                                                    {/* <WhenFieldChanges
                                            field="Portal"
                                            set="ParentUID"
                                            to={null}
                                        />
                                        <WhenFieldChanges
                                            field="Portal"
                                            set="Parent"
                                            to={null}
                                        /> */}
                                                    {/* <ConditionNotNullOrWhiteSpace when={'PortalUID'} >
                                                        <RAFFormContext.Consumer>
                                                            {(formContext) => {
                                                                const portalUID = formContext.values.PortalUID;
                                                                let customFilter: RAFCustomFilter = {};
                                                                customFilter.Condition = "and";
                                                                customFilter.Rules = [];
                                                                if (!IsNullOrWhiteSpace(portalUID)) {
                                                                    let filter: RAFCustomFilter = {};
                                                                    let filterVal: string[] = [];
                                                                    filterVal.push(portalUID);
                                                                    filter.Operator = RAFCustomOperator.Equal;
                                                                    filter.Value = filterVal;
                                                                    filter.Field = propertyOf<WorkspaceItemRow>("PortalUID");
                                                                    customFilter.Rules.push(filter);
                                                                } else {
                                                                    customFilter = null;
                                                                }
                                                                return (
                                                                    <ACLookup<WorkspaceItemRow>
                                                                        key={portalUID}
                                                                        field="Parent"
                                                                        label="Choose Parent"
                                                                        placeholder="Choose Parent"
                                                                        url={"PortalItem/Lookup"}
                                                                        moduleName={'portal_item'}
                                                                        selectedField={"Title"}
                                                                        showCreateButton={false}
                                                                        showFullList={false}
                                                                        showClearButton
                                                                        showLabel
                                                                        customFilter={customFilter}
                                                                    />
                                                                );
                                                            }}
                                                        </RAFFormContext.Consumer>
                                                    </ConditionNotNullOrWhiteSpace> */}
                                                    <ACTextBox<WorkspaceItemRow> field="Title" label="Title" required placeholder="Title" />
                                                    <ACIconPicker<WorkspaceItemRow> field="Icon" label="Select Icon" />
                                                </div>
                                                <div className="e-dlg-footerContent">
                                                    <ButtonComponent
                                                        type='button'
                                                        className="me-2"
                                                        cssClass='e-flat e-info'
                                                        onClick={() => { this.rafForm && this.rafForm.form.submit(); }}
                                                    >Add</ButtonComponent>
                                                    <ButtonComponent type="button" cssClass='e-flat' onClick={() => this.props.onClose()}>Cancel</ButtonComponent>
                                                </div>
                                            </RAFForm>
                                        );
                                    }}
                                </RAFNavigationContext.Consumer>
                            );
                        }
                        }
                    </WorkspaceItemsContext.Consumer>
                </div>
            );
        }
        else {
            return (
                <div className="e-dlg-content-outer" id="workspaceItemsDetailsdiv">
                    <div className="e-dlg-headerContent justify-content-between">
                        <div className="e-dlg-header" id="_title"></div>

                        <ButtonComponent type='button' cssClass="primary-custom-button"
                            iconCss="fas fa-xmark"
                            onClick={() => this.props.onClose()}
                        ></ButtonComponent>
                    </div>
                    <div className="e-dlg-body-content bp-body-content mt-0 h-100 overflow-auto py-1">
                    </div>
                </div>
            );
        }
    }
}

export default AddRelatedPortalItem;
