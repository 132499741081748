import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { DialogUtility } from "@syncfusion/ej2-react-popups";
import { DropDownButtonComponent, MenuEventArgs } from "@syncfusion/ej2-react-splitbuttons";
import React, { Component } from "react";
import { FormRenderProps } from "react-final-form";
import ACDropdown from "../../../components/shared/ACFormFields/ACDropdown";
import ACIconPicker from "../../../components/shared/ACFormFields/ACIconPicker";
import ACTextBox from "../../../components/shared/ACFormFields/ACTextBox";
import { DeleteRecord, getSessionStorage, hideProgress, removeSessionStorage, showProgress } from "../../../RAFComponents/helpers/AppHelper";
import { RAFLayout, StorageKey } from "../../../constants/Common/Constants";
import { deepEqual, IDialogProps, isNotEmptyArray, isNotNullAndUndefined, IsNullOrWhiteSpace, propertyOf, RAFFormComponentProps } from "../../../RAFComponents/helpers/utils";
import RAFChoiceOption from "../../../RAFComponents/Inputs/RAFChoiceOption";
import RAFForm from "../../../RAFComponents/Inputs/RAFForm";
import { RAFCustomFilter, RAFCustomOperator } from "../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import { showSuccessToast, showWarningToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import { NavigateParamsProps } from "../../../router";
import { isPortalChildItemExist, RAFPortalType, systemPortalNames } from "./WorkspaceHelper";
import { WorkspaceRow } from "./WorkspaceRow";
import { getWorkspaceRetrieve, WorkspaceSave } from "./WorkspaceService/WorkspaceServiceHelper";
import { RAFEntityName } from "../../../constants/Common/EntityConstants";

interface IState {
    isActive: boolean,
    workspaceRow: WorkspaceRow,
}

interface IProps {
    isActive?: boolean,
    workspaceUID: string,
    workspaceRow: WorkspaceRow,
    workspaceList: WorkspaceRow[],
    onDelete: () => void,
    selectedParentWorkspace?: WorkspaceRow;
    mode: 'create' | 'update' | 'createChild';
}

class ManageWorkspace extends Component<IDialogProps & RAFFormComponentProps & NavigateParamsProps & IProps, IState> {

    constructor(props) {
        super(props);
        this.state = {
            isActive: false,
            workspaceRow: null,
        };
    }

    _isMounted = false;
    public rafForm: FormRenderProps | null;
    deleteDialog: any;

    componentDidMount() {
        this._isMounted = true;
        this.refresh();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate = (prevProps/*, prevState*/) => {
        if (!deepEqual(prevProps.isActive, this.props.isActive) ||
            !deepEqual(prevProps.workspaceUID, this.props.workspaceUID) ||
            !deepEqual(prevProps.workspaceRow, this.props.workspaceRow) ||
            !deepEqual(prevProps.selectedParentWorkspace, this.props.selectedParentWorkspace) ||
            !deepEqual(prevProps.mode, this.props.mode)
        ) {
            this.setState({ isActive: false, workspaceRow: null }, () => {
                this.refresh();
            });
            //this.setState({ isActive: this.props.isActive })
        }
    };

    refresh = () => {
        let { mode, workspaceRow, workspaceUID, selectedParentWorkspace, workspaceList, isActive } = this.props;
        if (isActive === true) {
            if (this._isMounted) {
                if (mode === 'update') {
                    if (isNotNullAndUndefined(workspaceRow) && isNotNullAndUndefined(workspaceRow.UID)) {
                        // if (IsNullOrWhiteSpace(workspaceRow.ParentUID)) {
                        //     workspaceRow.Parent = defaultPortalName;
                        // }
                        this.setState({ isActive, workspaceRow: workspaceRow });
                    } else if (isNotNullAndUndefined(workspaceUID)) {
                        let progressDiv = showProgress('#manageWorkSpaceDialog');
                        getWorkspaceRetrieve(workspaceUID).then((workspaceRow) => {
                            hideProgress(progressDiv);
                            // if (isNotNullAndUndefined(workspaceRow) && IsNullOrWhiteSpace(workspaceRow.ParentUID)) {
                            //     workspaceRow.Parent = defaultPortalName;
                            // }
                            if (this._isMounted) {
                                this.setState({ isActive, workspaceRow: workspaceRow });
                            }
                        });
                    }
                } else if (mode === 'create' || mode === 'createChild') {
                    let workspaceRow: WorkspaceRow = new WorkspaceRow();
                    workspaceRow.PortalType = RAFPortalType.Internal;
                    workspaceRow.IsSystem = false;
                    workspaceRow.Icon = 'FaAvianex';

                    if (mode === 'createChild' && isNotNullAndUndefined(selectedParentWorkspace) &&
                        (selectedParentWorkspace.PortalType === RAFPortalType.Internal ||
                            selectedParentWorkspace.PortalType === RAFPortalType.Tenant)
                    ) {
                        workspaceRow.Parent = selectedParentWorkspace.PortalName;
                        workspaceRow.ParentUID = selectedParentWorkspace.UID;
                    } else {
                        const tenantPortal = isNotEmptyArray(workspaceList) ? workspaceList.find(x => x.PortalType === RAFPortalType.Tenant) : null;
                        if (isNotNullAndUndefined(tenantPortal)) {
                            workspaceRow.Parent = tenantPortal.PortalName;
                            workspaceRow.ParentUID = tenantPortal.UID;
                        }
                    }
                    // if (IsNullOrWhiteSpace(workspaceRow.ParentUID)) {
                    //     workspaceRow.Parent = defaultPortalName;
                    // }
                    this.setState({ isActive, workspaceRow: workspaceRow });
                }
            }
        }
    };

    checkWorkspaceExist(currentWorkspaceRow: WorkspaceRow, workspaceList: WorkspaceRow[]) {
        if (isNotNullAndUndefined(currentWorkspaceRow) && isNotNullAndUndefined(workspaceList)) {
            let items = workspaceList.filter(x => x.PortalName === currentWorkspaceRow.PortalName && x.UID !== currentWorkspaceRow.UID);
            if (isNotNullAndUndefined(items) && items.length > 0) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    }

    onSubmitCreateWorkspace = (value: WorkspaceRow) => {
        let isSystemName = systemPortalNames.includes(value.PortalName);
        if (isSystemName === false) {
            if (!this.checkWorkspaceExist(value, this.props.workspaceList)) {
                let progressDiv = showProgress('#manageWorkSpaceDialog');
                if (IsNullOrWhiteSpace(value.ParentUID)) {
                    value.Parent = null;
                }
                WorkspaceSave(value).then((entityId) => {
                    hideProgress(progressDiv);
                    if (isNotNullAndUndefined(entityId)) {
                        if (isNotNullAndUndefined(value.UID))
                            showSuccessToast('Portal updated successfully');
                        else
                            showSuccessToast('Portal saved successfully');
                        if (this._isMounted) {
                            this.setState({ isActive: false }, () => {
                                if (this.props.onSave)
                                    this.props.onSave(entityId);
                            });
                        }
                    } else {
                        showWarningToast('Sorry something went wrong!');
                    }
                })
                    .catch((error) => error);
            } else {
                showWarningToast('Portal with the name you specified already exists. Specify a different Portal name.');
            }
        } else {
            showWarningToast('The given title is a system keyword. Please try with another title.');
        }
    };

    onMoreMenuClicked = (args: MenuEventArgs) => {
        if (isNotNullAndUndefined(args) && isNotNullAndUndefined(args.item)) {
            switch (args.item.id) {
                case 'Cancel':
                    if (this.props.onClose)
                        this.props.onClose();
                    break;
                case 'Delete':
                    this.DeleteClicked();
                    break;
                default:
                    break;
            }
        }
    };

    DeleteClicked() {
        this.deleteDialog = DialogUtility.confirm({
            animationSettings: { effect: 'Fade' },
            cancelButton: { text: 'No' },
            closeOnEscape: false,
            content: "Are you sure want to delete?",
            okButton: { text: 'Yes', click: this.DeleteRecord.bind(this) },
            title: ' Delete Portal',
            position: { X: 'center', Y: 'center' },
            cssClass: 'alert-dialog',
        });
    }

    DeleteRecord() {
        let { workspaceRow } = this.state;
        let { workspaceList } = this.props;
        let id = workspaceRow.UID;
        let isItemExist = isPortalChildItemExist(id, workspaceList);
        if (isItemExist === false) {
            const currentWorkspace = JSON.parse(getSessionStorage(StorageKey.CurrentPortal, true));
            if (currentWorkspace.UID !== id) {
                let progressDiv = showProgress('.alert-dialog');
                DeleteRecord(id, RAFEntityName.Portal).then((response) => {
                    hideProgress(progressDiv);
                    removeSessionStorage(StorageKey.PortalList);
                    if (isNotNullAndUndefined(response)) {
                        this.deleteDialog.hide();
                        if (this._isMounted) {
                            this.setState({ isActive: false }, () => {
                                if (this.props.onDelete) {
                                    this.props.onDelete();
                                }
                            });
                        }
                    }
                })
                    .catch((error) => error);
            } else {
                showWarningToast("Sorry you cannot delete current workspace!");
                this.deleteDialog.hide();
            }
        } else {
            this.deleteDialog.hide();
        }
    }

    checkIsPortalTypeDisabled(workspaceRow, workspaceList, mode) {
        let isDisabled = mode === 'update' ? true : false;
        // mode === 'createChild' ? true :
        // mode === 'update' &&
        //     isNotNullAndUndefined(workspaceRow.UID) &&
        //     workspaceRow.PortalType === RAFPortalType.Internal &&
        //     isNotNullAndUndefined(workspaceList) &&
        //     workspaceList.length > 0 &&
        //     workspaceList.filter(x => x.PortalType === RAFPortalType.Internal && (x.ParentUID === workspaceRow.UID || x.UID === workspaceRow.ParentUID)).length > 0 ?
        //     true : false;


        return isDisabled;
    }

    getCustomFilter = (workspaceUID) => {
        let customFilter: RAFCustomFilter = {};
        customFilter.Condition = "and";
        customFilter.Rules = [];

        let filter: RAFCustomFilter = {};
        let filterVal: string[] = [];
        filterVal.push(RAFPortalType.Internal);
        filter.Operator = RAFCustomOperator.EndsWith;
        filter.Value = filterVal;
        filter.Field = propertyOf<WorkspaceRow>("PortalType");
        customFilter.Rules.push(filter);

        if (!IsNullOrWhiteSpace(workspaceUID)) {
            let filter1: RAFCustomFilter = {};
            let filterVal1: string[] = [];
            filterVal1.push(workspaceUID);
            filter1.Operator = RAFCustomOperator.NotEqual;
            filter1.Value = filterVal1;
            filter1.Field = propertyOf<WorkspaceRow>("UID");
            customFilter.Rules.push(filter1);
        }

        return customFilter;
    };

    render() {
        let { workspaceRow, isActive } = this.state;
        let { workspaceList, mode } = this.props;
        if (isNotNullAndUndefined(workspaceRow) && isActive === true) {
            let isDisabled = this.checkIsPortalTypeDisabled(workspaceRow, workspaceList, mode);
            //let customFilter = this.getCustomFilter(workspaceRow.UID);

            return (
                <div>
                    <RAFForm type={WorkspaceRow} initialValues={workspaceRow} formRef={g => this.rafForm = g} layout={RAFLayout.TwoColumnLayout}
                        onSubmit={this.onSubmitCreateWorkspace}
                    >
                        <div className="e-dlg-body-content">
                            <ACTextBox<WorkspaceRow> field="PortalName" label="Title" required placeholder="Title" />
                            <ACDropdown<WorkspaceRow> field="PortalType" label="Type" placeholder="Select type"
                                showClearButton
                                allowAdd={false}
                                uitype={"colorpicker"}
                                hasChild
                                displayType={"Default"}
                                displayDropdownType={'customDropdownBtn'}
                                customDropdownBtnClassname="text-block"
                                disabled={isDisabled}
                            >
                                <RAFChoiceOption value={RAFPortalType.Internal} label={RAFPortalType.Internal} colorCode="#2dc26b" />
                                <RAFChoiceOption value={RAFPortalType.PartnerHub} label={RAFPortalType.PartnerHub} colorCode="#3598db" />
                                <RAFChoiceOption value={RAFPortalType.Public} label={RAFPortalType.Public} colorCode="#3598db" />
                                {/* <RAFChoiceOption value={RAFPortalType.PartnerConnect} label={RAFPortalType.PartnerConnect} colorCode="#3598db" /> */}
                            </ACDropdown>
                            {/* <ACLookup<WorkspaceRow>
                                field="Parent"
                                label="Parent"
                                placeholder="Select portal"
                                url={"Portal/Lookup"}
                                moduleName={RAFEntityName.Portal}
                                labelClassName="bp-label col-12"
                                inputFieldClassName="bp-Input col-12"
                                SearchCreateOptionMode={"Footer"}
                                addEmpty
                                emptyString={defaultPortalName}
                                customFilter={customFilter}
                                required
                            /> */}
                            <ACDropdown<WorkspaceRow>
                                field="Label"
                                label="Label"
                                placeholder="Select Label"
                                isColorOption
                                uitype="colorpicker"
                                createOptionMode={"Footer"}
                                moduleName={RAFEntityName.Portal}
                            />
                            <ACIconPicker<WorkspaceRow> field="Icon" label="Select Icon" />
                        </div>
                        <div className="e-dlg-footerContent ">
                            <div className="row gx-2">
                                <div className="col-auto">
                                    <ButtonComponent cssClass='e-flat e-info form-custom-button' >OK</ButtonComponent>
                                </div>
                                <div className="col-auto">
                                    <ButtonComponent type="button" cssClass='e-flat form-custom-button' onClick={() => this.props.onClose()}>Cancel</ButtonComponent>
                                </div>
                                {isNotNullAndUndefined(workspaceRow.UID) && (
                                    <div className="col-auto">
                                        <DropDownButtonComponent
                                            items={[
                                                { id: 'Delete', text: 'Delete', iconCss: 'fas fa-trash', },
                                            ]}
                                            cssClass="square-btn e-caret-hide d-flex align-items-center justify-content-center p-0 e-icon-btn d-none d-sm-flex d-md-none d-xl-flex"
                                            select={this.onMoreMenuClicked.bind(this)}
                                        >
                                            <i className="fa fa-ellipsis-v d-none"></i>
                                            <div className="icon-ellipsis-horizontal icon-ellipsis-sm"><span className="icon-ellipsis-dot"></span></div>
                                        </DropDownButtonComponent>
                                        <DropDownButtonComponent
                                            items={[
                                                // { id: 'SaveNewStep', text: 'Save & New Item', iconCss: 'e-Add e-icon', },
                                                // { id: 'Cancel', text: 'Cancel', iconCss: 'fas fa-xmark', },
                                                { id: 'Delete', text: 'Delete', iconCss: 'fas fa-trash', },
                                            ]}
                                            cssClass="d-flex d-sm-none d-md-flex d-xl-none e-caret-hide d-flex align-items-center justify-content-center p-0 e-icon-btn"
                                            select={this.onMoreMenuClicked.bind(this)}
                                        >
                                            <i className="fa fa-ellipsis-v d-none"></i>
                                            <div className="icon-ellipsis-horizontal icon-ellipsis-sm"><span className="icon-ellipsis-dot"></span></div>
                                        </DropDownButtonComponent>
                                    </div>
                                )}
                            </div>
                        </div>
                    </RAFForm>
                </div>
            );
        } else {
            return (
                <div>
                </div>
            );
        }
    }
}

export default React.memo(ManageWorkspace);
