import React, { PropsWithChildren } from "react";
import { isNotNullAndUndefined } from "../../RAFComponents/helpers/utils";
import { Column } from "../../RAFMaster/RMModules/RAFPage/PageLayoutJM";
import RAFFieldsList from "./RAFFieldsList";

interface IProps {
  data?: Column;
  colClass?: string;
  prefix?: string;
  validateFields?: boolean;
  mode?: "Edit" | "Details" | "Create";
}

export default function RAFColumn({
  children,
  ...props
}: PropsWithChildren<IProps>) {
  const { data, colClass } = props;

  return (
    <div className={colClass}>
      {data && isNotNullAndUndefined(data.Fields) && (
        <RAFFieldsList
          items={data.Fields}
          prefix={props.prefix}
          validateFields={props.validateFields}
          mode={props.mode}
        />
      )}
    </div>
  );
}
