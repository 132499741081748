import * as R from "ramda";
import React, { useContext, useEffect, useRef } from "react";
import { FormRenderProps } from "react-final-form";
import { ITheme, Model } from "survey-core";
import "survey-core/defaultV2.min.css";
import { Survey } from "survey-react-ui";
import RAFForm from "../../../RAFComponents/Inputs/RAFForm";
import CustomCardWidget from "../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import { RAFAttributesContext } from "../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import {
  showSuccessToast,
  showWarningToast,
} from "../../../RAFComponents/Utility/RAFToastComponent";
import {
  RAFActionMessage,
  getSessionStorage,
  hideProgress,
  setSessionStorage,
  showProgress,
} from "../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../RAFComponents/helpers/PermissionHelper";
import {
  ConvertSystemName,
  isNotEmptyArray,
  isNotNullAndUndefined,
  joinStringArray,
} from "../../../RAFComponents/helpers/utils";
import { RAFViewRow } from "../../../RAFComponents/models/Common/RAFViewRow";
import { LookUpRow } from "../../../RAFComponents/models/CompositeTypes/LookUpRow";
import { customTheme } from "../../../RAFMaster/RMModules/FormLibrary/components/custom-theme";
import {
  EvaluateScore,
  getEntityByObjectName,
} from "../../../RAFMaster/helpers/RMutils";
import { CareEmployeePermissionConstants, CareRecipientPermissionConstants } from "../../../constants/CareESIO/CareESIOPermissionConstant";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import {
  RAFButtonConstant,
  RAFLayout,
  StorageKey,
} from "../../../constants/Common/Constants";
import { fileUpload } from "../../ActiveContacts/Document/Library/DocumentHelper";
import RAFDetailsPageHeading from "../Details/RAFDetailsPageHeading";
import { RAFEntityContext } from "../Providers/RAFEntityProvider";
import RAFObjectContextProvider, {
  RAFObjectContext,
  submitRAFForm,
} from "../Providers/RAFObjectContextProvider";
import { getInitialValueForForm } from "../RAFFieldHelper";
import CreateContent from "./CreateContent";

interface IProps {
  initialValues?: any;
  onSave?: (values?: any) => void;
  onCancel?: () => void;
  mode?: "Dialog" | "Page";
}
const isLeadCareRecipient = (entityName: string) => {
  if (entityName === CareEsioEntity.CareRecipient.EntityName) {
    let allViews: RAFViewRow[] = JSON.parse(
      getSessionStorage(StorageKey.allViews_modulename + entityName, true)
    );
    let recentViewId = JSON.parse(
      getSessionStorage(StorageKey.recentViewId_modulename + entityName, true)
    );
    let recentView: RAFViewRow;
    if (
      isNotNullAndUndefined(recentViewId) &&
      isNotNullAndUndefined(recentViewId.viewId)
    ) {
      recentView =
        allViews && allViews.find((x) => x.UID === recentViewId.viewId);
    }
    if (
      isNotNullAndUndefined(recentView) &&
      recentView.ViewName === "all_leads"
    ) {
      return true;
    }
  }
  return false;
};

function CreateContentRenderer({ ...props }) {
  const rafAttributesContext = useContext(RAFAttributesContext);
  const queryAttributes = rafAttributesContext.queryAttributes;

  const rafEntityContext = useContext(RAFEntityContext);
  const entity = rafEntityContext.entity;
  const formLibrary = rafEntityContext.formLibrary;
  const moduleName = entity.EntityName;

  let hasCustomForm = false;
  if (
    isNotNullAndUndefined(formLibrary) &&
    isNotNullAndUndefined(formLibrary.FormStructure)
  ) {
    hasCustomForm = true;
  }

  //const formModel = new Model(formLibrary?.FormStructure);
  const [formModel] = React.useState(new Model(formLibrary?.FormStructure));
  const [pageNo, setPageNo] = React.useState(formModel.currentPageNo);
  const [isRunning, setIsRunning] = React.useState(true);
  // const [tempFileStorage, setTempFileStorage] = React.useState({});
  let rafForm: FormRenderProps | null;
  let submitButtonRef = useRef(null);

  const createPermissionName = moduleName === CareEsioEntity.CareRecipient.EntityName ? CareRecipientPermissionConstants.CareRecipientManage
    : moduleName === CareEsioEntity.Employee.EntityName ? CareEmployeePermissionConstants.EmployeeManage : `${moduleName}::Add`;

  const onSubmitObject = async (
    values,
    entity,
    queryAttributes,
    formModel,
    hasCustomForm,
    saveObject?: (
      objectData?: any,
      onlineOffline?: "Online" | "Offline",
      url?: string
    ) => Promise<{ objectUID?: string; objectName?: string; }>
  ) => {
    let updatedValues = R.clone(values);
    if (
      isNotNullAndUndefined(entity) &&
      isNotNullAndUndefined(entity.EntitySettings) &&
      isNotNullAndUndefined(entity.EntitySettings.EnableScore) &&
      entity.EntitySettings.EnableScore === true
    ) {
      updatedValues = EvaluateScore(values, queryAttributes);
    }

    if (hasCustomForm) {
      let isFormValid = formModel.validate(true, true);
      if (isFormValid) {
        let progressDiv = showProgress("body", true);
        if (
          isNotNullAndUndefined(entity) &&
          entity.EntityName === CareEsioEntity.CareRecipient.EntityName
        ) {
          let clientType = isLeadCareRecipient(entity.EntityName)
            ? "Lead"
            : "Client";
          formModel.setValue("type", clientType);
        }

        let filesToUpload;
        const questionsToUpload = Object.keys(tempFileStorage.current);
        for (let i = 0; i < questionsToUpload.length; i++) {
          const questionName = questionsToUpload[i];
          filesToUpload = tempFileStorage.current[questionName];
        }

        if (isNotEmptyArray(filesToUpload)) {
          Promise.all(
            filesToUpload.map(async (file) => {
              let filestoUpload = await fileUpload(file, moduleName);
              if (isNotNullAndUndefined(filestoUpload)) {
                let attachment: LookUpRow = {
                  UID: filestoUpload.entityId,
                  Value: filestoUpload.objectName,
                };
                return attachment;
              } else {
                let attachment: LookUpRow = {
                  UID: null,
                  Value: null,
                };
                return attachment;
              }
            })
          ).then((responseAttachments) => {
            const attachments = isNotEmptyArray(responseAttachments) ? responseAttachments.filter(x => isNotNullAndUndefined(x.UID)) : [];
            for (let i = 0; i < questionsToUpload.length; i++) {
              const questionName = questionsToUpload[i];
              formModel.setValue(questionName, attachments);
            }
            //set this to full form save
            let formData = formModel.data;

            saveObject(formData, "Online", "DataList/SaveForm")
              .then(async (response) => {
                if (
                  isNotNullAndUndefined(response) &&
                  isNotNullAndUndefined(response.objectUID)
                ) {
                  if (
                    entity.EntityName ===
                    CareEsioEntity.CareRecipient.EntityName
                  ) {
                    //save care plan
                    const carePlanTitle = joinStringArray([
                      formModel.data.first_name,
                      formModel.data.middle_name,
                      formModel.data.last_name,
                    ]);
                    let initialValues = {};
                    initialValues[
                      "Title"
                    ] = `${carePlanTitle}'s ${CareEsioEntity.CarePlan.DisplayName}`;
                    initialValues["CareRecipientUID"] = response.objectUID;
                    initialValues["CareRecipient"] = carePlanTitle;
                    const entity = await getEntityByObjectName({
                      ObjectName: ConvertSystemName(
                        CareEsioEntity.CarePlan.EntityName
                      ),
                    });
                    let savePlanResponse = await submitRAFForm(
                      initialValues,
                      null,
                      entity,
                      null
                    );
                    //save object
                  }

                  hideProgress(progressDiv);
                  showSuccessToast(RAFActionMessage.RecordSaved);
                  let allViews: RAFViewRow[] = JSON.parse(
                    getSessionStorage(
                      StorageKey.allViews_modulename + props.moduleName,
                      true
                    )
                  );
                  let recentlyCreatedView: RAFViewRow =
                    allViews &&
                    allViews.find((x) => x.ViewName === "Recently_Created"); //recently_created;
                  if (isNotNullAndUndefined(recentlyCreatedView)) {
                    setSessionStorage(
                      StorageKey.recentViewId_modulename + props.moduleName,
                      true,
                      JSON.stringify({
                        viewId: recentlyCreatedView.UID,
                        viewName: recentlyCreatedView.DisplayName,
                      })
                    );
                  }
                  if (props.onSave) {
                    props.onSave(response.objectUID, response.objectName);
                  }
                } else {
                  hideProgress(progressDiv);
                  showWarningToast(RAFActionMessage.RecordNotSaved);
                }
              })
              .catch((error) => error);
          });
        } else {
          //set this to full form save
          let formData = formModel.data;

          saveObject(formData, "Online", "DataList/SaveForm")
            .then(async (response) => {
              if (
                isNotNullAndUndefined(response) &&
                isNotNullAndUndefined(response.objectUID)
              ) {
                if (
                  entity.EntityName === CareEsioEntity.CareRecipient.EntityName
                ) {
                  //save care plan
                  const carePlanTitle = joinStringArray([
                    formModel.data.first_name,
                    formModel.data.middle_name,
                    formModel.data.last_name,
                  ]);
                  let initialValues = {};
                  initialValues[
                    "Title"
                  ] = `${carePlanTitle}'s ${CareEsioEntity.CarePlan.DisplayName}`;
                  initialValues["CareRecipientUID"] = response.objectUID;
                  initialValues["CareRecipient"] = carePlanTitle;
                  const entity = await getEntityByObjectName({
                    ObjectName: ConvertSystemName(
                      CareEsioEntity.CarePlan.EntityName
                    ),
                  });
                  let savePlanResponse = await submitRAFForm(
                    initialValues,
                    null,
                    entity,
                    null
                  );
                  //save object
                }

                hideProgress(progressDiv);
                showSuccessToast(RAFActionMessage.RecordSaved);
                let allViews: RAFViewRow[] = JSON.parse(
                  getSessionStorage(
                    StorageKey.allViews_modulename + props.moduleName,
                    true
                  )
                );
                let recentlyCreatedView: RAFViewRow =
                  allViews &&
                  allViews.find((x) => x.ViewName === "Recently_Created"); //recently_created;
                if (isNotNullAndUndefined(recentlyCreatedView)) {
                  setSessionStorage(
                    StorageKey.recentViewId_modulename + props.moduleName,
                    true,
                    JSON.stringify({
                      viewId: recentlyCreatedView.UID,
                      viewName: recentlyCreatedView.DisplayName,
                    })
                  );
                }
                if (props.onSave) {
                  props.onSave(response.objectUID, response.objectName);
                }
              } else {
                hideProgress(progressDiv);
                showWarningToast(RAFActionMessage.RecordNotSaved);
              }
            })
            .catch((error) => error);
        }
      }
    } else {
      let progressDiv = showProgress("body", true);
      saveObject(updatedValues)
        .then((response) => {
          hideProgress(progressDiv);
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.objectUID)
          ) {
            showSuccessToast(RAFActionMessage.RecordSaved);
            let allViews: RAFViewRow[] = JSON.parse(
              getSessionStorage(
                StorageKey.allViews_modulename + props.moduleName,
                true
              )
            );
            let recentlyCreatedView: RAFViewRow =
              allViews &&
              allViews.find((x) => x.ViewName === "Recently_Created"); //recently_created;
            if (isNotNullAndUndefined(recentlyCreatedView)) {
              setSessionStorage(
                StorageKey.recentViewId_modulename + props.moduleName,
                true,
                JSON.stringify({
                  viewId: recentlyCreatedView.UID,
                  viewName: recentlyCreatedView.DisplayName,
                })
              );
            }
            if (props.onSave) {
              props.onSave(response.objectUID, response.objectName);
            }
          } else {
            showWarningToast("Sorry something went wrong !");
          }
        })
        .catch((error) => error);
    }
  };

  const tempFileStorage = useRef({});
  useEffect(() => {
    // A variable that will store files until the survey is completed

    // Handles selected files
    formModel.onUploadFiles.add((_, options) => {
      // A variable that will store files until the survey is completed
      //const tempFileStorage = {};

      // Add files to the temporary storage
      if (tempFileStorage.current[options.name] !== undefined) {
        tempFileStorage.current[options.name] = tempFileStorage.current[
          options.name
        ].concat(options.files);
      } else {
        tempFileStorage.current[options.name] = options.files;
      }

      // Load file previews
      const content = [];

      let filesRead = 0; // Counter for files read

      options.files.forEach((file) => {
        const fileReader = new FileReader();
        fileReader.onload = () => {
          content.push({
            name: file.name,
            type: file.type,
            content: fileReader.result,
            file: file,
          });
          filesRead++; // Increment counter

          // Check if all files have been read
          if (filesRead === options.files.length) {
            // Return a file for preview as a { file, content } object
            options.callback(
              content.map((fileContent) => {
                return {
                  file: fileContent.file,
                  content: fileContent.content,
                };
              })
            );
          }
        };
        fileReader.readAsDataURL(file);
      });
    });
  }, []);

  // Handles file removal
  formModel.onClearFiles.add((_, options) => {
    // Empty the temporary file storage if "Clear All" is clicked
    if (options.fileName === null) {
      tempFileStorage.current[options.name] = [];
      options.callback("success");
      return;
    }

    // Remove an individual file
    const tempFiles = tempFileStorage && tempFileStorage.current[options.name];
    if (!!tempFiles) {
      const fileInfoToRemove = tempFiles.filter(
        (file) => file.name === options.fileName
      )[0];
      if (fileInfoToRemove) {
        const index = tempFiles.indexOf(fileInfoToRemove);
        tempFiles.splice(index, 1);
      }
    }
    //setTempFileStorage(tempFiles);
    tempFileStorage.current[options.name] = tempFiles;
    options.callback("success");
  });

  const enableSubmitButton = (g: FormRenderProps | null) => {
    if (g) {
      if (
        isNotNullAndUndefined(submitButtonRef) &&
        isNotNullAndUndefined(submitButtonRef.current) &&
        isNotNullAndUndefined(submitButtonRef.current.toggleBtnPermission)
      ) {
        submitButtonRef.current.toggleBtnPermission(g.submitting);
      }
    }
  };

  const cancelClick = () => {
    if (props.onCancel) {
      props.onCancel();
    }
  };

  formModel.applyTheme(customTheme as ITheme);

  formModel.onStarted.add(() => {
    setIsRunning(true);
  });

  formModel.onComplete.add(() => {
    setIsRunning(false);
  });

  const jumpToPage = (pageNo) => {
    if (formModel.currentPage.validate()) {
      setPageNo(pageNo);
    }
  };

  let pageTitles = [];
  formModel.visiblePages.forEach((pageModel) => {
    let title = pageModel["name"];
    pageTitles.push(title);
  });

  formModel.onErrorCustomText.add(function (sender, options) {
    if (options.name == "required") {
      //options.text = options.obj.title + " is required";
      options.text = "This field is required";
    }
  });

  const pageSelector = () => {
    return (
      <div id="raf_tab_header" className="raf_tab_header hideScrollBar">
        {pageTitles.map((page, index) => {
          return (
            <div
              className={`raf_tab_header_item${pageNo === index ? " active" : ""
                }`}
              key={index}
              onClick={() => jumpToPage(index)}
            >
              <span>{page}</span>
            </div>
          );
        })}
      </div>
    );
  };

  const renderExternalNavigation = () => {
    if (!isRunning) return undefined;
    return (
      <div className="h-100 w-100">
        <CustomCardWidget
          headerTemplate={pageSelector()}
          cardContentClassName="p-0 raf_tab_content section__div section__divY"
          cardClassName="section__container border-0"
          showCardSeparator
        >
          <Survey model={formModel} currentPageNo={pageNo} />
        </CustomCardWidget>
      </div>
    );
  };

  formModel.onCurrentPageChanged.add((_, options) => {
    let pageNo = options.newCurrentPage.visibleIndex;
    setPageNo(pageNo);
  });

  let detailsBtn = (
    <div>
      <div className="row gx-2 d-md-none">
        <div className="col-auto">
          <RAFPermissionRender permissionName={createPermissionName}>
            <RAFButtonComponent
              ref={submitButtonRef}
              isPrimary
              action={RAFButtonConstant.Save}
              onClick={() => rafForm && rafForm.form.submit()}
              idString="CreatePage"
              disabled={rafForm && rafForm.submitting}
              iconBtn
            />
          </RAFPermissionRender>
        </div>
        <div className="col-auto">
          <RAFButtonComponent
            action={RAFButtonConstant.Cancel}
            onClick={cancelClick}
            idString="CreatePage"
            iconBtn
          />
        </div>
      </div>
      <div className="row gx-2 d-md-flex d-none">
        <div className="col-auto">
          <RAFPermissionRender permissionName={createPermissionName}>
            <RAFButtonComponent
              ref={submitButtonRef}
              isPrimary
              action={RAFButtonConstant.Save}
              onClick={() => rafForm && rafForm.form.submit()}
              idString="CreatePage"
              disabled={rafForm && rafForm.submitting}
            />
          </RAFPermissionRender>
        </div>
        <div className="col-auto">
          <RAFButtonComponent
            action={RAFButtonConstant.Cancel}
            onClick={cancelClick}
            idString="CreatePage"
          />
        </div>
      </div>
    </div>
  );

  let nextButton = formModel.navigationBar.getActionById("sv-nav-next");
  let prevButton = formModel.navigationBar.getActionById("sv-nav-prev");
  let completeButton = formModel.navigationBar.getActionById("sv-nav-complete");
  nextButton.visible = false;
  prevButton.visible = false;
  completeButton.visible = false;

  if (props.mode === "Dialog") {
    return (
      <RAFObjectContextProvider
        moduleName={props.moduleName}
        progressDivId={`#raf_dlg_Outer_Div_${props.moduleName}`}
      >
        <RAFObjectContext.Consumer>
          {({ /*objectId, rafObject,*/ saveObject }) => {
            let initalObject = getInitialValueForForm(
              queryAttributes,
              props.initialValues
            );

            return (
              <RAFForm
                initialValues={initalObject}
                formRef={(g) => {
                  enableSubmitButton(g);
                  return (rafForm = g);
                }}
                layout={RAFLayout.TwoColumnLayout}
                onSubmit={(values) =>
                  onSubmitObject(
                    values,
                    entity,
                    queryAttributes,
                    formModel,
                    hasCustomForm,
                    saveObject
                  )
                }
                className="h-100"
              // onChange={(values) =>
              //   onRAFFormOnchange(values, formLibrary)
              // }
              //decorators={[accountDecorators]}
              //convertBeforeSave={ConvertAccountRowToAccountDto}
              >
                <div className="e-dlg-content-outer">
                  <div className="e-dlg-body-content">
                    {hasCustomForm ? (
                      <>
                        {formModel.visiblePages.length > 1 ? (
                          renderExternalNavigation()
                        ) : (
                          <Survey model={formModel} />
                        )}
                      </>
                    ) : (
                      <CreateContent
                        moduleName={moduleName}
                        progressDivId={`#raf_dlg_Outer_Div_${moduleName}`}
                      />
                    )}
                  </div>

                  <div className="e-dlg-footerContent ">
                    <div className="row gx-2">
                      <div className="col-auto">
                        <RAFPermissionRender
                          permissionName={createPermissionName}
                        >
                          <RAFButtonComponent
                            ref={submitButtonRef}
                            type="button"
                            isPrimary
                            action={RAFButtonConstant.Save}
                            onClick={() => rafForm && rafForm.form.submit()}
                            idString="CreateContent"
                            disabled={rafForm && rafForm.submitting}
                          />
                        </RAFPermissionRender>
                      </div>
                      <div className="col-auto">
                        <RAFButtonComponent
                          type="button"
                          action={RAFButtonConstant.Cancel}
                          onClick={cancelClick}
                          idString="CreateContent"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </RAFForm>
            );
          }}
        </RAFObjectContext.Consumer>
      </RAFObjectContextProvider>
    );
  } else if (props.mode === "Page") {
    return (
      <div className={"h-100 detailspage-flex-container"}>
        <div id="detailSecondHeaderDiv" className="detailspage-second-header">
          <RAFDetailsPageHeading
            headerValue={"Create"}
            moduleName={entity.EntityName}
            secondaryHeaderValue={entity.DisplayName}
            detailsBtn={detailsBtn}
            showRecordInfo={false}
          //customHeaderAvatar={customHeaderAvatar}
          />
        </div>
        {/* <NavigationMenu2></NavigationMenu2> */}
        <div className="detailspage-content p-0 overflowX-hidden">
          {/* <div className="page_container"> */}
          <RAFObjectContextProvider moduleName={moduleName}>
            <RAFObjectContext.Consumer>
              {({ objectId, objectName, rafObject, saveObject }) => {
                let initalObject = getInitialValueForForm(
                  queryAttributes,
                  props.initialValues
                );

                return (
                  <RAFForm
                    initialValues={initalObject}
                    formRef={(g) => {
                      enableSubmitButton(g);
                      return (rafForm = g);
                    }}
                    layout={RAFLayout.TwoColumnLayout}
                    onSubmit={(values) =>
                      onSubmitObject(
                        values,
                        entity,
                        queryAttributes,
                        formModel,
                        hasCustomForm,
                        saveObject
                      )
                    }
                    className="h-100"
                  //decorators={[accountDecorators]}
                  //convertBeforeSave={ConvertAccountRowToAccountDto}
                  >
                    <div className="e-dlg-body-content h-100">
                      {hasCustomForm ? (
                        <>
                          {formModel.visiblePages.length > 1 ? (
                            renderExternalNavigation()
                          ) : (
                            <Survey model={formModel} />
                          )}
                        </>
                      ) : (
                        <CreateContent moduleName={moduleName} />
                      )}
                    </div>
                    <div className="e-dlg-footerContent "></div>
                  </RAFForm>
                );
              }}
            </RAFObjectContext.Consumer>
          </RAFObjectContextProvider>
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
}
export default React.memo(CreateContentRenderer);
