import React, { PropsWithChildren } from "react";
import { SectionRow } from "../../RAFMaster/RMModules/RAFPage/PageLayoutJM";
import RAFSection from "./RAFSection";

interface IProps {
  items?: SectionRow[];
  prefix?: string;
  validateFields?: boolean;
  mode?: "Edit" | "Details" | "Create";
}
export default function RAFSectionList({
  children,
  ...props
}: PropsWithChildren<IProps>) {
  const { items } = props;

  return (
    <div className="row g-3">
      {items.map((item, index) => (
        <div className="col-12" key={item.UID}>
          <RAFSection
            key={index}
            data={item}
            prefix={props.prefix}
            validateFields={props.validateFields}
            mode={props.mode}
          />
        </div>
      ))}
    </div>
  );
}
