import { isNullOrUndefined } from "@syncfusion/ej2-base";
import * as R from "ramda";
import React, { useContext, useRef } from "react";
import { FormRenderProps } from "react-final-form";
import { ITheme, Model } from "survey-core";
import "survey-core/defaultV2.min.css";
import { Survey } from "survey-react-ui";
import RAFForm from "../../../RAFComponents/Inputs/RAFForm";
import CustomCardWidget from "../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDeletedRecordState from "../../../RAFComponents/Navigation/RAFDeletedRecordState";
import { RAFAttributesContext } from "../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import {
  showSuccessToast,
  showWarningToast,
} from "../../../RAFComponents/Utility/RAFToastComponent";
import {
  RAFActionMessage,
  getSessionStorage,
  hideProgress,
  setSessionStorage,
  showProgress,
} from "../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../RAFComponents/helpers/PermissionHelper";
import {
  IsNullOrWhiteSpace,
  isNotEmptyArray,
  isNotNullAndUndefined,
} from "../../../RAFComponents/helpers/utils";
import { RAFViewRow } from "../../../RAFComponents/models/Common/RAFViewRow";
import { LookUpRow } from "../../../RAFComponents/models/CompositeTypes/LookUpRow";
import { customTheme } from "../../../RAFMaster/RMModules/FormLibrary/components/custom-theme";
import { EvaluateScore } from "../../../RAFMaster/helpers/RMutils";
import nodata_es from "../../../assets/ES/nodata_es.svg";
import { CareEmployeePermissionConstants, CareRecipientPermissionConstants } from "../../../constants/CareESIO/CareESIOPermissionConstant";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import {
  Constants,
  RAFButtonConstant,
  RAFLayout,
  StorageKey,
} from "../../../constants/Common/Constants";
import { RAFEntityName } from "../../../constants/Common/EntityConstants";
import { fileUpload } from "../../ActiveContacts/Document/Library/DocumentHelper";
import RAFDetailsPageHeading from "../Details/RAFDetailsPageHeading";
import { RAFEntityContext } from "../Providers/RAFEntityProvider";
import { RAFObjectContext } from "../Providers/RAFObjectContextProvider";
import EditContent from "./EditContent";

interface IProps {
  onSave?: (values?: any) => void;
  onCancel?: () => void;
  onDelete?: () => void;
  mode?: "Dialog" | "Page";
  objectUID?: string;
}

function UpdateContentRenderer({ ...props }) {
  const rafAttributesContext = useContext(RAFAttributesContext);
  const queryAttributes = rafAttributesContext.queryAttributes;
  const rafEntityContext = useContext(RAFEntityContext);
  const entity = rafEntityContext.entity;
  const formLibrary = rafEntityContext.formLibrary;
  const moduleName = entity.EntityName;
  const rafObjectContext = useContext(RAFObjectContext);
  const saveObject = rafObjectContext.saveObject;
  const error = rafObjectContext.error;
  const rafObject = rafObjectContext.rafObject;
  const tempFileStorage = useRef({});

  let hasCustomForm = false;
  if (
    isNotNullAndUndefined(formLibrary) &&
    isNotNullAndUndefined(formLibrary.FormStructure)
  ) {
    hasCustomForm = true;
  }

  const [formModel] = React.useState(new Model(formLibrary?.FormStructure));
  const [pageNo, setPageNo] = React.useState(formModel.currentPageNo);
  const [isRunning, setIsRunning] = React.useState(true);
  const [formDataModel, setFormDataModel] = React.useState(rafObject);

  let rafForm: FormRenderProps | null;
  let submitButtonRef = useRef(null);

  const updatePermissionName = moduleName === CareEsioEntity.CareRecipient.EntityName ? CareRecipientPermissionConstants.CareRecipientManage
    : moduleName === CareEsioEntity.Employee.EntityName ? CareEmployeePermissionConstants.EmployeeManage : `${moduleName}::Add`;

  React.useEffect(() => {
    setFormDataModel(rafObject);
    // A variable that will store files until the survey is completed

    // Handles selected files
    formModel.onUploadFiles.add((_, options) => {
      // A variable that will store files until the survey is completed
      //const tempFileStorage = {};

      // Add files to the temporary storage
      if (tempFileStorage.current[options.name] !== undefined) {
        tempFileStorage.current[options.name] = tempFileStorage.current[
          options.name
        ].concat(options.files);
      } else {
        tempFileStorage.current[options.name] = options.files;
      }

      // Load file previews
      const content = [];

      let filesRead = 0; // Counter for files read

      options.files.forEach((file) => {
        const fileReader = new FileReader();
        fileReader.onload = () => {
          content.push({
            name: file.name,
            type: file.type,
            content: fileReader.result,
            file: file,
          });
          filesRead++; // Increment counter

          // Check if all files have been read
          if (filesRead === options.files.length) {
            // Return a file for preview as a { file, content } object
            options.callback(
              content.map((fileContent) => {
                return {
                  file: fileContent.file,
                  content: fileContent.content,
                };
              })
            );
          }
        };
        fileReader.readAsDataURL(file);
      });
    });
    // formModel.onDownloadFile.add((_, options) => {
    //   getBlobDocumentFile(
    //     "3f953db1-5922-4d69-9204-4f5a84335200",
    //     "Active Contacts - Vertical Bar.jpeg"
    //   )
    //     .then((file) => {
    //       const reader = new FileReader();
    //       reader.onload = (e) => {
    //         options.callback("success", e.target.result);
    //       };
    //       reader.readAsDataURL(file);
    //     })
    //     .catch((error) => {
    //       console.error("Error: ", error);
    //       options.callback("error");
    //     });

    //   // fetch("https://api.surveyjs.io/private/Surveys/getTempFile?name=" + options.content)
    //   //     .then((response) => response.blob())
    //   //     .then((blob) => {
    //   //         const file = new File([blob], options.fileValue.name, {
    //   //             type: options.fileValue.type
    //   //         });
    //   //         const reader = new FileReader();
    //   //         reader.onload = (e) => {
    //   //             options.callback("success", e.target.result);
    //   //         };
    //   //         reader.readAsDataURL(file);
    //   //     })
    //   //     .catch((error) => {
    //   //         console.error("Error: ", error);
    //   //         options.callback("error");
    //   //     });
    // });
  }, [rafObject]);

  formModel.data = formDataModel;

  // Handles file removal
  formModel.onClearFiles.add((_, options) => {
    // Empty the temporary file storage if "Clear All" is clicked
    if (options.fileName === null) {
      tempFileStorage.current[options.name] = [];
      options.callback("success");
      return;
    }

    // Remove an individual file
    const tempFiles = tempFileStorage && tempFileStorage.current[options.name];
    if (!!tempFiles) {
      const fileInfoToRemove = tempFiles.filter(
        (file) => file.name === options.fileName
      )[0];
      if (fileInfoToRemove) {
        const index = tempFiles.indexOf(fileInfoToRemove);
        tempFiles.splice(index, 1);
      }
    }
    //setTempFileStorage(tempFiles);
    tempFileStorage.current[options.name] = tempFiles;
    options.callback("success");
  });

  const onSubmitObject = (
    values,
    entity,
    queryAttributes,
    formModel,
    hasCustomForm,
    saveObject?: (
      objectData?: any,
      onlineOffline?: "Online" | "Offline",
      url?: string
    ) => Promise<{ objectUID?: string; objectName?: string; }>
  ) => {
    let updatedValues = R.clone(values);
    if (
      isNotNullAndUndefined(entity) &&
      isNotNullAndUndefined(entity.EntitySettings) &&
      isNotNullAndUndefined(entity.EntitySettings.EnableScore) &&
      entity.EntitySettings.EnableScore === true
    ) {
      updatedValues = EvaluateScore(values, queryAttributes);
    }

    if (hasCustomForm) {
      let isFormValid = formModel.validate(true, true);

      if (isFormValid) {
        let progressDiv = showProgress("body", true);
        let filesToUpload;
        const questionsToUpload = Object.keys(tempFileStorage.current);
        for (let i = 0; i < questionsToUpload.length; i++) {
          const questionName = questionsToUpload[i];
          filesToUpload = tempFileStorage.current[questionName];
        }

        if (isNotEmptyArray(filesToUpload)) {
          Promise.all(
            filesToUpload.map(async (file) => {
              let filestoUpload = await fileUpload(file, moduleName);
              if (isNotNullAndUndefined(filestoUpload)) {
                let attachment: LookUpRow = {
                  UID: filestoUpload.entityId,
                  Value: filestoUpload.objectName,
                };
                return attachment;
              } else {
                let attachment: LookUpRow = {
                  UID: null,
                  Value: null,
                };
                return attachment;
              }
            })
          ).then((responseAttachments) => {
            const attachments = isNotEmptyArray(responseAttachments) ? responseAttachments.filter(x => isNotNullAndUndefined(x.UID)) : [];
            for (let i = 0; i < questionsToUpload.length; i++) {
              const questionName = questionsToUpload[i];
              formModel.setValue(questionName, attachments);
            }

            //set this to full form save
            let formData = formModel.data;

            formModel.getAllQuestions().forEach(function (question) {
              let questionType = question.getType();
              if (questionType === "email") {
                let email = formData[question.name];

                if (isNullOrUndefined(email)) {
                  email = { personal: null, work: null };
                }
                if (!email.hasOwnProperty("personal")) {
                  email["personal"] = null;
                }
                if (!email.hasOwnProperty("work")) {
                  email["work"] = null;
                }
                formData[question.name] = email;
              }
              if (questionType === "phone") {
                let phone = formData[question.name];
                if (isNullOrUndefined(phone)) {
                  phone = { personal: null, work: null };
                }
                if (!phone.hasOwnProperty("personal")) {
                  phone["personal"] = null;
                }
                if (!phone.hasOwnProperty("work")) {
                  phone["work"] = null;
                }
                formData[question.name] = phone;
              }
              if (questionType === "address") {
                let address = formData[question.name];
                if (isNullOrUndefined(address)) {
                  address = {
                    line1: null,
                    city: null,
                    state: null,
                    country: null,
                    latitude: null,
                    longitude: null,
                    postal_code: null,
                  };
                }
                if (!address.hasOwnProperty("line1")) {
                  address["line1"] = null;
                }
                if (!address.hasOwnProperty("city")) {
                  address["city"] = null;
                }
                if (!address.hasOwnProperty("state")) {
                  address["state"] = null;
                }
                if (!address.hasOwnProperty("country")) {
                  address["country"] = null;
                }
                if (!address.hasOwnProperty("latitude")) {
                  address["latitude"] = null;
                }
                if (!address.hasOwnProperty("longitude")) {
                  address["longitude"] = null;
                }
                if (!address.hasOwnProperty("postal_code")) {
                  address["postal_code"] = null;
                }

                formData[question.name] = address;
              }
              if (questionType === "emergency_contact") {
                let emergency_contact = formData[question.name];
                if (isNullOrUndefined(emergency_contact)) {
                  emergency_contact = {
                    contact_name: null,
                    contact_phone: null,
                    relationship: null,
                  };
                }
                if (!emergency_contact.hasOwnProperty("contact_name")) {
                  emergency_contact["contact_name"] = null;
                }
                if (!emergency_contact.hasOwnProperty("contact_phone")) {
                  emergency_contact["contact_phone"] = null;
                }
                if (!emergency_contact.hasOwnProperty("relationship")) {
                  emergency_contact["relationship"] = null;
                }
                formData[question.name] = emergency_contact;
              }

              if (formData[question.name] === undefined) {
                formData[question.name] = null; // some empty value you can write result[question.name] = undefined; but it will look weird
              }
            });

            saveObject(formData, "Online", "DataList/SaveForm")
              .then((response) => {
                hideProgress(progressDiv);
                if (
                  isNotNullAndUndefined(response) &&
                  isNotNullAndUndefined(response.objectUID)
                ) {
                  showSuccessToast(RAFActionMessage.RecordSaved);
                  let allViews: RAFViewRow[] = JSON.parse(
                    getSessionStorage(
                      StorageKey.allViews_modulename + props.moduleName,
                      true
                    )
                  );
                  let recentlyCreatedView: RAFViewRow =
                    allViews &&
                    allViews.find((x) => x.ViewName === "Recently_Created"); //recently_created;
                  if (isNotNullAndUndefined(recentlyCreatedView)) {
                    setSessionStorage(
                      StorageKey.recentViewId_modulename + props.moduleName,
                      true,
                      JSON.stringify({
                        viewId: recentlyCreatedView.UID,
                        viewName: recentlyCreatedView.DisplayName,
                      })
                    );
                  }
                  if (props.onSave) {
                    props.onSave(response.objectUID);
                  }
                } else {
                  showWarningToast(RAFActionMessage.RecordNotSaved);
                }
              })
              .catch((error) => error);
          });
        } else {
          //set this to full form save
          let formData = formModel.data;

          formModel.getAllQuestions().forEach(function (question) {
            let questionType = question.getType();

            if (questionType === "email") {
              let email = formData[question.name];

              if (isNullOrUndefined(email)) {
                email = { personal: null, work: null };
              }
              if (!email.hasOwnProperty("personal")) {
                email["personal"] = null;
              }
              if (!email.hasOwnProperty("work")) {
                email["work"] = null;
              }
              formData[question.name] = email;
            }
            if (questionType === "phone") {
              let phone = formData[question.name];
              if (isNullOrUndefined(phone)) {
                phone = { personal: null, work: null };
              }
              if (!phone.hasOwnProperty("personal")) {
                phone["personal"] = null;
              }
              if (!phone.hasOwnProperty("work")) {
                phone["work"] = null;
              }
              formData[question.name] = phone;
            }
            if (questionType === "address") {
              let address = formData[question.name];
              if (isNullOrUndefined(address)) {
                address = {
                  line1: null,
                  city: null,
                  state: null,
                  country: null,
                  latitude: null,
                  longitude: null,
                  postal_code: null,
                };
              }
              if (!address.hasOwnProperty("line1")) {
                address["line1"] = null;
              }
              if (!address.hasOwnProperty("city")) {
                address["city"] = null;
              }
              if (!address.hasOwnProperty("state")) {
                address["state"] = null;
              }
              if (!address.hasOwnProperty("country")) {
                address["country"] = null;
              }
              if (!address.hasOwnProperty("latitude")) {
                address["latitude"] = null;
              }
              if (!address.hasOwnProperty("longitude")) {
                address["longitude"] = null;
              }
              if (!address.hasOwnProperty("postal_code")) {
                address["postal_code"] = null;
              }

              formData[question.name] = address;
            }
            if (questionType === "emergency_contact") {
              let emergency_contact = formData[question.name];
              if (isNullOrUndefined(emergency_contact)) {
                emergency_contact = {
                  contact_name: null,
                  contact_phone: null,
                  relationship: null,
                };
              }
              if (!emergency_contact.hasOwnProperty("contact_name")) {
                emergency_contact["contact_name"] = null;
              }
              if (!emergency_contact.hasOwnProperty("contact_phone")) {
                emergency_contact["contact_phone"] = null;
              }
              if (!emergency_contact.hasOwnProperty("relationship")) {
                emergency_contact["relationship"] = null;
              }
              formData[question.name] = emergency_contact;
            }
            if (formData[question.name] === undefined) {
              formData[question.name] = null; // some empty value you can write result[question.name] = undefined; but it will look weird
            }
          });

          saveObject(formData, "Online", "DataList/SaveForm")
            .then((response) => {
              hideProgress(progressDiv);
              if (
                isNotNullAndUndefined(response) &&
                isNotNullAndUndefined(response.objectUID)
              ) {
                showSuccessToast(RAFActionMessage.RecordSaved);
                let allViews: RAFViewRow[] = JSON.parse(
                  getSessionStorage(
                    StorageKey.allViews_modulename + props.moduleName,
                    true
                  )
                );
                let recentlyCreatedView: RAFViewRow =
                  allViews &&
                  allViews.find((x) => x.ViewName === "Recently_Created"); //recently_created;
                if (isNotNullAndUndefined(recentlyCreatedView)) {
                  setSessionStorage(
                    StorageKey.recentViewId_modulename + props.moduleName,
                    true,
                    JSON.stringify({
                      viewId: recentlyCreatedView.UID,
                      viewName: recentlyCreatedView.DisplayName,
                    })
                  );
                }
                if (props.onSave) {
                  props.onSave(response.objectUID);
                }
              } else {
                showWarningToast(RAFActionMessage.RecordNotSaved);
              }
            })
            .catch((error) => error);
        }
      }
    } else {
      let progressDiv = showProgress("body", true);
      saveObject(updatedValues)
        .then((response) => {
          hideProgress(progressDiv);
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.objectUID)
          ) {
            showSuccessToast(RAFActionMessage.RecordSaved);
            let allViews: RAFViewRow[] = JSON.parse(
              getSessionStorage(
                StorageKey.allViews_modulename + props.moduleName,
                true
              )
            );
            let recentlyCreatedView: RAFViewRow =
              allViews &&
              allViews.find((x) => x.ViewName === "Recently_Created"); //recently_created;
            if (isNotNullAndUndefined(recentlyCreatedView)) {
              setSessionStorage(
                StorageKey.recentViewId_modulename + props.moduleName,
                true,
                JSON.stringify({
                  viewId: recentlyCreatedView.UID,
                  viewName: recentlyCreatedView.DisplayName,
                })
              );
            }
            if (props.onSave) {
              props.onSave(response.objectUID);
            }
          } else {
            showWarningToast(RAFActionMessage.RecordNotSaved);
          }
        })
        .catch((error) => error);
    }
  };

  const enableSubmitButton = (g: FormRenderProps | null) => {
    if (g) {
      if (
        isNotNullAndUndefined(submitButtonRef) &&
        isNotNullAndUndefined(submitButtonRef.current) &&
        isNotNullAndUndefined(submitButtonRef.current.toggleBtnPermission)
      ) {
        submitButtonRef.current.toggleBtnPermission(g.submitting);
      }
    }
  };

  const cancelClick = () => {
    if (props.onCancel) {
      props.onCancel();
    }
  };

  const deleteClick = () => {
    if (props.onDelete) {
      props.onDelete();
    }
  };

  formModel.applyTheme(customTheme as ITheme);

  formModel.onStarted.add(() => {
    setIsRunning(true);
  });

  formModel.onComplete.add(() => {
    setIsRunning(false);
  });

  const jumpToPage = (newPageNo) => {
    if (formModel.currentPage.validate()) {
      // Update formModel.data based on pageNo
      setFormDataModel(formModel.data);
      setPageNo(newPageNo);
    }
  };

  let pageTitles = [];
  formModel.visiblePages.forEach((pageModel) => {
    let title = pageModel["name"];
    pageTitles.push(title);
  });

  const pageSelector = () => {
    return (
      <div id="raf_tab_header" className="raf_tab_header hideScrollBar">
        {pageTitles.map((page, index) => {
          return (
            <div
              className={`raf_tab_header_item${pageNo === index ? " active" : ""
                }`}
              key={index}
              onClick={() => jumpToPage(index)}
            >
              <span>{page}</span>
            </div>
          );
        })}
      </div>
    );
  };

  const renderExternalNavigation = () => {
    if (!isRunning) return undefined;

    return (
      <div className="h-100 w-100">
        <CustomCardWidget
          headerTemplate={pageSelector()}
          cardContentClassName="p-0 raf_tab_content section__div section__divY"
          cardClassName="section__container border-0"
          showCardSeparator
        >
          <Survey model={formModel} currentPageNo={pageNo} />
        </CustomCardWidget>
      </div>
    );
  };

  formModel.onCurrentPageChanged.add((_, options) => {
    let pageNo = options.newCurrentPage.visibleIndex;
    setPageNo(pageNo);
  });

  formModel.onErrorCustomText.add(function (sender, options) {
    if (options.name == "required") {
      //options.text = options.obj.title + " is required";
      options.text = "This field is required";
    }
  });

  let detailsBtn = (
    <div>
      <div className="row gx-2">
        <div className="col-auto">
          <RAFPermissionRender permissionName={updatePermissionName}>
            <RAFButtonComponent
              ref={submitButtonRef}
              isPrimary
              action={RAFButtonConstant.Save}
              onClick={() => rafForm && rafForm.form.submit()}
              idString="UpdatePage"
              disabled={rafForm && rafForm.submitting}
            />
          </RAFPermissionRender>
        </div>
        <div className="col-auto d-none d-md-block">
          <RAFButtonComponent
            action={RAFButtonConstant.Cancel}
            onClick={cancelClick}
            idString="UpdatePage"
          />
        </div>
      </div>
    </div>
  );
  let nextButton = formModel.navigationBar.getActionById("sv-nav-next");
  let prevButton = formModel.navigationBar.getActionById("sv-nav-prev");
  let completeButton = formModel.navigationBar.getActionById("sv-nav-complete");
  nextButton.visible = false;
  prevButton.visible = false;
  completeButton.visible = false;

  if (props.mode === "Dialog") {
    return (
      <>
        {isNotNullAndUndefined(rafObject) &&
          !IsNullOrWhiteSpace(rafObject.UID) ? (
          <RAFForm
            initialValues={rafObject}
            formRef={(g) => {
              enableSubmitButton(g);
              return (rafForm = g);
            }}
            layout={RAFLayout.TwoColumnLayout}
            onSubmit={(values) =>
              onSubmitObject(
                values,
                entity,
                queryAttributes,
                formModel,
                hasCustomForm,
                saveObject
              )
            }
            className="h-100"
          // onChange={(values) =>
          //   onRAFFormOnchange(values, formLibrary)
          // }
          //decorators={[accountDecorators]}
          //convertBeforeSave={ConvertAccountRowToAccountDto}
          >
            <div className="e-dlg-content-outer">
              <div className="e-dlg-body-content">
                {hasCustomForm ? (
                  <>
                    {formModel.visiblePages.length > 1 ? (
                      renderExternalNavigation()
                    ) : (
                      <Survey model={formModel} currentPageNo={pageNo} />
                    )}
                  </>
                ) : (
                  <EditContent moduleName={moduleName} />
                )}
              </div>

              <div className="e-dlg-footerContent">
                <div className="w-100">
                  <div className="row gx-2 justify-content-between">
                    {isNotNullAndUndefined(props.onDelete) &&
                      <RAFPermissionRender permissionName={`${moduleName}::Delete`}>
                        <div className="col-auto">
                          <RAFButtonComponent
                            type="button"
                            action={RAFButtonConstant.Delete}
                            onClick={deleteClick}
                            idString="UpdateContent"
                            className="form-custom-button btn_state_danger outline"
                          />
                        </div>
                      </RAFPermissionRender>
                    }
                    <div className="col-auto">
                      <div className="row gx-2">
                        <div className="col-auto">
                          <RAFButtonComponent
                            type="button"
                            action={RAFButtonConstant.Cancel}
                            onClick={cancelClick}
                            idString="UpdateContent"
                            className="form-custom-button"
                          />
                        </div>
                        <div className="col-auto">
                          <RAFPermissionRender permissionName={updatePermissionName}>
                            <RAFButtonComponent
                              ref={submitButtonRef}
                              type="button"
                              isPrimary
                              action={RAFButtonConstant.Save}
                              onClick={() => rafForm && rafForm.form.submit()}
                              idString="UpdateContent"
                              disabled={rafForm && rafForm.submitting}
                              className="form-custom-button"
                            />
                          </RAFPermissionRender>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </RAFForm>
        ) : error === Constants.RAFObject_NoContent ? (
          <div className="container-fluid px-0">
            <RAFDeletedRecordState
              image={nodata_es}
              title="This item is either deleted or You do not have sufficient privileges to view this item."
            />
          </div>
        ) : (
          <div
            className="container-fluid px-0"
            style={{ background: "transparent" }}
          >
            {/* <ACLoadingPanel loadingText="Loading..." /> */}
          </div>
        )}
      </>
    );
  } else if (props.mode === "Page") {
    let objectValue;
    let defaultField =
      queryAttributes && queryAttributes.find((x) => x.IsDefault === true);
    if (isNotNullAndUndefined(defaultField)) {
      objectValue = rafObject && rafObject[defaultField.AttributeName];
    }
    return (
      <div className={"h-100"}>
        {isNotNullAndUndefined(rafObject) &&
          !IsNullOrWhiteSpace(rafObject.UID) ? (
          <div className={"h-100 detailspage-flex-container"}>
            <div
              id="detailSecondHeaderDiv"
              className="detailspage-second-header"
            >
              <RAFDetailsPageHeading
                headerValue={`Update${entity.EntityName === CareEsioEntity.care_recipient.EntityName
                  ? ` - ${rafObject.recipient_name}`
                  : entity.EntityName === RAFEntityName.Employee
                    ? ` - ${rafObject.first_name} ${rafObject.last_name}`
                    : isNotNullAndUndefined(rafObject.ObjectName)
                      ? ` - ${rafObject.ObjectName}`
                      : ""
                  }`}
                moduleName={entity.EntityName}
                secondaryHeaderValue={entity.DisplayName}
                detailsBtn={detailsBtn}
                showRecordInfo={false}
                showRelatedFocusList={false}
              //customHeaderAvatar={customHeaderAvatar}
              />
            </div>
            {/* <NavigationMenu2></NavigationMenu2> */}
            <div className={`detailspage-content p-0 overflowX-hidden ${moduleName}`}>
              {/* <div className="page_container"> */}

              <RAFForm
                initialValues={rafObject}
                formRef={(g) => {
                  enableSubmitButton(g);
                  return (rafForm = g);
                }}
                layout={RAFLayout.TwoColumnLayout}
                onSubmit={(values) =>
                  onSubmitObject(
                    values,
                    entity,
                    queryAttributes,
                    formModel,
                    hasCustomForm,
                    saveObject
                  )
                }
                className="h-100"
              //decorators={[accountDecorators]}
              //convertBeforeSave={ConvertAccountRowToAccountDto}
              >
                <div className="e-dlg-body-content h-100">
                  {hasCustomForm ? (
                    <>
                      {formModel.visiblePages.length > 1 ? (
                        renderExternalNavigation()
                      ) : (
                        <Survey model={formModel} currentPageNo={pageNo} />
                      )}
                    </>
                  ) : (
                    <EditContent moduleName={moduleName} />
                  )}
                </div>
                <div className="e-dlg-footerContent "></div>
              </RAFForm>
            </div>
          </div>
        ) : error === Constants.RAFObject_NoContent ? (
          <div className="container-fluid px-0">
            <RAFDeletedRecordState
              image={nodata_es}
              title="This item is either deleted or You do not have sufficient privileges to view this item."
            />
          </div>
        ) : (
          <div
            className="container-fluid px-0"
            style={{ background: "transparent" }}
          >
            {/* <ACLoadingPanel loadingText="Loading..." /> */}
          </div>
        )}
      </div>
    );
  } else {
    return <div></div>;
  }
}
export default React.memo(UpdateContentRenderer);
