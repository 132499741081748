import { PropsWithChildren } from "react";
import "survey-core/defaultV2.min.css";
import RAFAttributeRelatedListProvider from "../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import {
  IDialogProps,
  IsNullOrWhiteSpace,
} from "../../../RAFComponents/helpers/utils";
import RAFEntityProvider from "../Providers/RAFEntityProvider";
import CreateContentRenderer from "./CreateContentRenderer";

interface IProps {
  moduleName?: string;
  onSave?: (entityId?: string, objectName?: string) => void;
  onClose?: () => void;
  initialValues?: any;
}

export default function RAFCreate({
  ...props
}: PropsWithChildren<IProps & IDialogProps>) {
  const cancelClick = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  const onSave = (objectUID, objectName) => {
    if (props.onSave) {
      props.onSave(objectUID, objectName);
    }
  };

  return (
    props.isActive &&
    !IsNullOrWhiteSpace(props.moduleName) && (
      <div
        className="h-100"
        key={props.moduleName}
        id={`raf_dlg_Outer_Div_${props.moduleName}`}
      >
        <RAFEntityProvider moduleName={props.moduleName}>
          <RAFAttributeRelatedListProvider moduleName={props.moduleName}>
            <CreateContentRenderer
              onSave={(objectUID, objectName) => onSave(objectUID, objectName)}
              onCancel={cancelClick}
              initialValues={props.initialValues}
              mode="Dialog"
            />
          </RAFAttributeRelatedListProvider>
        </RAFEntityProvider>
      </div>
    )
  );
}
